import { Button, Spacer } from 'components';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import { AdminMergePropertiesResult } from '@rentcheck/types';
import { useState } from 'react';
import FlatfileDialog from './flatfile-dialog';

const UploadFile = () => {
	const [dialogOpen, setDialogOpen] = useState(false);
	const [result, setResult] = useState<string[]>([]);

	const handleShowFlatfile = () => {
		setDialogOpen(true);
	};

	const handleOnClose = (result: AdminMergePropertiesResult['data']) => {
		setDialogOpen(false);

		if (result) {
			setResult(result.errors ?? []);
		} else {
			setResult([]);
		}
	};

	return (
		<>
			<Spacer height={4} />
			<Button onClick={handleShowFlatfile}>Upload File</Button>
			<Spacer height={4} />
			{result.map((r) => (
				<Typography variant="error">
					<FontAwesomeIcon icon={regular('times')} style={{ marginRight: 5 }} />
					{r}
				</Typography>
			))}

			<FlatfileDialog open={dialogOpen} onClose={handleOnClose} />
		</>
	);
};

export default UploadFile;
