import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';

import Container from '../common/container';
import EncryptedSettings from './encrypted-settings';

export default () => {
	const { search } = useLocation();
	const history = useHistory();

	const URLParameters = queryString.parse(search);
	const token = URLParameters.token as string;

	if (!token) {
		history.replace('/signin');
		return null;
	}

	return (
		<Container>
			<EncryptedSettings token={token} />
		</Container>
	);
};
