"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.canEditNotificationPreferences = void 0;
const canEditNotificationPreferences = ({ user, permissionGroups, }) => {
    if (!user) {
        return false;
    }
    /**
     * User should have a permission group with
     * allow_notifications_preferences_editing set to true
     * in order to be able to edit the
     * notification preferences for the subscription
     */
    return permissionGroups.some((p) => p.allow_notifications_preferences_editing);
};
exports.canEditNotificationPreferences = canEditNotificationPreferences;
