import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab } from '@mui/material';
import { useState } from 'react';

import { useTypedSelector } from 'store/reducers/common';
import Container from '../common/container';
import Skeleton from '../common/skeleton';
import PersonalSettings from './personal-settings';
import SubscriptionSettings from './subscription-settings';

export default () => {
	const profile = useTypedSelector((state) => state.activeProfile);
	const subscription = useTypedSelector((state) => state.subscription);

	const [tabValue, setTabValue] = useState('personal');

	const handleTabChanged = (_event: React.SyntheticEvent, newValue: any) => {
		setTabValue(newValue);
	};

	if (!profile) {
		return (
			<Container>
				<Skeleton />
			</Container>
		);
	}

	const canEditNotificationPreferences =
		!!profile.permissions.allow_notifications_preferences_editing;

	return (
		<Container>
			{subscription && canEditNotificationPreferences ? (
				<TabContext value={tabValue}>
					<TabList
						onChange={handleTabChanged}
						sx={{
							'.MuiTabs-flexContainer': { borderBottom: '1px solid #E0E4EC' },
						}}>
						<Tab label="Personal settings" value="personal" />
						<Tab label="Account default" value="account" />
					</TabList>

					<TabPanel value="personal" sx={{ pl: 0, pr: 0 }}>
						<PersonalSettings />
					</TabPanel>
					<TabPanel value="account" sx={{ pl: 0, pr: 0 }}>
						<SubscriptionSettings />
					</TabPanel>
				</TabContext>
			) : (
				<PersonalSettings />
			)}
		</Container>
	);
};
