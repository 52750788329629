import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { NotificationsPreferencesAPI } from '@rentcheck/api-frontend';
import { NotificationsPreferences } from '@rentcheck/types';
import { SnackbarActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';

export const usePersonalNotificationPreferences = () => {
	const dispatch: Dispatch = useDispatch();

	const profile = useTypedSelector((state) => state.activeProfile);

	const [notificationPreferences, setNotificationPreferences] =
		useState<NotificationsPreferences>();

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (!profile) return;

		setLoading(true);

		NotificationsPreferencesAPI.getByUser()
			.then((data) => setNotificationPreferences(data.data))
			.catch(() => dispatch(SnackbarActions.showError()))
			.finally(() => setLoading(false));
	}, [profile]);

	return {
		loading,
		notificationPreferences,
	};
};

export const useSubscriptionNotificationPreferences = () => {
	const dispatch: Dispatch = useDispatch();

	const subscription = useTypedSelector((state) => state.subscription);

	const [notificationPreferences, setNotificationPreferences] =
		useState<NotificationsPreferences>();

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (!subscription) return;

		setLoading(true);

		NotificationsPreferencesAPI.getBySubscription()
			.then((data) => setNotificationPreferences(data.data))
			.catch(() => dispatch(SnackbarActions.showError()))
			.finally(() => setLoading(false));
	}, [subscription]);

	return {
		loading,
		notificationPreferences,
	};
};

export const useEncryptedNotificationPreferences = (token: string) => {
	const dispatch: Dispatch = useDispatch();

	const [notificationPreferences, setNotificationPreferences] =
		useState<NotificationsPreferences>();

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setLoading(true);

		NotificationsPreferencesAPI.getByEncryptedToken(token)
			.then((data) => setNotificationPreferences(data.data))
			.catch(() => dispatch(SnackbarActions.showError()))
			.finally(() => setLoading(false));
	}, []);

	return {
		loading,
		notificationPreferences,
	};
};
