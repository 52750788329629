import { Tooltip, Typography } from '@mui/material';

import { colors } from '@rentcheck/theme';
import { APIProperty } from '@rentcheck/types';
import { Utils } from '@rentcheck/biz';

import { Column } from 'components';
import { useAppFolioIntegration } from 'hooks/appfolio-integration';
import { useRentManagerIntegrations } from 'hooks/rentmanager-integration';
import { useRentvineIntegration } from 'hooks/rentvine-integration';
import { formatDateAndTime, simplePropertyType } from 'utils/helpers';

interface Props {
	property: APIProperty;
}

const Yardi = ({ property }: Props) => {
	if (property.sync_data?.vendor !== 'yardi') {
		return null;
	}

	return (
		<Tooltip
			title={`Last synced on ${formatDateAndTime(
				property.sync_data.last_sync
			)}`}>
			<Typography>Synced with Yardi</Typography>
		</Tooltip>
	);
};

const AppFolio = ({ property }: Props) => {
	if (property.sync_data?.vendor !== 'appfolio') {
		return null;
	}

	return (
		<Tooltip
			title={`Last synced on ${formatDateAndTime(
				property.sync_data.last_sync
			)}`}>
			<Typography>Synced with AppFolio</Typography>
		</Tooltip>
	);
};

const RentManager = ({ property }: Props) => {
	if (property.sync_data?.vendor !== 'rentmanager') {
		return null;
	}

	return (
		<Tooltip
			title={`Last synced on ${formatDateAndTime(
				property.sync_data.last_sync
			)}`}>
			<Typography>Synced with Rent Manager</Typography>
		</Tooltip>
	);
};

const Rentvine = ({ property }: Props) => {
	if (property.sync_data?.vendor !== 'rentvine') {
		return null;
	}

	return (
		<Tooltip
			title={`Last synced on ${formatDateAndTime(
				property.sync_data.last_sync
			)}`}>
			<Typography>Synced with Rentvine</Typography>
		</Tooltip>
	);
};

interface NotSyncedProps {
	tooltip: string;
	vendor: string;
}

const NotSynced = ({ tooltip, vendor }: NotSyncedProps) => {
	return (
		<Tooltip title={tooltip}>
			<Typography
				color={colors.error.default}>{`Not Synced with ${vendor}`}</Typography>
		</Tooltip>
	);
};

const IntegrationStatus = ({ property }: Props) => {
	const { appFolioIntegration } = useAppFolioIntegration();
	const { rentManagerIntegrations } = useRentManagerIntegrations();
	const { rentvineIntegration } = useRentvineIntegration();

	const integration =
		appFolioIntegration ||
		rentManagerIntegrations?.find((rmi) => rmi.active) ||
		rentvineIntegration;

	const activeIntegration = !!integration;

	if (!activeIntegration) return null;

	const vendor = () => {
		if (appFolioIntegration) return 'AppFolio';
		if (rentManagerIntegrations.length > 0) return 'Rent Manager';
		if (rentvineIntegration) return 'Rentvine';
		return '';
	};

	const propertyType = simplePropertyType(property);

	if (property.property_type === 'Community') return null;

	if (Utils.Properties.isPropertyMissingSync(property)) {
		return (
			<NotSynced
				tooltip={`This may be a duplicate property that was manually created. Merge this ${propertyType} to sync property and inspection data.`}
				vendor={vendor()}
			/>
		);
	}

	if (!Utils.Properties.propertyIsStillInSync(property)) {
		return (
			<NotSynced
				tooltip={`We haven't received an update for this property since ${formatDateAndTime(
					property.sync_data?.last_sync
				)} and it is no longer syncing with ${vendor()}. Please review and delete from RentCheck if necessary.`}
				vendor={vendor()}
			/>
		);
	}

	return (
		<Column>
			<Yardi property={property} />
			<AppFolio property={property} />
			<RentManager property={property} />
			<Rentvine property={property} />
		</Column>
	);
};

export default IntegrationStatus;
