import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Rules } from '@rentcheck/biz';
import { ApiInspectionWithTemplate } from '@rentcheck/types';
import { LoadingButton } from 'components';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { InspectionActions, SnackbarActions } from 'store/actions';
import { Dispatch } from 'types';

interface Props {
	inspection: ApiInspectionWithTemplate;
}

export default ({ inspection }: Props) => {
	const dispatch = useDispatch<Dispatch>();

	const [loading, setLoading] = useState(false);

	const handleCompleteFirstStepReview = async () => {
		setLoading(true);

		dispatch(
			InspectionActions.updateInspection(inspection.id, {
				is_initial_review_complete: true,
			})
		)
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	};

	if (!Rules.Inspections.canCompleteFirstStepReview(inspection)) {
		return null;
	}

	return (
		<LoadingButton
			loading={loading}
			onClick={handleCompleteFirstStepReview}
			variant="outlined"
			startIcon={<FontAwesomeIcon icon={solid('check')} />}>
			Initial Review
		</LoadingButton>
	);
};
