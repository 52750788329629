"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotArray = exports.UnsupportedValue = exports.MissingPermissions = exports.InvalidValue = exports.MissingField = exports.BlankField = void 0;
const http_1 = require("./http");
class BlankField extends http_1.BadRequest {
    constructor(field) {
        super(`${field} can not be empty`);
    }
}
exports.BlankField = BlankField;
class MissingField extends http_1.BadRequest {
    constructor(field) {
        super(`${field} is missing`);
    }
}
exports.MissingField = MissingField;
class InvalidValue extends http_1.BadRequest {
    constructor(field, value) {
        super(`${field} has an invalid value${value ? `: ${value}` : ''}`);
    }
}
exports.InvalidValue = InvalidValue;
class MissingPermissions extends http_1.Unauthorized {
    constructor(action, resource) {
        super(`User does not have permissions to ${action} ${resource}`);
    }
}
exports.MissingPermissions = MissingPermissions;
class UnsupportedValue extends http_1.BadRequest {
    constructor(field, value) {
        super(`unsupported ${field} value${value ? `: ${value}` : ''}`);
    }
}
exports.UnsupportedValue = UnsupportedValue;
class NotArray extends http_1.BadRequest {
    constructor(field) {
        super(`${field} must be an array`);
    }
}
exports.NotArray = NotArray;
