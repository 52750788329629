import {
	ApiInspectionTemplateDigest,
	ApiTeam,
	FeatureRating,
	InspectionStatusDisplay,
	ListOptions,
	ReviewStep,
} from '@rentcheck/types';
import { useTypedSelector } from 'store/reducers/common';

export const version = '1.0';

export interface DateRange {
	start?: number;
	end?: number;
}

export type MultiValueFilter<T> =
	| {
			value: T[];
			condition: 'any_of' | 'none_of';
	  }
	| undefined;

export interface State {
	inspectionStatus: MultiValueFilter<InspectionStatusDisplay>;
	inspectionTemplate: MultiValueFilter<ApiInspectionTemplateDigest>;
	team: MultiValueFilter<ApiTeam>;
	dueDateRange?: DateRange;
	completedDateRange?: DateRange;
	search: string;
	archived: boolean;
	assignedTo: string[];
	reviewStep?: ReviewStep[];
	ratings?: FeatureRating[];
	sort: ListOptions['sort'];
	version: string;
}

const initialState: State = {
	sort: {
		sort_by: 'due_date',
		sort_type: 'DESC',
	},
	inspectionStatus: undefined,
	inspectionTemplate: undefined,
	team: undefined,
	dueDateRange: undefined,
	completedDateRange: undefined,
	search: '',
	archived: false,
	assignedTo: [],
	reviewStep: undefined,
	ratings: undefined,
	version,
};

const resetState: Partial<State> = {
	inspectionStatus: undefined,
	inspectionTemplate: undefined,
	team: undefined,
	dueDateRange: undefined,
	completedDateRange: undefined,
	archived: false,
	assignedTo: [],
	reviewStep: undefined,
	ratings: undefined,
	version,
};

type Action = {
	type: string;
	value?: string;
	dateRange: DateRange;
};

const reducer = (state: State = initialState, action: Action) => {
	switch (action.type) {
		case 'SIGNOUT_SUCCESS':
		case 'IMPERSONATE':
			return initialState;

		case 'SET_INSPECTION_STATUS_FILTER':
			return { ...state, inspectionStatus: action.value };

		case 'SET_INSPECTION_TEAMS_FILTER':
			return { ...state, team: action.value };

		case 'SET_INSPECTION_TEMPLATE_FILTER':
			return { ...state, inspectionTemplate: action.value };

		case 'SET_INSPECTION_REVIEW_STEP_FILTER':
			return { ...state, reviewStep: action.value };

		case 'SET_INSPECTION_RATINGS_FILTER':
			return { ...state, ratings: action.value };

		case 'SET_INSPECTION_DUE_DATE_RANGE_FILTER':
			return { ...state, dueDateRange: action.dateRange };

		case 'SET_INSPECTION_COMPLETED_DATE_RANGE_FILTER':
			return { ...state, completedDateRange: action.dateRange };

		case 'TOGGLE_INSPECTION_SORT_TYPE': {
			const newSortType = state.sort.sort_type === 'ASC' ? 'DESC' : 'ASC';
			const newSort: State['sort'] = { ...state.sort, sort_type: newSortType };

			return { ...state, sort: newSort };
		}

		case 'SET_INSPECTION_SORT_BY': {
			if (!action.value) {
				return state;
			}

			const newSort: State['sort'] = { ...state.sort, sort_by: action.value };
			return { ...state, sort: newSort };
		}

		case 'SET_INSPECTION_SEARCH_FILTER':
			return { ...state, search: action.value ?? '' };

		case 'SET_INSPECTION_ARCHIVED_FILTER':
			return { ...state, archived: action.value ?? false };

		case 'SET_INSPECTION_ASSIGNED_TO_FILTER':
			return { ...state, assignedTo: action.value ?? [] };

		case 'RESET_INSPECTION_FILTERS':
			return { ...state, ...resetState };

		default:
			return state;
	}
};

export const appliedFiltersCount = (state: State) => {
	return [
		!!state.dueDateRange,
		!!state.completedDateRange,
		state.team?.value.length,
		state.inspectionTemplate?.value.length,
		state.inspectionStatus?.value.length,
		state.archived,
		state.assignedTo.length,
		state.ratings?.length,
		state.reviewStep?.length,
	].filter(Boolean).length;
};

export const useAppliedFiltersCount = () => {
	const filters = useTypedSelector((state) => state.inspections.filters);
	return appliedFiltersCount(filters);
};

export const hasAppliedFilters = (state: State) => {
	return appliedFiltersCount(state) > 0 || state.search.length > 0;
};

export default reducer;
