"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InspectionFeaturesNotConsistent = exports.PropertyUpdateFailed = void 0;
const http_1 = require("./http");
class PropertyUpdateFailed extends http_1.BadRequest {
    constructor(field, propertyId) {
        super(`Failed to update ${field} for property ${propertyId}`);
    }
}
exports.PropertyUpdateFailed = PropertyUpdateFailed;
class InspectionFeaturesNotConsistent extends http_1.BadRequest {
    constructor() {
        super('Inspection must be consistent. All features should belong to the same inspection.');
    }
}
exports.InspectionFeaturesNotConsistent = InspectionFeaturesNotConsistent;
