import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { AppBar, Box, Toolbar } from '@mui/material';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

import { landing } from 'assets';
import IconButton from 'components/icon-button';
import SubscriptionCancelledSnackbar from 'components/subscription-cancelled';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';
import { isEmbeddedInMobileApp } from 'utils/helpers';

import { Button } from 'components';
import { SubscriptionActions } from 'store/actions';
import DeeplinkUtils from '../../utils/deeplinkNavigation';
import Drawer from './drawer';
import NavItems from './nav-items';
import ProfileButton from './profile-button';

const hideForLocations = ['/apmhelp', '/appfolio', '/essential'];
const showLoginForUnauthorizedLocations = ['/account/notification-preferences'];

const Navbar = () => {
	const history = useHistory();
	const location = useLocation();
	const dispatch: Dispatch = useDispatch();

	const auth = useTypedSelector((state) => state.firebase.auth);
	const profile = useTypedSelector((state) => state.activeProfile);
	const authenticatedUser = useTypedSelector(
		(state) => state.authenticatedUser
	);

	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

	const URLParameters = queryString.parse(location.search);

	const hideOnLargeScreens = { display: { md: 'none', xs: 'flex' } };
	const hideOnSmallScreens = { display: { md: 'flex', xs: 'none' } };

	useEffect(() => {
		profile && dispatch(SubscriptionActions.getSubscription());
	}, [profile?.id, dispatch]);

	if (hideForLocations.includes(location.pathname)) {
		return null;
	}

	if (typeof URLParameters.$deeplink_path === 'string' && auth.uid) {
		const handledLink = DeeplinkUtils.handleDeeplink(
			URLParameters.$deeplink_path,
			dispatch,
			authenticatedUser
		);

		if (handledLink?.redirect) return <Redirect to={handledLink.redirect} />;
	}

	const redirectLocation = showLoginForUnauthorizedLocations.find(
		(l) => l === location.pathname
	);

	if (!auth.uid && redirectLocation) {
		return (
			<AppBar component="nav">
				<Toolbar style={{ minHeight: 56 }}>
					<Box
						sx={{
							flexGrow: 1,
							cursor: 'pointer',
							mr: 2,
							...hideOnLargeScreens,
						}}
						onClick={() => history.push('/')}>
						<img
							alt="RentCheck"
							src={landing.rentCheckLogoNotext}
							style={{ height: 21 }}
						/>
					</Box>
					<Box
						sx={{
							flexGrow: 1,
							justifyContent: 'flex-start',
							cursor: 'pointer',
							...hideOnSmallScreens,
						}}
						onClick={() => history.push('/')}>
						<img
							alt="RentCheck"
							src={landing.rentCheckLogo}
							style={{ height: 28 }}
						/>
					</Box>
					<Box>
						<Button
							fullWidth
							variant="outlined"
							type="submit"
							onClick={() =>
								history.push(`/signin?redirect=${redirectLocation}`)
							}>
							Login
						</Button>
					</Box>
				</Toolbar>
			</AppBar>
		);
	}

	if (!auth.uid) {
		return (
			<AppBar
				component="nav"
				sx={{ height: 56, backgroundColor: '#E6E7F3' }}
				elevation={0}
			/>
		);
	}

	if (isEmbeddedInMobileApp()) {
		return <SubscriptionCancelledSnackbar />;
	}

	const handleDrawerToggle = (e?: React.MouseEvent<HTMLButtonElement>) => {
		!!e && setAnchorEl(e.currentTarget);
	};

	return (
		<AppBar component="nav">
			<SubscriptionCancelledSnackbar />

			<Toolbar style={{ minHeight: 56 }}>
				{/**
				 *	Small screen components:
				 *	• Hamburger button
				 *	• Drawer
				 *	• RentCheck logo (no text)
				 */}
				<Box
					sx={{
						cursor: 'pointer',
						mr: 2,
						...hideOnLargeScreens,
					}}
					onClick={() => history.push('/')}>
					<img
						alt="RentCheck"
						src={landing.rentCheckLogoNotext}
						style={{ height: 21 }}
					/>
				</Box>
				<Box
					sx={{
						flexGrow: 1,
						justifyContent: 'flex-end',
						...hideOnLargeScreens,
					}}>
					<IconButton
						onClick={handleDrawerToggle}
						icon={regular('bars')}
						buttonSx={{ color: '#000' }}
					/>
				</Box>
				<Drawer anchorEl={anchorEl} setAnchorEl={setAnchorEl} />

				{/**
				 *	Large screen components:
				 *	• RentCheck logo button
				 *	• NavItems
				 *	• Prorfile button
				 */}
				<Box
					sx={{
						flexGrow: 1,
						justifyContent: 'flex-start',
						cursor: 'pointer',
						...hideOnSmallScreens,
					}}
					onClick={() => history.push('/')}>
					<img
						alt="RentCheck"
						src={landing.rentCheckLogo}
						style={{ height: 28 }}
					/>
				</Box>
				<Box sx={{ ...hideOnSmallScreens }}>
					<NavItems />
					<ProfileButton />
				</Box>
			</Toolbar>
		</AppBar>
	);
};

export default Navbar;
