import {
	AdminMergePropertiesParams,
	AdminMergePropertiesResult,
} from '@rentcheck/types';
import { DialogTitle } from 'components';

import { FlatfileProvider } from '@flatfile/react';
import { Dialog, DialogContent } from '@mui/material';
import { AdminToolsApi } from '@rentcheck/api-frontend';
import { Utils } from '@rentcheck/biz';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { DataSubmittedEvent } from 'screens/account/imports/flows/dialogs/common/base-dialog/steps/embedded-flatfile/types';
import { SnackbarActions } from 'store/actions';
import { Snackbar } from 'store/actions/snackbar-actions';
import styled from 'styled-components';
import { Dispatch } from 'types';
import { sheetConfig } from './blueprint';
import FlatfileIframe from './flatfile-iframe';

interface Props {
	open: boolean;
	onClose: (result: AdminMergePropertiesResult['data']) => void;
}

export default ({ open, onClose }: Props) => {
	const dispatch = useDispatch<Dispatch>();

	const handleDataSubmitted = async (event: DataSubmittedEvent) => {
		const records = (await event.allData()).records;

		const progressSnackbar: Snackbar = await dispatch(
			SnackbarActions.showProgress('Duplicate property merge in progress')
		);

		/**
		 * We will process records in batches of `chunkSize` to avoid hitting the API
		 * Gateway timeout limit.
		 */
		const chunkSize = 5;
		const recordChunks = _.chunk(records, chunkSize);

		try {
			const errors: string[] = [];

			for (const chunk of recordChunks) {
				const payload: AdminMergePropertiesParams['data'] = {
					property_info: _.map(chunk, (r) =>
						_.mapValues(r.values, (v) => v.value)
					) as any,
				};

				const result = await AdminToolsApi.mergeProperties(payload);
				errors.push(...(result.errors ?? []));
			}

			const errorCount = errors?.length ?? 0;
			const successCount = records.length - errorCount;

			if (successCount) {
				dispatch(
					SnackbarActions.showSuccess(
						`${Utils.Helpers.numberAndPluralizedWord(successCount, 'property', 'properties')} merged`
					)
				);
			}

			if (errorCount) {
				dispatch(
					SnackbarActions.showError(
						`${Utils.Helpers.numberAndPluralizedWord(errorCount, 'property', 'properties')} failed to merge`
					)
				);
			}

			onClose({ errors });
		} catch (e) {
			dispatch(SnackbarActions.showError((e as Error).message));
		}

		dispatch(SnackbarActions.hideSnackbar(progressSnackbar.id));
	};

	return (
		<>
			<Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth fullScreen>
				<DialogTitle
					title="Upload Properties to Merge"
					onClose={() => onClose({})}
				/>
				<DialogContent>
					<FlatfileImportContainer>
						<FlatfileProvider
							publishableKey={process.env.REACT_APP_FLATFILE_PK}
							config={{
								displayAsModal: false,
								mountElement: 'flatfile-iframe-container',
							}}>
							<FlatfileIframe
								sheetConfig={sheetConfig}
								onDataSubmitted={handleDataSubmitted}
							/>
						</FlatfileProvider>
					</FlatfileImportContainer>
				</DialogContent>
			</Dialog>
		</>
	);
};

const FlatfileImportContainer = styled.div`
	display: flex;
	height: 100%;

	iframe {
		flex: 1;
		border: none;
		box-shadow: none !important;
		height: 100% !important;
	}

	.flatfile-close-button {
		display: none;
	}
`;
