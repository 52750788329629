import FilterButton, { FilterBuilder } from 'components/table/filter-button-v2';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'store/reducers/rootReducer';

import { InspectionTemplatesActions } from 'store/actions';

import { useAppliedFiltersCount } from 'store/reducers/inspections/filters';
import archivedChip from './filters/archived/chip';
import archivedMenuItem from './filters/archived/menu-item';
import assignedToMeChip from './filters/assigned-to-me/chip';
import assignedToMeMenuItem from './filters/assigned-to-me/menu-item';
import completedDateChip from './filters/completed-date/chip';
import completedDateMenuItem from './filters/completed-date/menu-item';
import dueDateChip from './filters/due-date/chip';
import dueDateMenuItem from './filters/due-date/menu-item';
import featureRatingChip from './filters/feature-rating/chip';
import featureRatingMenuItem from './filters/feature-rating/menu-item';
import reviewStepChip from './filters/review-step/chip';
import reviewStepMenuItem from './filters/review-step/menu-item';
import statusChip from './filters/status/chip';
import statusMenuItem from './filters/status/menu-item';
import teamChip from './filters/team/chip';
import teamMenuItem from './filters/team/menu-item';
import templateChip from './filters/template/chip';
import templateMenuItem from './filters/template/menu-item';

export default () => {
	const dispatch = useDispatch();

	const profile = useTypedSelector((state) => state.activeProfile);
	const filtersCount = useAppliedFiltersCount();

	useEffect(() => {
		if (!profile) return;

		dispatch(InspectionTemplatesActions.getAll(true));
	}, [profile]);

	const handleResetFilters = () => {
		dispatch({ type: 'RESET_INSPECTION_FILTERS' });
	};

	return (
		<FilterButton
			title="Inspection"
			onClearFilters={handleResetFilters}
			appliedFiltersCount={filtersCount}
			filters={[
				new FilterBuilder(assignedToMeMenuItem, assignedToMeChip),
				new FilterBuilder(completedDateMenuItem, completedDateChip),
				new FilterBuilder(dueDateMenuItem, dueDateChip),
				new FilterBuilder(featureRatingMenuItem, featureRatingChip),
				new FilterBuilder(archivedMenuItem, archivedChip),
				new FilterBuilder(statusMenuItem, statusChip),
				new FilterBuilder(templateMenuItem, templateChip),
				new FilterBuilder(reviewStepMenuItem, reviewStepChip),
				new FilterBuilder(teamMenuItem, teamChip),
			]}
		/>
	);
};
