import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import styled from 'styled-components';
import { colors } from 'theme';
import { orderRoomNamesArray } from 'utils/helpers';
import { CenteredRow, Column, Spacer } from '../..';

import { Typography } from '@mui/material';
import {
	APIProperty,
	ApiInspection,
	ApiInspectionWithTemplate,
	Feature,
	TemplateQuestion,
} from '@rentcheck/types';
import { FeaturesApi } from 'api';
import { useDispatch } from 'react-redux';
import { InspectionActions, ModalFlowActions } from 'store/actions';
import { FeatureWithMetadata } from 'store/reducers/features';
import '../inspectionStyles/inspectionStyles.css';
import EditComparisonModal from './Components/EditComparisonModal';
import InspectionInfoBox from './Components/InspectionInfoBox';

interface Props {
	firstID: string;
	secondID: string;
	firstInspection?: ApiInspectionWithTemplate;
	secondInspection?: ApiInspectionWithTemplate;
	property?: APIProperty;
}

function InspectionComparisonDetail({
	firstID,
	secondID,
	firstInspection,
	secondInspection,
	property,
}: Props) {
	const dispatch = useDispatch();

	const [rooms, setRooms] = React.useState<any>([]);
	const [firstFeatures, setFirstFeatures] = React.useState<any>({});
	const [secondFeatures, setSecondFeatures] = React.useState<any>({});
	const [details, setDetails] = React.useState<any>({});
	const [canEditComparison, setCanEditComparison] = React.useState(false);
	const [editModalVisible, setEditModalVisible] = React.useState(false);
	const [editModalIndex, setEditModalIndex] = React.useState(1);

	const combineArrays = (...arrays: any[]) => {
		let jointArray: string[] = [];
		arrays.forEach((array) => {
			jointArray = [...jointArray, ...array];
		});
		const uniqueArray = jointArray.filter(
			(item, index) => jointArray.indexOf(item) === index
		);
		return uniqueArray;
	};

	const DetailsWarningSign = () => {
		const title = `This feature contains a note added by the user or at least one “No” answer.`;
		return (
			<Tooltip title={title} placement="top-start" arrow>
				<span>
					<FontAwesomeIcon
						icon={solid('exclamation-triangle')}
						color={colors.black}
					/>
				</span>
			</Tooltip>
		);
	};

	const needWarningSign = (one?: Feature, two?: Feature) => {
		if (one || two) {
			if (one?.notes || two?.notes) return <DetailsWarningSign />;

			if (one?.responses?.includes('No') || two?.responses?.includes('No'))
				return <DetailsWarningSign />;
		}
		return;
	};

	const getFeaturesByRoom = (
		features: Feature[],
		inspection: ApiInspection
	) => {
		const featuresByRoom: any = {};
		for (const roomKey of inspection.room_names) {
			featuresByRoom[roomKey] = {};
		}

		Object.values(features).forEach((feature: Feature) => {
			const roomName = feature.section.name_with_ordinal;
			if (roomName) {
				if (featuresByRoom[roomName]) {
					featuresByRoom[roomName] = {
						...featuresByRoom[roomName],
						[feature.name]: feature,
					};
				} else {
					featuresByRoom[roomName] = {
						[feature.name]: feature,
					};
				}
			}
		});
		return featuresByRoom;
	};

	const populateRooms = async () => {
		if (!firstInspection || !secondInspection) {
			return;
		}

		try {
			const firstFeatures: Feature[] =
				await FeaturesApi.getForInspectionId(firstID);
			const secondFeatures: Feature[] =
				await FeaturesApi.getForInspectionId(secondID);

			const firstFeaturesByRoom = getFeaturesByRoom(
				firstFeatures,
				firstInspection
			);
			const secondFeaturesByRoom = getFeaturesByRoom(
				secondFeatures,
				secondInspection
			);

			const allRoomNames = combineArrays(
				firstInspection.room_names,
				secondInspection.room_names
			);

			const sortedRooms = orderRoomNamesArray(allRoomNames);
			setRooms(sortedRooms);
			setFirstFeatures(firstFeaturesByRoom);
			setSecondFeatures(secondFeaturesByRoom);
		} catch (error) {
			setRooms([]);
		}
	};

	const determineIfComparisonCanBeEdited = async () => {
		if (!property) {
			return;
		}

		const inspections = await InspectionActions.getInspectionsForComparison(
			property.id
		);

		setCanEditComparison(inspections.length > 2);
	};

	const featureRating = (feature?: Feature) => {
		if (!feature) {
			return (
				<Tooltip
					title="This feature or area was not included in this inspection at the time it was completed."
					placement="top-start"
					arrow>
					<NAPill>Not Included</NAPill>
				</Tooltip>
			);
		}

		if (feature.incomplete) {
			return (
				<Tooltip
					title="This feature not completed during the inspection."
					placement="top-start"
					arrow>
					<IncompletePill>Not Completed</IncompletePill>
				</Tooltip>
			);
		}

		if (feature.not_applicable) {
			return (
				<Tooltip
					title="This feature was marked as being not applicable to the unit during the inspection."
					placement="top-start"
					arrow>
					<NAPill color={colors.lightGray}>N/A</NAPill>
				</Tooltip>
			);
		}

		if (feature.rating !== '') {
			return (
				<div className={`feature-rating-${feature.rating}`}>
					{feature.rating}
				</div>
			);
		}

		return (
			<Tooltip
				title="This feature was marked as skipped during the inspection."
				placement="top-start"
				arrow>
				<SkippedPill>
					<Typography>Skipped</Typography>
				</SkippedPill>
			</Tooltip>
		);
	};

	const imageCount = (
		inspection?: ApiInspectionWithTemplate,
		feature?: FeatureWithMetadata
	) => {
		if (!inspection) {
			return null;
		}

		if (
			feature &&
			!feature.incomplete &&
			!feature.not_applicable &&
			feature.images
		) {
			return (
				<SkippedPill
					onClick={() => {
						dispatch(
							ModalFlowActions.showFeatureDetails({
								inspection,
								initialFeature: feature,
								features: [feature],
							})
						);
					}}>
					{' '}
					<FontAwesomeIcon icon={solid('images')} color={colors.white} />{' '}
					{feature.images.length}
				</SkippedPill>
			);
		} else {
			return null;
		}
	};

	const note = (feature?: Feature) => {
		if (feature && feature.notes && feature.notes !== '') {
			return (
				<SkippedPill
					onClick={() =>
						showDetail(feature.section.name_with_ordinal, feature, null)
					}>
					{' '}
					<FontAwesomeIcon icon={solid('sticky-note')} color={colors.white} />
				</SkippedPill>
			);
		} else {
			return null;
		}
	};

	const showNotes = (feature: Feature | undefined) => {
		return (
			feature &&
			feature.notes &&
			feature.notes !== '' && (
				<Column>
					<EmptyQuestionCell>
						<Typography color={colors.gray}>Notes:</Typography>
						<Typography>{feature.notes}</Typography>
					</EmptyQuestionCell>
				</Column>
			)
		);
	};

	const showDetail = (
		room: string,
		firstFeature: Feature | null,
		secondFeature: Feature | null
	) => {
		const feature = firstFeature
			? firstFeature.name
			: secondFeature
				? secondFeature.name
				: null;
		const payload = { ...details };
		if (feature && details[room] && details[room][feature]) {
			payload[room][feature] = !payload[room][feature];
			setDetails(payload);
		} else if (feature) {
			payload[room] = {};
			payload[room][feature] = true;
			setDetails(payload);
		}
	};

	const responseColor = (response: string) => {
		if (response === 'No') {
			return colors.error;
		}
		return colors.black;
	};

	const renderEmptyState = (inspectionOrder: string) => {
		return (
			<EmptyQuestionCell>
				<QuestionRow>
					This area was not included in the {inspectionOrder} inspection at the
					time it was completed.{' '}
				</QuestionRow>
			</EmptyQuestionCell>
		);
	};

	const renderQuestionRow = (question: TemplateQuestion, response: string) => {
		return (
			<Typography>
				<QuestionRow>
					<Question>{question.title}</Question>
					<Typography color={responseColor(response)}>{response}</Typography>
				</QuestionRow>
				<Spacer height={2} />
			</Typography>
		);
	};

	const renderFeatureData = (
		feature: FeatureWithMetadata,
		inspection?: ApiInspectionWithTemplate
	) => {
		return (
			<Cell>
				<Row>
					{featureRating(feature)}
					<Spacer width={1} />
					{imageCount(inspection, feature)}
					<Spacer width={1} />
					{note(feature)}
				</Row>
			</Cell>
		);
	};

	const renderFeature = (feature: Feature) => {
		return feature ? (
			<Column>
				{feature.questions.map((question, index) => {
					return question &&
						question.title !== '' &&
						feature.responses[index] ? (
						<>{renderQuestionRow(question, feature.responses[index])}</>
					) : null;
				})}
			</Column>
		) : (
			<EmptyColumn left>{renderEmptyState('second')}</EmptyColumn>
		);
	};

	const mapFeatureName = (name: string) => {
		return name.replace('Floors', 'Floor');
	};

	const renderFeatures = (
		featureList: string[],
		firstInspectionFeatures: any,
		secondInspectionFeatures: any,
		room: string
	) => {
		return (
			<>
				<Typography variant="h6">{room}</Typography>
				<Spacer height={2} />
				{featureList.map((feature: string) => {
					//dealing with script changes that made feature names plural
					const noS = feature.slice(0, -1);
					const firstFeature: FeatureWithMetadata = firstInspectionFeatures
						? firstInspectionFeatures[feature] ||
							firstInspectionFeatures[noS] ||
							firstInspectionFeatures[feature.replace('Floor', 'Floors')]
						: null;

					const secondFeature: FeatureWithMetadata = secondInspectionFeatures
						? secondInspectionFeatures[feature] ||
							secondInspectionFeatures[noS] ||
							secondInspectionFeatures[feature.replace('Floor', 'Floors')]
						: null;
					return (
						<>
							<Spacer height={5} />
							<Row>
								<Spacer width={5} />
								<Cell>{mapFeatureName(feature)}</Cell>
								<Spacer width={2} />
								{renderFeatureData(firstFeature, firstInspection)}
								<Spacer width={2} />
								{renderFeatureData(secondFeature, secondInspection)}
								<Spacer width={2} />
								<Cell
									className="clickable"
									onClick={() => {
										showDetail(room, firstFeature, secondFeature);
									}}>
									{needWarningSign(firstFeature, secondFeature)} Details{' '}
									<FontAwesomeIcon
										icon={solid('caret-down')}
										color={colors.black}
									/>
								</Cell>
							</Row>
							<Spacer height={2} />
							{details[room] && details[room][feature] && (
								<DetailsRow>
									<Cell></Cell>
									<Cell>
										{renderFeature(firstFeature)}
										{showNotes(firstFeature)}
									</Cell>
									<Spacer width={4} />
									<Cell>
										{renderFeature(secondFeature)}
										{showNotes(secondFeature)}
									</Cell>
									<Cell />
									<Spacer height={2} />
								</DetailsRow>
							)}
							<Spacer height={2} />
						</>
					);
				})}
			</>
		);
	};

	React.useEffect(() => {
		populateRooms();
	}, [firstInspection, secondInspection]);

	React.useEffect(() => {
		determineIfComparisonCanBeEdited();
	}, [property]);

	return (
		<div>
			<Spacer height={5} />
			<Row>
				<Indent></Indent>
				{!!firstInspection && (
					<Cell>
						<InspectionInfoBox
							inspection={firstInspection}
							canEdit={canEditComparison}
							onEdit={() => {
								setEditModalVisible(true);
								setEditModalIndex(1);
							}}
						/>
					</Cell>
				)}
				<Spacer width={6} />
				{!!secondInspection && (
					<Cell>
						<InspectionInfoBox
							inspection={secondInspection}
							canEdit={canEditComparison}
							onEdit={() => {
								setEditModalVisible(true);
								setEditModalIndex(2);
							}}
						/>
					</Cell>
				)}
				<Indent></Indent>
			</Row>

			{rooms &&
				!!secondInspection &&
				!!firstInspection &&
				rooms.map((room: string) => {
					const first: string[] = firstFeatures[room]
						? Object.keys(firstFeatures[room])
						: [];
					const second: string[] = secondFeatures[room]
						? Object.keys(secondFeatures[room])
						: [];
					let allFeatures = [...new Set([...first, ...second]).values()];
					//dealing with script changes that made feature names plural
					allFeatures = allFeatures.filter((feature) => {
						return (
							!allFeatures.includes(feature + 's') &&
							!feature.includes('Floors,')
						);
					});

					return (
						<>
							<Column id={`room-${room}`}>
								{(firstFeatures[room] || secondFeatures[room]) &&
									renderFeatures(
										allFeatures,
										firstFeatures[room],
										secondFeatures[room],
										room
									)}
							</Column>
						</>
					);
				})}
			{editModalVisible && firstInspection && secondInspection && (
				<EditComparisonModal
					currentInspections={[firstInspection, secondInspection]}
					editIndex={editModalIndex}
					setHidden={() => setEditModalVisible(false)}
				/>
			)}
		</div>
	);
}

const EmptyColumn = styled.div<{ right?: boolean; left?: boolean }>`
	display: flex;
	flex-direction: column;
	border-right: ${({ right }) =>
		right ? `2px solid ${colors.lightGray}` : `none`};
	border-left: ${({ left }) =>
		left ? `2px solid ${colors.lightGray}` : `none`};
`;
const Row = styled.div`
	display: flex;
	flex-direction: row;
`;
const DetailsRow = styled.div`
	display: flex;
	flex-direction: row;
	border-bottom: 2px solid ${colors.lightGray};
`;
const QuestionRow = styled(Row)`
	display: space-between;
	flex-direction: row;
	justify-content: flex-end;
`;

const Cell = styled(Typography)`
	width: 55%;
	line-height: 2.1;
`;
const Indent = styled(Typography)`
	width: 50%;
`;
const Question = styled(Typography)`
	margin-right: 10px;
	width: 80%;
`;

const EmptyQuestionCell = styled(Typography)`
	padding: 8px;
	margin-right: 15px;
	margin-left: 15px;
	margin-bottom: 15px;
	border-radius: 8px;
	border: 1px solid ${colors.gray};
`;
const SkippedPill = styled(CenteredRow)`
	padding: 0 10px;
	border-radius: 8px;
	background-color: #727375;
	color: ${colors.white};
	font-size: 12px;
	font-weight: 600;
	height: 30px;
	cursor: pointer;
`;

const NAPill = styled(SkippedPill)`
	background-color: #afb3b7;
`;

const IncompletePill = styled(SkippedPill)`
	border: 2px solid #727375;
	color: #727375;
	height: 30px;
	width: 57px;
`;

export default InspectionComparisonDetail;
