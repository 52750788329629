import { Typography } from '@mui/material';
import { Spacer } from 'components';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';

import DataSyncModal, {
	IngestionFilterOptionType,
	MappingSectionType,
} from '../../common/data-sync-modal';
import InstallButton from '../../common/install-button';
import InstalledContent from '../../common/installed-content';

import { IntegrationsApi } from '@rentcheck/api-frontend';
import {
	ApiBaseIntegration,
	ApiRentManagerIntegration,
	BaseIntegration,
	BaseSyncDetails,
	GetRentManagerSkillsResult,
	GetRentManagerWorkOrderStatusesResult,
	UpdateRentManagerIntegrationsParams,
} from '@rentcheck/types';
import { useRentManagerIntegrations } from 'hooks/rentmanager-integration';
import _ from 'lodash';
import { Analytics } from 'utils';
import InstallModal from './install-modal';

const commonProps = {
	vendor: 'Rent Manager',
	parentUrl: '/account/integrations/rentmanager',
	learnMoreLink:
		'https://help.getrentcheck.com/en/articles/5666058-rent-manager-integration',
};

const mappingSections: MappingSectionType[] = [
	{
		type: 'Units',
		title: 'Unit Sync',
		body: 'The Unit Sync adds new units from Rent Manager and keeps them up to date in RentCheck. On every sync, when a matching unit is found the unit will be updated with new information. Otherwise a new unit will be created in RentCheck.',
		mode: 'read',
		mappings: [
			{ columns: ['Street', 'Address'] },
			{ columns: ['City', 'City'] },
			{ columns: ['Postal Code', 'Zip Code'] },
			{ columns: ['Bedrooms', 'Bedrooms'] },
			{ columns: ['Bathrooms', 'Full Bathrooms & Half Bathrooms'] },
			{ columns: ['PropertyType', 'Type'] },
		],
	},
	{
		type: 'Buildings',
		title: 'Building Sync',
		body: 'The Building Sync adds new buildings from Rent Manager and keeps them up to date in RentCheck. On every sync, when a matching building is found the building will be updated with new information. Otherwise a new building will be created in RentCheck.',
		mode: 'read',
		mappings: [
			{ columns: ['Full Name', 'Building Name'] },
			{ columns: ['Street', 'Address'] },
			{ columns: ['City', 'City'] },
			{ columns: ['Postal Code', 'Zip Code'] },
		],
	},
	{
		type: 'Residents',
		title: 'Resident Sync',
		body: 'The Resident Sync imports your tenant directory from Rent Manager and includes current and future residents. When the Resident Sync is enabled contact details including email and phone are kept in sync along with resident status and key lease dates such as lease start, least end, move-in, renewal, and move-out dates.',
		mode: 'read',
		mappings: [
			{ columns: ['First Name', 'First Name'] },
			{ columns: ['Last Name', 'Last Name'] },
			{ columns: ['Email', 'Email'] },
			{ columns: ['Primary Phone', 'Phone'] },
			{ columns: ['Lease Start Date', 'Lease Start Date'] },
			{ columns: ['Lease End Date', 'Lease End Date'] },
			{ columns: ['Move Out Date', 'Move-out Date'] },
		],
	},
	{
		type: 'Inspection Reports',
		title: 'Inspection Sync',
		body: 'Send completed unit and building inspection reports back to Rent Manager upon completion and approval. Unit inspection PDF reports will be uploaded as an Occupancy attachment and also attached to the unit itself.',
		mode: 'write',
		frequency: 12,
		mappings: [
			{
				columns: [
					'Inspection PDF report',
					'Attached to property and current tenants',
				],
			},
		],
	},
	{
		type: 'Service Issues',
		title: 'Service Issue Sync',
		body: 'Create service issues in Rent Manager as you are flagging items for maintenance in RentCheck.',
		mode: 'write',
		frequency: 12,
		mappings: [
			{ columns: ['Notes', 'Service Details'] },
			{ columns: ['Category', 'Category'] },
			{ columns: ['Priority', 'Priority'] },
			{ columns: ['Ok to Enter', 'Access is Authorized'] },
		],
	},
];

const Active = () => {
	const dispatch = useDispatch();

	const subscription = useTypedSelector((state) => state.subscription);
	const { rentManagerIntegrations: integrations } =
		useRentManagerIntegrations();

	const [skills, setSkills] = useState<GetRentManagerSkillsResult>([]);
	const [statuses, setStatuses] =
		useState<GetRentManagerWorkOrderStatusesResult>([]);

	useEffect(() => {
		if (!integrations?.length) {
			return;
		}

		IntegrationsApi.RentManager.getSkills()
			.then((res) => _.uniqBy(res, 'name'))
			.then(setSkills)
			.catch((e) => dispatch(SnackbarActions.showError(e)));

		IntegrationsApi.RentManager.getStatuses()
			.then((res) => _.uniqBy(res, 'name'))
			.then(setStatuses)
			.catch((e) => dispatch(SnackbarActions.showError(e)));
	}, [integrations]);

	const getExternalTeams = () => {
		return IntegrationsApi.RentManager.getLocations().then((result) => {
			return result;
		});
	};

	const getIngestionFilterOptions = () => {
		return getExternalTeams();
	};

	const getUpdatedIntegration = (integration: ApiRentManagerIntegration) => {
		return IntegrationsApi.RentManager.get().then(
			(data) => data.filter((i) => i.id === integration.id)[0]
		);
	};

	const handleSaveIntegration = (
		integration: ApiBaseIntegration,
		basePayload: UpdateRentManagerIntegrationsParams['data'],
		firstTimeSetup: boolean,
		__?: string[],
		ingestionFilters?: IngestionFilterOptionType[]
	) => {
		const payload: UpdateRentManagerIntegrationsParams['data'] = {
			...basePayload,
			enabled_locations: ingestionFilters?.map((f) => f.id),
		};

		return IntegrationsApi.RentManager.update(integration.id, payload).then(
			async () => {
				await IntegrationsApi.RentManager.manualSync();

				if (firstTimeSetup && subscription) {
					const yesNoFromSyncDetails = (syncDetails?: BaseSyncDetails) => {
						return syncDetails?.active ? 'yes' : 'no';
					};

					const syncDetails = basePayload.sync_details as
						| BaseIntegration['sync_details']
						| undefined;

					Analytics.trackEvent('enabled_propify_sync', {
						subscription_id: subscription.id,
						property_management_software: 'Rent Manager',
						building_sync_enabled: yesNoFromSyncDetails(syncDetails?.buildings),
						unit_sync_enabled: yesNoFromSyncDetails(syncDetails?.units),
						resident_sync_enabled: yesNoFromSyncDetails(syncDetails?.residents),
						inspection_sync_enabled: yesNoFromSyncDetails(
							syncDetails?.inspections
						),
					});
				}
			}
		);
	};

	const isInstalled = !!integrations?.length;
	const hasMultiple = (integrations?.length ?? 0) > 1;

	return (
		<>
			{!isInstalled && (
				<>
					<Typography sx={{ marginTop: -5 }}>
						Available as an add on to a RentCheck subscription.
					</Typography>
					<Spacer height={6} />
				</>
			)}

			<InstallButton
				isInstalled={isInstalled}
				InstallModal={InstallModal}
				{...commonProps}
			/>

			{integrations?.map((integration) => {
				const connectionComplete = !!integration?.initial_connection_complete;

				return (
					<>
						<InstalledContent
							{...commonProps}
							title={hasMultiple ? integration.friendly_name : undefined}
							waitForConnection={!connectionComplete}
						/>
						<DataSyncModal
							{...commonProps}
							mappingSections={mappingSections}
							getIntegration={() => getUpdatedIntegration(integration)}
							handleSaveIntegration={handleSaveIntegration}
							teamAssignment={{ extTeamName: 'Location', getExternalTeams }}
							ingestionFilter={{
								name: 'Location',
								selector: 'enabled_locations',
								getFilterOptions: getIngestionFilterOptions,
							}}
							defaultSkill={{
								name: 'service issue category',
								shortName: 'category',
								selector: 'default_skill',
								getOptions: () => skills,
								value: integration?.default_skill,
								setValue: () => {},
							}}
							defaultWorkOrderStatus={{
								name: 'service issue status',
								selector: 'default_work_order_status',
								getOptions: () => statuses,
								value: integration?.default_work_order_status,
								setValue: () => {},
							}}
						/>
					</>
				);
			})}
		</>
	);
};

export default Active;
