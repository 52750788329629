import { firestore } from 'firebase/app';
import _ from 'lodash';
import moment from 'moment-timezone';
import pLimit from 'p-limit';

import {
	InspectionEventsApi,
	InspectionsApi,
	PropertiesApi,
	TeamsApi,
	UserRequestsApi,
} from '@rentcheck/api-frontend';
import {
	ApiInspection,
	ApiInspectionTemplateDigest,
	ApiInspectionWithTemplate,
	ApiUser,
	Approval,
	AssignMethod,
	CompleteSignatureRequestParams,
	CreateError,
	CreateSuccess,
	Feature,
	Rejection,
	ReminderChannel,
	ReminderRecipient,
	ResidentsAssignmentType,
	SendReminderSuccess,
	UpdateInspectionParams,
} from '@rentcheck/types';
import { RecurringOption } from 'components/inspections/create-steps/recurrence';
import fbConfig from 'config/firebase';

import { Dispatch, GetFirebase, GetState } from 'types';
import {
	featureDisplayName,
	isEmbeddedInMobileApp,
	postMessageToNativeApp,
} from 'utils/helpers';

import { Recipient } from 'components/recipients-list';
import { AssignedTeammates } from 'screens/modal-flows-controller/create-inspection/common/assigned-teammates';
import { Analytics } from 'utils';
import * as ModalFlowActions from './modal-flows';
import * as SnackbarActions from './snackbar-actions';

type ReduxFn = (a: Dispatch, b: GetState, c: GetFirebase) => any;

export const requestReview = (
	inspectionId: string,
	dueDate: Date,
	sendTo: string[],
	message: string
) => {
	const fn: ReduxFn = async (dispatch) => {
		const dueMoment = resetMomentTime(moment(dueDate));

		const updatedInspection = await InspectionsApi.requestReview(
			inspectionId,
			dueMoment.format('YYYY-MM-DD'),
			sendTo,
			message
		);

		dispatch({
			type: 'UPDATED_INSPECTIONS',
			inspections: [updatedInspection],
		});

		dispatch({
			type: 'UPDATED_INSPECTION',
			inspection: updatedInspection,
		});
	};

	return fn;
};

export const cancelReview = (inspectionId: string) => {
	const fn: ReduxFn = async (dispatch) => {
		const updatedInspection = await InspectionsApi.cancelReview(inspectionId);

		dispatch({
			type: 'UPDATED_INSPECTIONS',
			inspections: [updatedInspection],
		});

		dispatch({
			type: 'UPDATED_INSPECTION',
			inspection: updatedInspection,
		});
	};

	return fn;
};

const resetMomentTime = (moment: moment.Moment) => {
	return moment.set('h', 12).set('m', 0).set('s', 0);
};

export const bulkCreate = (
	userId: string,
	properties: { id: string }[],
	template: ApiInspectionTemplateDigest,
	fastTrack: boolean,
	selectedFeaturesInfo: {
		feature: string;
		room: string;
	}[],
	dueDate: Date,
	recurrence: RecurringOption,
	assignMethod: AssignMethod,
	recipients: Recipient[],
	inspectionLabel: string,
	residentsType: ResidentsAssignmentType,
	recurrenceEndDate?: Date,
	inviteDate?: Date,
	teammates?: AssignedTeammates,
	notifyResidents?: boolean,
	notifyNote?: string,
	check_automation_conflict_validation?: boolean
) => {
	return async (dispatch: Dispatch) => {
		const propertyIds = properties.map((p) => p.id);
		const emails = recipients.map((r) =>
			typeof r === 'string' ? r : r.emails[0]
		);

		const recurrenceType = () => {
			switch (recurrence) {
				case 'Repeat daily':
					return 'Daily';
				case 'Repeat weekly':
					return 'Weekly';
				case 'Repeat monthly':
					return 'Monthly';
				case 'Repeat quarterly':
					return 'Quarterly';
				case 'Repeat semi-annually':
					return 'Semi-Annually';
				case 'Repeat annually':
					return 'Annually';
				case 'Does not repeat':
					return 'None';
				default:
					return 'None';
			}
		};

		const formattedSelectedFeatures = () => {
			if (!selectedFeaturesInfo.length) return undefined;

			const formattedFeatures = [];
			const featuresByRoom = _.groupBy(selectedFeaturesInfo, (f) => f.room);
			const keys = Object.keys(featuresByRoom);

			for (const key of keys) {
				const roomFeatures = featuresByRoom[key];

				formattedFeatures.push({
					area: key,
					features: roomFeatures.map((f) => f.feature),
				});
			}

			return formattedFeatures;
		};

		const dueMoment = resetMomentTime(moment(dueDate));
		const inviteMoment = resetMomentTime(moment(inviteDate));
		const recurrenceEndMoment = resetMomentTime(moment(recurrenceEndDate));

		const { success, errors, warnings, conflict } = await InspectionsApi.create(
			{
				userId,
				data: {
					inspection_template_id: template.id,
					property_ids: propertyIds,
					due_date: dueMoment.format('YYYY-MM-DD'),
					invite_date: inviteMoment.format('YYYY-MM-DD'),
					user_timezone: moment.tz.guess(),
					label: inspectionLabel,
					fast_track: fastTrack,
					recipients: {
						type: assignMethod,
						emails,
						teammate_emails: extractEmailsFromAssignedTeammates(teammates),
						resident_type: residentsType,
					},
					recurrence: {
						type: recurrenceType(),
						end_date: recurrenceEndMoment.format('YYYY-MM-DD'),
					},
					selected_features: formattedSelectedFeatures(),
					notify_residents: notifyResidents
						? {
								note: notifyNote,
							}
						: undefined,
					check_automation_conflict_validation,
				},
			}
		);

		if (success.length) {
			if (assignMethod === 'emails') {
				Analytics.trackEvent('requested_inspection', {
					inspection_assignee_type: assignMethod,
					inspection_template: template.name,
					template_internal_label: template.internal_label,
					inspection_date: dueDate,
					fast_track: fastTrack ? 'yes' : 'no',
					number_of_recipients: emails.length,
				});
			} else {
				Analytics.trackEvent('created_inspection', {
					inspection_assignee_type: assignMethod,
					inspection_template: template.name,
					template_internal_label: template.internal_label,
					inspection_date: dueDate,
					fast_track: fastTrack ? 'yes' : 'no',
				});
			}

			if (isEmbeddedInMobileApp()) {
				postMessageToNativeApp({
					type: 'created-inspection',
					ids: success.map((s: CreateSuccess) => s.inspection.id),
				});

				postMessageToNativeApp({
					type: 'closed-modal',
					modal: 'create-inspection',
				});
			}

			/**
			 * Fetch inspection data for the first 20 inspections
			 * Doing this sequentially to prevent unnecessary load on the API
			 */
			const limit = pLimit(1);
			await Promise.all(
				_.slice<CreateSuccess>(success, 0, 20).map((sr) =>
					limit(async (sr) => {
						const awsInspection = await InspectionsApi.getById(
							sr.inspection.id
						).catch(() => undefined);

						awsInspection &&
							dispatch({
								type: 'FETCHED_INSPECTION',
								inspection: awsInspection,
							});
					}, sr)
				)
			);
		}

		if (errors.length) {
			await fetchPropertyDataForCreateErrors(errors);
		}

		if (warnings.length) {
			await fetchPropertyDataForCreateErrors(warnings);
		}

		return { success, errors, warnings, conflict };
	};
};

const fetchPropertyDataForCreateErrors = async (errors: CreateError[]) => {
	for (const error of errors) {
		if (error.property.address) {
			continue;
		}

		const property = await PropertiesApi.getById(error.property.id);

		if (!property) {
			continue;
		}

		error.property.address = property.address;
		error.property.city = property.city;
		error.property.zipcode = property.zip_code;
	}
};

export const updateInspection = (
	id: string,
	data: UpdateInspectionParams['data']
) => {
	return async (dispatch: Dispatch) => {
		const inspection = await InspectionsApi.update(id, data);

		dispatch({ type: 'UPDATED_INSPECTION', inspection });

		return inspection;
	};
};

export const updateInspectionsDueDate = (
	inspections: ApiInspection[],
	newDate: Date,
	inviteDate?: Date
) => {
	return async (dispatch: Dispatch) => {
		const updatedInspections = await InspectionsApi.updateDueDate(
			inspections.map((i) => i.id),
			newDate,
			inviteDate
		);

		if (inspections.length > 1) {
			return dispatch({
				type: 'UPDATED_INSPECTIONS',
				inspections: updatedInspections,
			});
		}

		const updatedInspection = await InspectionsApi.getById(
			updatedInspections[0].id
		);

		dispatch({
			type: 'UPDATED_INSPECTION',
			inspection: updatedInspection,
		});

		return;
	};
};

const adjustedAssignMethod = (
	inspection: ApiInspection,
	assignMethod: AssignMethod
) => {
	if (assignMethod !== 'residents') {
		return assignMethod;
	}

	if (inspection.inspection_status === 'Scheduled') {
		return assignMethod;
	}

	return 'emails';
};

export const updateInspectionRecipients = (
	inspection: ApiInspection,
	assignMethod: AssignMethod,
	newRecipients: Recipient[],
	teammates?: AssignedTeammates,
	notifyResidents?: boolean,
	notifyNote?: string
) => {
	return async (dispatch: Dispatch) => {
		const updatedInspection = await InspectionsApi.updateRecipients(
			inspection.id,
			{
				type: adjustedAssignMethod(inspection, assignMethod),
				emails: newRecipients.map((r) =>
					typeof r === 'string' ? r : r.emails[0]
				),
				teammate_emails: extractEmailsFromAssignedTeammates(teammates),
			},
			notifyResidents
				? {
						note: notifyNote,
					}
				: undefined
		);

		dispatch({
			type: 'UPDATED_INSPECTION',
			inspection: updatedInspection,
		});
	};
};

export const signInspection = (
	inspection: ApiInspection,
	note?: string,
	signatureImage?: string,
	signatureText?: string
) => {
	return async (dispatch: Dispatch) => {
		const payload: CompleteSignatureRequestParams['data'] = {
			platform: 'WEB',
			signature_base64: signatureImage,
			signature_text: signatureText,
			note,
		};

		await UserRequestsApi.completeSignatureRequest(inspection.id, payload);

		await InspectionsApi.getById(inspection.id).then((res) => {
			dispatch({
				type: 'UPDATED_INSPECTION',
				inspection: res,
			});

			dispatch({
				type: 'UPDATED_INSPECTIONS',
				inspections: [res],
			});
		});

		dispatch(SnackbarActions.showSuccess('Inspection Signed'));
	};
};

export const rejectInspection = (
	inspection: ApiInspectionWithTemplate,
	rejecter: ApiUser,
	note: string,
	due_date: Date,
	features: Feature[]
) => {
	return async (dispatch: Dispatch) => {
		if (!inspection.completed_by) {
			throw new Error("couldn't get performer info");
		}

		if (!inspection.current_performer) {
			throw new Error("couldn't get performer info");
		}

		const rejection: Rejection = {
			note: note,
			rejected_by: rejecter.id,
			rejected_by_name: rejecter.name,
			notification_sent_to: inspection.current_performer.email,
			rejected_date: firestore.Timestamp.fromDate(new Date()),
			due_date: firestore.Timestamp.fromDate(due_date),
			features: features.map((f) => _.pick(f, ['id', 'name', 'section'])),
		};

		const organization = inspection.team
			? await TeamsApi.getById(inspection.team.id).catch(() => undefined)
			: undefined;

		if (organization) {
			rejection.rejected_by_organization = organization.name;
		}

		const rejectFunction = fbConfig
			.functions()
			.httpsCallable('modelCallableRejectInspection');

		await rejectFunction({ rejecter, inspection, rejection })
			.then((result) => {
				InspectionEventsApi.create(inspection.id, {
					type: 'inspection_status_changed',
					metadata: {
						changed_by_name: rejecter.name,
						inspection_status: 'inspection_rejected',
						emails_sent: result.data.sentEmails,
						note,
						features: rejection.features.map((f) =>
							featureDisplayName(f, ': ')
						),
					},
					shows_in_timeline: true,
				});

				Analytics.trackEvent('Requested inspection revision', {
					inspection_template: inspection.inspection_template.name,
					inspection_id: inspection.id,
					user_id: rejecter.id,
					performed_by: inspection.current_performer?.id ?? '',
					features: features.map((f) => featureDisplayName(f)).join(','),
				});

				return true;
			})
			.catch(() => {
				return false;
			});

		const updatedInspection = await InspectionsApi.getById(inspection.id);
		dispatch({ type: 'UPDATED_INSPECTION', inspection: updatedInspection });
		dispatch({ type: 'UPDATED_INSPECTIONS', inspections: [updatedInspection] });

		return updatedInspection;
	};
};

export const approveInspection = (
	inspection: ApiInspection,
	approver: ApiUser,
	note: string,
	userFacingNote: string,
	rating?: number,
	signatureImage?: string,
	signatureText?: string
) => {
	return async (dispatch: Dispatch) => {
		if (!inspection.completed_by) {
			throw new Error("couldn't get performer info");
		}

		const approval: Approval = {
			note: note,
			rating: rating,
			approved_by: approver.id,
			approved_by_name: approver.name,
			notification_sent_to: inspection.completed_by.email,
			approved_date: firestore.Timestamp.fromDate(new Date()),
		};

		if (userFacingNote) {
			approval.user_facing_note = userFacingNote;
		}

		if (inspection.team) {
			const organization = await TeamsApi.getById(inspection.team.id).catch(
				() => undefined
			);

			if (organization) {
				approval.approved_by_organization = organization.name;
			}
		}

		if (signatureText) {
			approval.signature_text = signatureText;
		}

		const approveFunction = fbConfig
			.functions()
			.httpsCallable('modelCallableApproveInspection');

		await approveFunction({
			approval,
			inspection_id: inspection.id,
			signature_image_data: signatureImage,
		});

		const updatedInspection = await InspectionsApi.getById(inspection.id);
		dispatch({ type: 'UPDATED_INSPECTION', inspection: updatedInspection });
		dispatch({ type: 'UPDATED_INSPECTIONS', inspections: [updatedInspection] });

		return updatedInspection;
	};
};

export const requestSignature = (
	emails: string[],
	inspection: ApiInspection,
	note: string
) => {
	const fn: ReduxFn = async (dispatch, getState) => {
		return UserRequestsApi.createSignatureRequest(inspection.id, {
			emails,
			note,
		}).catch((e) => {
			dispatch(SnackbarActions.showError(e));
			throw e;
		});
	};

	return fn;
};

export const markAsCompleted = (inspection: ApiInspection) => {
	const fn: ReduxFn = async (dispatch) => {
		const updatedStatus = ['residents', 'emails'].includes(
			inspection.assigned_recipients.type
		)
			? 'Review Inspection'
			: 'Inspection Complete';

		return dispatch(
			updateInspection(inspection.id, {
				status: updatedStatus,
			})
		);
	};

	return fn;
};

export const sendReminder = (
	recipients: ReminderRecipient[],
	message: string,
	channels: ReminderChannel[]
) => {
	const fn: ReduxFn = async (dispatch, getState) => {
		const state = getState();
		const currentInspection = state.inspections.inspection;

		const response = await InspectionsApi.sendReminder(
			recipients,
			message,
			channels
		);

		/**
		 * If the user is currently viewing an inspection's details
		 * then dispatch an update so the timeline fetches the newest reminder
		 * events
		 */
		if (currentInspection) {
			dispatch({
				type: 'UPDATED_INSPECTION',
				inspection: { ...currentInspection },
			});
		}

		/**
		 * For each recipient log events in intercom
		 * SMS and email events are separate
		 */
		recipients.forEach((r) => {
			if (channels.includes('email')) {
				Analytics.trackEvent('Sent reminder', {
					inspection_id: r.inspectionId,
					recipient: r.email,
					message,
				});
			}

			if (channels.includes('sms')) {
				const phoneNumbers = [...r.phoneNumbers, ...r.additionalPhoneNumbers];

				Analytics.trackEvent('Sent SMS reminder', {
					inspection_id: r.inspectionId,
					recipient: phoneNumbers.join(', '),
				});
			}
		});

		const successWithDestinations = response.success.filter(
			(s: SendReminderSuccess) => s.destination.length > 0
		);

		const uniqueInspections = _.uniqBy(recipients, 'inspectionId');
		const successMessage =
			uniqueInspections.length === 1
				? `${successWithDestinations.length} reminder(s) were successfully sent.`
				: `${successWithDestinations.length} reminders sent for ${uniqueInspections.length} inspections.`;

		if (response.error.length) {
			dispatch(
				ModalFlowActions.showErrorModal({
					title: 'Send Reminder Errors',
					errors: response.error,
				})
			);
		}

		return dispatch(SnackbarActions.showSuccess(successMessage));
	};

	return fn;
};

export const getInspectionsForComparison = async (
	propertyId: string
): Promise<ApiInspection[]> => {
	return InspectionsApi.getAll(
		false,
		{ page_size: 100, page_number: 0 },
		{
			property: { value: [propertyId], condition: 'any_of' },
			inspection_status: {
				value: ['Completed', 'Approved', 'Awaiting Review', 'Revision Review'],
				condition: 'any_of',
			},
		}
	).then((res) => res.data);
};

export const reopenInspection = (inspection: ApiInspection) => {
	return async (dispatch: Dispatch, getState: GetState) => {
		const profile = getState().authenticatedUser;

		if (!profile) {
			throw new Error('No profile found');
		}

		Analytics.trackEvent('reopened inspection', {
			inspection_id: inspection.id,
			inspection_template: inspection.inspection_template.name,
			user_id: profile.id,
		});

		return dispatch(
			updateInspection(inspection.id, {
				status: 'Continue Inspection',
			})
		);
	};
};

const extractEmailsFromAssignedTeammates = (teammates?: AssignedTeammates) => {
	if (!teammates) return undefined;

	return _.mapValues(teammates, (v) => v.map((p) => p.email));
};
