"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SyncedPropertyRemoval = exports.MaxUnitsReached = void 0;
const http_1 = require("./http");
class MaxUnitsReached extends http_1.BadRequest {
    constructor(maxUnits) {
        super(`max units reached: ${maxUnits}/1`);
    }
}
exports.MaxUnitsReached = MaxUnitsReached;
class SyncedPropertyRemoval extends http_1.Forbidden {
    constructor() {
        super('Cannot delete property when integration sync is active');
    }
}
exports.SyncedPropertyRemoval = SyncedPropertyRemoval;
