"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AlreadyClaimed = void 0;
const http_1 = require("./http");
class AlreadyClaimed extends http_1.Conflict {
    constructor() {
        super(`This invite code has already been claimed by another user.`);
    }
}
exports.AlreadyClaimed = AlreadyClaimed;
