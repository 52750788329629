import { MouseEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { LoadingButton } from '@mui/lab';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Popover,
	useMediaQuery,
	useTheme,
} from '@mui/material';

import { PropertiesApi } from '@rentcheck/api-frontend';
import {
	APIProperty,
	ApiInspectionWithTemplate,
	AssignMethod,
} from '@rentcheck/types';
import { Rules } from '@rentcheck/biz';

import { Dispatch } from 'types';
import RecipientsList, { Recipient } from 'components/recipients-list';
import TeammatesList from 'components/teammates-list';
import AssignMethodList from 'screens/modal-flows-controller/create-inspection/assign/assign-method-list';
import AssignMethodRow from 'screens/modal-flows-controller/create-inspection/assign/assign-method-row';
import { AssignedTeammates } from 'screens/modal-flows-controller/create-inspection/common/assigned-teammates';
import DialogTitle from 'screens/modal-flows-controller/create-inspection/common/inner-dialog-title';
import innerTransition from 'screens/modal-flows-controller/create-inspection/common/inner-transition';
import { InspectionActions, SnackbarActions } from 'store/actions';

interface Props {
	inspection: ApiInspectionWithTemplate;
	open: boolean;
	onClose: () => void;
}

export default ({ inspection, open, onClose }: Props) => {
	const dispatch: Dispatch = useDispatch();
	const theme = useTheme();
	const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));

	const [menuAnchor, setMenuAnchor] = useState<HTMLDivElement | null>(null);

	const [assignMethod, setAssignMethod] = useState<AssignMethod>();
	const [recipients, setRecipients] = useState<Recipient[]>([]);
	const [properties, setProperties] = useState<APIProperty[]>([]);
	const [assignedTeammates, setAssignedTeammates] = useState<AssignedTeammates>(
		{}
	);
	const [notifyChecked, setNotifyChecked] = useState(false);
	const [notifyNote, setNotifyNote] = useState('');
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		PropertiesApi.getById(inspection.property.id).then((result) =>
			setProperties([result])
		);
	}, []);

	useEffect(() => {
		if (!assignMethod) return;

		setRecipients([]);
		setAssignedTeammates({});
	}, [assignMethod]);

	useEffect(() => {
		if (!open) {
			setAssignedTeammates({});
			setNotifyChecked(false);
			setNotifyNote('');
		}
	}, [open]);

	const inspectionScheduled = inspection.inspection_status === 'Scheduled';
	const noTeams = !inspection.team;

	const handleClose = () => {
		setAssignMethod(undefined);
		onClose();
	};

	const handleAssignMethod = (value: AssignMethod) => {
		setMenuAnchor(null);
		setAssignMethod(value);
	};

	const handleChangeAssignMethod = (
		_: AssignMethod,
		e: MouseEvent<HTMLDivElement>
	) => {
		setMenuAnchor(e.currentTarget);
	};

	const handleUpdate = () => {
		if (!assignMethod) return;

		setLoading(true);

		dispatch(
			InspectionActions.updateInspectionRecipients(
				inspection,
				assignMethod,
				recipients,
				assignedTeammates,
				notifyChecked,
				notifyNote
			)
		)
			.then(() => handleClose())
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	};

	const disabled =
		!assignMethod ||
		(assignMethod === 'emails' && recipients.length === 0) ||
		(assignMethod === 'teammates' &&
			Object.values(assignedTeammates).length === 0);

	const showRequested =
		Rules.Inspections.inspectionIsInProgress(inspection) || inspectionScheduled;

	if (!showRequested) return null;

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			fullScreen={mobileScreen}
			TransitionComponent={innerTransition}>
			<DialogTitle title="Assign Inspection" onClose={handleClose} />
			<DialogContent style={{ height: '60vh' }}>
				{!assignMethod && (
					<AssignMethodList
						template={inspection.inspection_template}
						onChange={handleAssignMethod}
						noTeams={noTeams}
					/>
				)}

				{!!assignMethod && (
					<>
						<AssignMethodRow
							variant={assignMethod}
							onClick={handleChangeAssignMethod}
							icon={solid('caret-down')}
						/>
						<Popover
							open={!!menuAnchor}
							anchorEl={menuAnchor}
							PaperProps={{
								style: { width: mobileScreen ? '100%' : 544 },
							}}
							onClose={() => setMenuAnchor(null)}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'center',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'center',
							}}>
							<div style={{ padding: '0 10px' }}>
								<AssignMethodList
									template={inspection.inspection_template}
									onChange={handleAssignMethod}
									noTeams={noTeams}
								/>
							</div>
						</Popover>
					</>
				)}

				{assignMethod === 'emails' && (
					<RecipientsList
						properties={properties}
						value={recipients}
						setValue={setRecipients}
					/>
				)}

				{assignMethod === 'teammates' && (
					<TeammatesList
						properties={properties}
						notifyChecked={notifyChecked}
						setNotifyChecked={setNotifyChecked}
						notifyNote={notifyNote}
						setNotifyNote={setNotifyNote}
						assignedTeammates={assignedTeammates}
						setAssignedTeammates={setAssignedTeammates}
						showNotify={false}
					/>
				)}
			</DialogContent>
			<DialogActions>
				<Button variant="text" color="secondary" onClick={handleClose}>
					Close
				</Button>

				<LoadingButton
					variant="contained"
					onClick={handleUpdate}
					loading={loading}
					disabled={disabled}>
					Update
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};
