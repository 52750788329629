"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reviewStepEnumArray = exports.ValidResidentsAssignmentTypes = exports.ValidAssignMethods = exports.recurrenceType = void 0;
exports.recurrenceType = [
    'None',
    'Daily',
    'Weekly',
    'Monthly',
    'Quarterly',
    'Semi-Annually',
    'Annually',
];
exports.ValidAssignMethods = [
    'residents',
    'self-perform',
    'emails',
    'teammates',
    'myself',
];
exports.ValidResidentsAssignmentTypes = ['current', 'future'];
exports.reviewStepEnumArray = [
    'Initial Review',
    'Secondary Review',
];
