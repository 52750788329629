import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ApiInspectionWithTemplate } from '@rentcheck/types';
import { Column, Screens, Spacer } from 'components';
import { colors } from 'theme';

import DataTextSection from './data-text-section';
import InternalTemplateName from './internal-template-name';
import Overline from './overline';
import Subtitle from './subtitle';
import ViewModeToggleButton from './view-mode-toggle-button';

import Approve from './actions/approve';
import CancelReviewRequest from './actions/cancel-review-request';
import CancelRevisionRequest from './actions/cancel-revision-request';
import Manage from './actions/manage';
import Score from './actions/score';
import Sign from './actions/sign';

import CompleteInitialReview from './actions/complete-initial-review';
import InspectionLink from './secondary-actions/inspection-link';
import ManualSync from './secondary-actions/manual-sync';
import QuickEdits from './secondary-actions/quick-edits';
import ReportDownload from './secondary-actions/report-download';
import ReportLink from './secondary-actions/report-link';
import Revision from './secondary-actions/revision';
import Share from './secondary-actions/share';
import Summary from './secondary-actions/summary';

interface Props {
	inspection: ApiInspectionWithTemplate;
}

export default ({ inspection }: Props) => {
	return (
		<>
			<Screens.Header
				overline={<Overline inspection={inspection} />}
				title={
					<>
						{inspection.fast_track && (
							<FontAwesomeIcon
								icon={solid('bolt-lightning')}
								color={colors.primary}
								size="2xs"
								style={{ marginRight: 4 }}
							/>
						)}
						{inspection.inspection_template.name} Inspection
					</>
				}
				subtitle={
					<Column>
						<InternalTemplateName inspection={inspection} />
						<Subtitle inspection={inspection} />
					</Column>
				}
				titleCompanion={<Score inspection={inspection} />}
				actions={[
					<Approve inspection={inspection} />,
					<CompleteInitialReview inspection={inspection} />,
					<CancelReviewRequest inspection={inspection} />,
					<CancelRevisionRequest inspection={inspection} />,
					<Sign inspection={inspection} />,
					<Manage inspection={inspection} />,
				]}
				secondaryActions={[
					<ManualSync inspection={inspection} />,
					<Revision inspection={inspection} />,
					<Share inspection={inspection} />,
					<ReportLink inspection={inspection} />,
					<ReportDownload inspection={inspection} />,
					<InspectionLink inspection={inspection} />,
					<QuickEdits inspection={inspection} />,
					<Summary inspection={inspection} />,
				]}
				tertiaryActions={[<ViewModeToggleButton inspection={inspection} />]}
			/>
			<DataTextSection inspection={inspection} />
			<Spacer height={8} />
		</>
	);
};
