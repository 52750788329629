"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoOrganizationsForSubscription = exports.NoChargebeeCustomerId = exports.NoChargebeeId = void 0;
const http_1 = require("./http");
class NoChargebeeId extends http_1.NotFound {
    constructor(id) {
        super(`No chargebee sub_id for ${id}`);
    }
}
exports.NoChargebeeId = NoChargebeeId;
class NoChargebeeCustomerId extends http_1.NotFound {
    constructor(id) {
        super(`No chargebee customer_id for ${id}`);
    }
}
exports.NoChargebeeCustomerId = NoChargebeeCustomerId;
class NoOrganizationsForSubscription extends http_1.NotFound {
    constructor(id) {
        super(`No organizations for subscription ${id}`);
    }
}
exports.NoOrganizationsForSubscription = NoOrganizationsForSubscription;
