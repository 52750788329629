"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TooMany = void 0;
const _1 = require(".");
class TooMany extends _1.Conflict {
    constructor() {
        super('too many subscriptions');
    }
}
exports.TooMany = TooMany;
