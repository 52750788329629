"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IntegrationNotAllowed = exports.InvalidCredentials = exports.IntegrationAlreadyExistsForSubscription = exports.IntegrationAlreadyExists = void 0;
const http_1 = require("./http");
class IntegrationAlreadyExists extends http_1.BadRequest {
    constructor() {
        super(`integration already exist for client_id and client_secret`);
    }
}
exports.IntegrationAlreadyExists = IntegrationAlreadyExists;
class IntegrationAlreadyExistsForSubscription extends http_1.BadRequest {
    constructor() {
        super(`integration already exist for subscription`);
    }
}
exports.IntegrationAlreadyExistsForSubscription = IntegrationAlreadyExistsForSubscription;
class InvalidCredentials extends http_1.BadRequest {
    constructor() {
        super('invalid credentials');
    }
}
exports.InvalidCredentials = InvalidCredentials;
class IntegrationNotAllowed extends http_1.Forbidden {
    constructor(integrationName) {
        super(`${integrationName} integration is not allowed for this account`);
    }
}
exports.IntegrationNotAllowed = IntegrationNotAllowed;
