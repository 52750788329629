"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RentvineObjectTypeId = exports.RentvineLeaseStatuses = exports.RentvinePropertyTypes = void 0;
exports.RentvinePropertyTypes = [
    'Single Family Home',
    'Apartment',
    'Condo',
    'Townhouse',
    'Duplex',
    'Multiplex',
    'Loft',
    'Mobile Home',
    'Commercial',
];
var RentvineLeaseStatuses;
(function (RentvineLeaseStatuses) {
    RentvineLeaseStatuses["Pending"] = "1";
    RentvineLeaseStatuses["Active"] = "2";
    RentvineLeaseStatuses["Closed"] = "3";
})(RentvineLeaseStatuses = exports.RentvineLeaseStatuses || (exports.RentvineLeaseStatuses = {}));
var RentvineObjectTypeId;
(function (RentvineObjectTypeId) {
    RentvineObjectTypeId[RentvineObjectTypeId["Account"] = 1] = "Account";
    RentvineObjectTypeId[RentvineObjectTypeId["User"] = 2] = "User";
    RentvineObjectTypeId[RentvineObjectTypeId["Contact"] = 3] = "Contact";
    RentvineObjectTypeId[RentvineObjectTypeId["Lease"] = 4] = "Lease";
    RentvineObjectTypeId[RentvineObjectTypeId["Bill"] = 5] = "Bill";
    RentvineObjectTypeId[RentvineObjectTypeId["Property"] = 6] = "Property";
    RentvineObjectTypeId[RentvineObjectTypeId["Unit"] = 7] = "Unit";
    RentvineObjectTypeId[RentvineObjectTypeId["Deposit"] = 8] = "Deposit";
    RentvineObjectTypeId[RentvineObjectTypeId["Accounting_Transaction"] = 9] = "Accounting_Transaction";
    RentvineObjectTypeId[RentvineObjectTypeId["Accounting_Transaction_Entry"] = 10] = "Accounting_Transaction_Entry";
    RentvineObjectTypeId[RentvineObjectTypeId["Portfolio"] = 11] = "Portfolio";
    RentvineObjectTypeId[RentvineObjectTypeId["Payout"] = 12] = "Payout";
    RentvineObjectTypeId[RentvineObjectTypeId["Bank_Adjustment"] = 13] = "Bank_Adjustment";
    RentvineObjectTypeId[RentvineObjectTypeId["Company"] = 14] = "Company";
    RentvineObjectTypeId[RentvineObjectTypeId["Statement"] = 15] = "Statement";
    RentvineObjectTypeId[RentvineObjectTypeId["Work_Order"] = 16] = "Work_Order";
    RentvineObjectTypeId[RentvineObjectTypeId["Inspection"] = 17] = "Inspection";
    RentvineObjectTypeId[RentvineObjectTypeId["Inspection_Area"] = 18] = "Inspection_Area";
    RentvineObjectTypeId[RentvineObjectTypeId["Inspection_Item"] = 19] = "Inspection_Item";
    RentvineObjectTypeId[RentvineObjectTypeId["Application"] = 20] = "Application";
    RentvineObjectTypeId[RentvineObjectTypeId["Applicant"] = 21] = "Applicant";
    RentvineObjectTypeId[RentvineObjectTypeId["Bank_Transfer"] = 22] = "Bank_Transfer";
    RentvineObjectTypeId[RentvineObjectTypeId["Listing"] = 23] = "Listing";
    RentvineObjectTypeId[RentvineObjectTypeId["Appliance"] = 24] = "Appliance";
    RentvineObjectTypeId[RentvineObjectTypeId["Text_Message"] = 25] = "Text_Message";
    RentvineObjectTypeId[RentvineObjectTypeId["Email_Message"] = 26] = "Email_Message";
    RentvineObjectTypeId[RentvineObjectTypeId["Work_Order_Estimate"] = 27] = "Work_Order_Estimate";
    RentvineObjectTypeId[RentvineObjectTypeId["Settlement"] = 28] = "Settlement";
    RentvineObjectTypeId[RentvineObjectTypeId["Lease_Tenant"] = 29] = "Lease_Tenant";
    RentvineObjectTypeId[RentvineObjectTypeId["Email_Template"] = 30] = "Email_Template";
    RentvineObjectTypeId[RentvineObjectTypeId["Note"] = 31] = "Note";
    RentvineObjectTypeId[RentvineObjectTypeId["File_Attachment"] = 32] = "File_Attachment";
    RentvineObjectTypeId[RentvineObjectTypeId["Vendor_Bill"] = 33] = "Vendor_Bill";
    RentvineObjectTypeId[RentvineObjectTypeId["Document_Transaction"] = 34] = "Document_Transaction";
    RentvineObjectTypeId[RentvineObjectTypeId["Document_Envelope"] = 35] = "Document_Envelope";
    RentvineObjectTypeId[RentvineObjectTypeId["Application_Template"] = 36] = "Application_Template";
    RentvineObjectTypeId[RentvineObjectTypeId["Recurring_Bill"] = 37] = "Recurring_Bill";
    RentvineObjectTypeId[RentvineObjectTypeId["Chat_Message"] = 38] = "Chat_Message";
    RentvineObjectTypeId[RentvineObjectTypeId["Reconciliation"] = 39] = "Reconciliation";
    RentvineObjectTypeId[RentvineObjectTypeId["Path"] = 40] = "Path";
    RentvineObjectTypeId[RentvineObjectTypeId["Payout_Return"] = 41] = "Payout_Return";
    RentvineObjectTypeId[RentvineObjectTypeId["Management_Fee_Setting"] = 42] = "Management_Fee_Setting";
    RentvineObjectTypeId[RentvineObjectTypeId["Additional_Management_Fee_Setting"] = 43] = "Additional_Management_Fee_Setting";
    RentvineObjectTypeId[RentvineObjectTypeId["Accounting_Setting"] = 44] = "Accounting_Setting";
    RentvineObjectTypeId[RentvineObjectTypeId["Posting_Setting"] = 45] = "Posting_Setting";
    RentvineObjectTypeId[RentvineObjectTypeId["Late_Fee_Setting"] = 46] = "Late_Fee_Setting";
    RentvineObjectTypeId[RentvineObjectTypeId["Statement_Setting"] = 47] = "Statement_Setting";
    RentvineObjectTypeId[RentvineObjectTypeId["Payout_Batch"] = 48] = "Payout_Batch";
    RentvineObjectTypeId[RentvineObjectTypeId["Letter"] = 49] = "Letter";
    RentvineObjectTypeId[RentvineObjectTypeId["Reminder"] = 50] = "Reminder";
    RentvineObjectTypeId[RentvineObjectTypeId["Review"] = 51] = "Review";
})(RentvineObjectTypeId = exports.RentvineObjectTypeId || (exports.RentvineObjectTypeId = {}));
