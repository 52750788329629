import { Checkbox, Tooltip, Typography } from '@mui/material';

import { Rules } from '@rentcheck/biz';
import { ApiInspection, APIProperty } from '@rentcheck/types';

import { Row } from 'components';
import { CreateWorkOrderMetadata } from 'store/reducers/modal-flows';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { simplePropertyType } from 'utils/helpers';
import { useAppFolioIntegration } from 'hooks/appfolio-integration';

interface Props {
	inspection: ApiInspection;
	property: APIProperty;
	value?: CreateWorkOrderMetadata['provider'];
	setValue: (value?: CreateWorkOrderMetadata['provider']) => void;
}

const AppFolioWorkOrderIntegration = ({ property, value, setValue }: Props) => {
	const subscription = useTypedSelector((state) => state.subscription);
	const { appFolioIntegration } = useAppFolioIntegration();

	const createWorkOrdersResult = Rules.WorkOrders.canCreateWorkOrders(
		'appfolio',
		appFolioIntegration,
		subscription,
		property
	);

	if (createWorkOrdersResult === 'not allowed') return null;

	if (createWorkOrdersResult === 'not synced') {
		return (
			<Row style={{ marginTop: 24, marginLeft: -12 }}>
				<Tooltip
					title={`This ${simplePropertyType(property)} is not synced with AppFolio.`}>
					<span>
						<Checkbox disabled />
					</span>
				</Tooltip>
				<Typography>Create AppFolio Work Order</Typography>
			</Row>
		);
	}

	return (
		<Row style={{ marginTop: 24, marginLeft: -12 }}>
			<Checkbox
				checked={value === 'AppFolio'}
				onChange={() => setValue(value ? undefined : 'AppFolio')}
			/>
			<Typography>Create AppFolio Work Order</Typography>
		</Row>
	);
};

export default AppFolioWorkOrderIntegration;
