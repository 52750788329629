import { Skeleton as MuiSkeleton } from '@mui/material';

export const Skeleton = () => {
	return (
		<>
			<MuiSkeleton
				variant="rectangular"
				height={200}
				width="100%"
				sx={{ borderRadius: 1, mb: 3 }}
			/>
			<MuiSkeleton
				variant="rectangular"
				height={200}
				width="100%"
				sx={{ borderRadius: 1, mb: 3 }}
			/>
			<MuiSkeleton
				variant="rectangular"
				height={200}
				width="100%"
				sx={{ borderRadius: 1, mb: 3 }}
			/>
		</>
	);
};

export default Skeleton;
