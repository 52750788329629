import { Rules } from '@rentcheck/biz';
import { ApiInspectionWithTemplate } from '@rentcheck/types';
import MenuItem from 'components/menu-item';
import { useDispatch } from 'react-redux';
import { InspectionActions, SnackbarActions } from 'store/actions';
import { Dispatch } from 'types';

interface Props {
	inspection: ApiInspectionWithTemplate;
	onSelected: () => void;
}

export default ({ inspection, onSelected }: Props) => {
	const dispatch = useDispatch<Dispatch>();

	const handleClick = async () => {
		dispatch(
			InspectionActions.updateInspection(inspection.id, {
				is_initial_review_complete: false,
			})
		).catch((e) => dispatch(SnackbarActions.showError(e.message)));

		onSelected();
	};

	if (!Rules.Inspections.canUndoFirstStepReview(inspection)) {
		return null;
	}

	return <MenuItem onClick={handleClick}>Return to Initial Review</MenuItem>;
};
