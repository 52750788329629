import { useRef, useState } from 'react';
import styled from 'styled-components';
import { Menu, Tooltip, TooltipProps, Typography } from '@mui/material';
import shadows from '@mui/material/styles/shadows';

import { ApiInspection } from '@rentcheck/types';
import { Rules } from '@rentcheck/biz';

import { useIsMobileBreakpoint } from 'utils/hooks';

import TooltipTitle from './tooltip-title';

interface Props {
	inspection: ApiInspection;
}

export default ({ inspection }: Props) => {
	const isMobile = useIsMobileBreakpoint('md');

	const bubbleRef = useRef<HTMLDivElement>(null);

	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

	if (Rules.Inspections.inspectionIsInProgress(inspection)) {
		return null;
	}

	if (!inspection.health_score) return null;

	const score = inspection.health_score;

	score.score = Math.min(score.score, 100);

	const backgroundColor =
		score.score <= 49 ? '#FBE1B5' : score.score < 80 ? '#CCD6E1' : '#A3FFC0';

	const tooltipProps: Partial<TooltipProps> = {
		placement: 'bottom-end',
		componentsProps: {
			tooltip: {
				sx: {
					bgcolor: 'white',
					boxShadow: shadows[1],
				},
			},
		},
	};

	if (!isMobile) {
		return (
			<Tooltip
				{...tooltipProps}
				title={<TooltipTitle inspection={inspection} />}>
				<ScoreBubble backgroundColor={backgroundColor}>
					<Typography variant="h5">{score.score.toFixed()}</Typography>
				</ScoreBubble>
			</Tooltip>
		);
	}

	const handleOnClose = () => {
		setAnchorEl(null);
	};

	const handleOnClick = () => {
		setAnchorEl(bubbleRef.current);
	};

	return (
		<>
			<Menu
				open={!!anchorEl}
				anchorEl={anchorEl}
				onClose={handleOnClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
				transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
				<TooltipTitle inspection={inspection} />
			</Menu>

			<ScoreBubble
				isMobile
				ref={bubbleRef}
				backgroundColor={backgroundColor}
				onClick={handleOnClick}>
				<Typography variant="h5">{score.score.toFixed()}</Typography>
			</ScoreBubble>
		</>
	);
};

const ScoreBubble = styled.div<{ backgroundColor: string; isMobile?: boolean }>`
	margin-left: 8px;
	height: 48px;
	width: 48px;
	border-radius: 24px;
	margin-top: -6px;
	margin-bottom: -6px;
	background-color: ${(props) =>
		props.backgroundColor ? props.backgroundColor : '#FBE1B5'};
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: ${(isMobile) => (isMobile ? 'pointer' : 'default')};
`;
