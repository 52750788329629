import { Card, CardContent, Typography } from '@mui/material';
import { Column, Spacer } from 'components';

import UploadFile from './upload-file';

const MergeProperties = () => {
	return (
		<Card>
			<CardContent>
				<Column style={{ width: '50%' }}>
					<Typography variant="h5">Merge Properties</Typography>
					<Spacer height={2} />

					<Typography variant="body1">
						Upload a file to merge properties containing mapping pairs where
						each unsynced (duplicate) property (origin_id) is matched with its
						corresponding synced property (target_id).
					</Typography>
					<Spacer height={2} />

					<UploadFile />
				</Column>
			</CardContent>
		</Card>
	);
};

export default MergeProperties;
