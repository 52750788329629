import { useIntercom } from 'react-use-intercom';

import { Button, PlanGate } from 'components';

const Inactive = () => {
	const { showNewMessage } = useIntercom();

	const handleChatToInstall = () => {
		showNewMessage();
	};

	return (
		<PlanGate variant="professional">
			<Button onClick={handleChatToInstall}>Install</Button>
		</PlanGate>
	);
};

export default Inactive;
