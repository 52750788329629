import { Button, Card, CardContent, Typography } from '@mui/material';
import { BaseIntegration } from '@rentcheck/types';
import { Spacer } from 'components';
import { useEffect, useState } from 'react';

import _ from 'lodash';
import { colors } from 'theme';
import { TeamAssignmentType } from '../types';
import TeamAssignmentModal from './modal';

interface Props {
	vendor: string;
	teamAssignment: TeamAssignmentType;
	teamMappings?: BaseIntegration['team_mappings'];
	setTeamMappings: (value?: BaseIntegration['team_mappings']) => void;
	error: boolean;
}

const TeamAssignment = ({
	vendor,
	teamAssignment,
	teamMappings,
	setTeamMappings,
	error,
}: Props) => {
	const [modalOpen, setModalOpen] = useState(false);

	/**
	 * When teamMappings change we fetch the current available external teams
	 * and filter out any team mappings that are no longer valid
	 * (e.g. the external team was deleted)
	 */
	useEffect(() => {
		teamAssignment.getExternalTeams().then((result) => {
			const validExternalTeamIds = result.map((o) => o.id);

			/**
			 * For a team mapping to be valid either the key needs to be
			 * in the list of valid external team ids or it needs to be the 'default'
			 * key used as a fallback
			 */
			const validExistingTeamMappings = _.pickBy(
				teamMappings,
				(v, k) => validExternalTeamIds.includes(k) || k === 'default'
			);

			/**
			 * To avoid unnecessary re-renders we only update the team mappings
			 * if the number of keys in the team mappings object has changed
			 * (i.e. a team mapping was removed)
			 */
			if (
				_.keys(teamMappings).length !== _.keys(validExistingTeamMappings).length
			) {
				setTeamMappings(validExistingTeamMappings);
			}
		});
	}, [teamMappings]);

	const handleCloseModal = () => {
		setModalOpen(false);
	};

	const handleOpenModal = () => {
		setModalOpen(true);
	};

	const buttonTitle = teamMappings?.default
		? 'Edit team assignment'
		: 'Set up team assignment';

	return (
		<>
			<Card sx={{ marginBottom: 4 }}>
				<CardContent>
					<Typography variant="subtitle1">Team Assignment</Typography>
					<Spacer height={2} />
					<Typography variant="body2" color="#00000099">
						Map {vendor} properties to RentCheck teams. As updates are made in{' '}
						{vendor}, RentCheck team assignments will be also be automatically
						updated in RentCheck on a daily basis.
					</Typography>

					<Spacer height={4} />

					{error && (
						<Typography color={colors.error} sx={{ marginBottom: 2 }}>
							Please select a default team for unmapped properties
						</Typography>
					)}

					<Button variant="outlined" onClick={handleOpenModal}>
						{buttonTitle}
					</Button>
				</CardContent>
			</Card>

			<TeamAssignmentModal
				open={modalOpen}
				handleClose={handleCloseModal}
				vendor={vendor}
				teamMappings={teamMappings}
				setTeamMappings={setTeamMappings}
				teamAssignment={teamAssignment}
			/>
		</>
	);
};

export default TeamAssignment;
