import { Rules } from '@rentcheck/biz';
import { ApiInspection } from '@rentcheck/types';
import MenuItem from 'components/menu-item';
import { useDispatch } from 'react-redux';
import { InspectionActions, SnackbarActions } from 'store/actions';
import { Dispatch } from 'types';

interface Props {
	inspection: ApiInspection;
	onSelected: () => void;
}

export default ({ inspection, onSelected }: Props) => {
	const dispatch = useDispatch<Dispatch>();

	const handleClick = () => {
		onSelected();

		dispatch(
			InspectionActions.updateInspection(inspection.id, {
				archived: true,
			})
		).then(() => dispatch(SnackbarActions.showSuccess('Inspection archived')));
	};

	if (!Rules.Inspections.canArchive(inspection)) {
		return null;
	}

	return <MenuItem onClick={handleClick}>Archive</MenuItem>;
};
