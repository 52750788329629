"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileTooLarge = void 0;
const http_1 = require("./http");
class FileTooLarge extends http_1.BadRequest {
    constructor(fileSize) {
        super(`Report size over 30MB. File size ${fileSize}MB.`);
    }
}
exports.FileTooLarge = FileTooLarge;
