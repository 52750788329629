import { Badge, Tooltip } from '@mui/material';
import { Rules } from '@rentcheck/biz';
import { ApiInspectionWithTemplate, SyncData } from '@rentcheck/types';
import { integrations } from 'assets';
import { Spacer } from 'components';
import { useIntercom } from 'react-use-intercom';
import { formatDateAndTime } from 'utils/helpers';

interface Props {
	inspection: ApiInspectionWithTemplate;
}

const SyncStatus = ({ inspection }: Props) => {
	const { showNewMessage } = useIntercom();

	const handleContactSupport = () => {
		showNewMessage(
			`Problem syncing ${inspection.inspection_template.name} inspection (id: ${inspection.id})`
		);
	};

	if (!Rules.Inspections.canShowSyncStatus(inspection)) {
		return null;
	}

	if (!inspection.sync_data) {
		return null;
	}

	if (
		inspection.sync_data.status === 'scheduled' ||
		inspection.sync_data.status === 'processing'
	) {
		return null;
	}

	const icons: Record<SyncData['vendor'], string> = {
		appfolio: integrations.appFolio,
		rentmanager: integrations.rentManager,
		yardi: integrations.yardiBlue,
		rentvine: integrations.rentvine,
		jenark: '',
	};

	const icon = icons[inspection.sync_data.vendor];
	const lastSync = inspection.sync_data.last_sync;

	if (inspection.sync_data.error) {
		return (
			<Badge
				color="error"
				overlap="circular"
				badgeContent=""
				sx={{
					marginRight: 1,
					'.MuiBadge-badge': {
						height: 12,
						minWidth: 12,
						margin: '-1px -1px',
						fontSize: 10,
					},
				}}>
				<Tooltip
					title={
						<>
							Sync failed on {formatDateAndTime(lastSync)}.
							<Spacer height={1} />
							We will automatically retry this upload, if the issue persists{' '}
							<span
								style={{ cursor: 'pointer', textDecoration: 'underline' }}
								onClick={handleContactSupport}>
								contact customer support
							</span>
							.
						</>
					}>
					<img alt="" src={icon} style={{ width: 18, height: 18 }} />
				</Tooltip>
			</Badge>
		);
	}

	return (
		<Tooltip title={`Last Synced on ${formatDateAndTime(lastSync)}`}>
			<img
				alt=""
				src={icon}
				style={{ width: 19, height: 19, marginBottom: -5.5, marginRight: 4 }}
			/>
		</Tooltip>
	);
};

export default SyncStatus;
