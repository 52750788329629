import { Flatfile } from '@flatfile/api';

export const sheetConfig: Flatfile.SheetConfig = {
	name: 'Sheet Name',
	description: 'Sheet Description',
	slug: 'sheet_slug',

	fields: [
		{
			key: 'origin_id',
			type: 'string',
			label: 'Origin Property Id',
			constraints: [{ type: 'required' }],
		},
		{
			key: 'target_id',
			type: 'string',
			label: 'Target Property Id',
			constraints: [{ type: 'required' }],
		},
	],

	actions: undefined,
};
