import { Screens } from 'components';
import { ReactNode } from 'react';

interface Props {
	children: ReactNode | React.ReactNode[];
}

export default ({ children }: Props) => {
	return (
		<Screens.Container>
			<Screens.Header
				title="Notification Preferences"
				showBreadCrumbs
				subtitle="Control which messages you receive from RentCheck based on your preferences."
			/>
			{children}
		</Screens.Container>
	);
};
