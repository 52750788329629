import { Action } from 'redux';
import { ModalFlowActions } from 'store/actions';
import { colors } from 'theme';
import { ReduxFn } from 'types';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
	DeleteInspectionTemplateResult,
	UpdateInspectionTemplateResult,
} from '@rentcheck/types';

export const showDeleteErrorModal = (
	dispatch: (action: Action | ReduxFn) => Promise<any>,
	result:
		| UpdateInspectionTemplateResult['data']
		| DeleteInspectionTemplateResult['data']
) => {
	if (result.error?.reason === 'in-use') {
		const automations = result.error?.automation_names.join(', ');
		return dispatch(
			ModalFlowActions.showConfirmationModal({
				title: `Template In Use`,
				body1: [
					`This template cannot be unpublished or deleted because it's currently used in the automation(s): ${automations}`,
					'Please update the automation(s) to proceed.',
				],
				iconColor: colors.warning,
				icon: solid('brake-warning'),
				buttons: [
					{
						title: 'Ok',
						color: 'primary',
						onClick: async () => {
							dispatch(ModalFlowActions.closeConfirmationModal());
						},
					},
				],
			})
		);
	}
};
