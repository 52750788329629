"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeleteRemainingGroups = void 0;
const http_1 = require("./http");
class DeleteRemainingGroups extends http_1.Forbidden {
    constructor() {
        super(`At least one permission group must remain on the subscription`);
    }
}
exports.DeleteRemainingGroups = DeleteRemainingGroups;
