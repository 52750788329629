"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserAlreadyInTeam = void 0;
const http_1 = require("./http");
class UserAlreadyInTeam extends http_1.Forbidden {
    constructor() {
        super(`user is already part of a team`);
    }
}
exports.UserAlreadyInTeam = UserAlreadyInTeam;
