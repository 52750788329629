"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IntegrationWithNoTeamMappings = exports.IntegrationWithNoSyncDetails = exports.LocationNotMapped = exports.LocationNotEnabled = exports.IntegrationNotFound = exports.PropexoSyncError = void 0;
const _1 = require(".");
class PropexoSyncError extends _1.RentCheckException {
    constructor() {
        super(...arguments);
        this.name = 'PropexoSyncError';
    }
}
exports.PropexoSyncError = PropexoSyncError;
class IntegrationNotFound extends PropexoSyncError {
    constructor(id) {
        super(`invalid rentmanager integration "${id}"`, 404);
    }
}
exports.IntegrationNotFound = IntegrationNotFound;
class LocationNotEnabled extends PropexoSyncError {
    constructor(id) {
        super(`location not enabled "${id}"`, 400);
    }
}
exports.LocationNotEnabled = LocationNotEnabled;
class LocationNotMapped extends PropexoSyncError {
    constructor(id) {
        super(`location not mapped "${id}"`, 400);
    }
}
exports.LocationNotMapped = LocationNotMapped;
class IntegrationWithNoSyncDetails extends PropexoSyncError {
    constructor() {
        super('integration with no sync details', 400);
    }
}
exports.IntegrationWithNoSyncDetails = IntegrationWithNoSyncDetails;
class IntegrationWithNoTeamMappings extends PropexoSyncError {
    constructor() {
        super('Integration with no team mappings', 400);
    }
}
exports.IntegrationWithNoTeamMappings = IntegrationWithNoTeamMappings;
