"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PropertyNotSyncedWithIntegration = exports.NotSyncedWithRentManager = exports.PropertyNotFromIntegration = exports.InspectionAlreadySynced = void 0;
const http_1 = require("./http");
class InspectionAlreadySynced extends http_1.BadRequest {
    constructor() {
        super('Inspection already synced');
    }
}
exports.InspectionAlreadySynced = InspectionAlreadySynced;
class PropertyNotFromIntegration extends http_1.BadRequest {
    constructor() {
        super('Property not from integration');
    }
}
exports.PropertyNotFromIntegration = PropertyNotFromIntegration;
class NotSyncedWithRentManager extends http_1.BadRequest {
    constructor(object) {
        super(`${object} is not synced with rent manager`);
    }
}
exports.NotSyncedWithRentManager = NotSyncedWithRentManager;
class PropertyNotSyncedWithIntegration extends http_1.BadRequest {
    constructor() {
        super('property not synced with integration');
    }
}
exports.PropertyNotSyncedWithIntegration = PropertyNotSyncedWithIntegration;
