"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvalidInspectionStatus = exports.InvalidRequest = void 0;
const http_1 = require("./http");
class InvalidRequest extends http_1.BadRequest {
    constructor(requestId, inspectionId) {
        super(`request ${requestId} isn't valid for inspection ${inspectionId}`);
    }
}
exports.InvalidRequest = InvalidRequest;
class InvalidInspectionStatus extends http_1.BadRequest {
    constructor(status) {
        super(`inspection must have status "Continue Inspection", got ${status} instead`);
    }
}
exports.InvalidInspectionStatus = InvalidInspectionStatus;
