import { Dialog, DialogContent, DialogTitle, Divider } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Feature } from '@rentcheck/types';

import { Row, Spacer } from 'components';
import { useEditFeatureModalData } from 'hooks/modals';
import {
	FeatureActions,
	ModalFlowActions,
	SnackbarActions,
} from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';
import { calculateFeatureChangeset, imageIs360Photo } from 'utils/helpers';

import Header from './header';
import InstructionsModal from './instructions';
import Tools from './tools';

import { InspectionsApi } from '@rentcheck/api-frontend';
import { FeatureWithMetadata } from 'store/reducers/features';
import styled from 'styled-components';
import { ViewerProps } from '../../image/common';
import Image from '../../image/image';
import Image360 from '../../image/image-360';
import Video from '../../image/video';
import InfoSidebar from './info-sidebar';

interface Props {
	open: boolean;
}

const EditFeature = ({ open }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const profile = useTypedSelector((state) => state.activeProfile);

	const modalData = useEditFeatureModalData();
	const { features, inspection, media } = modalData?.metadata ?? {};

	const [currentFeature, setCurrentFeature] =
		useState<FeatureWithMetadata | null>(null);

	const [showInstructions, setShowInstructions] = useState(false);
	const [featurePayload, setFeaturePayload] = useState<Partial<Feature>>({});
	const [saveLoading, setSaveLoading] = useState(false);

	const setToCurrentFeature = () => {
		if (!currentFeature) return;

		setFeaturePayload({
			rating: currentFeature.rating,
			responses: currentFeature.responses,
			notes: currentFeature.notes ?? '',
			not_applicable: currentFeature.not_applicable,
			images: currentFeature.images,
		});
	};

	useEffect(() => {
		if (!open || !profile) {
			return;
		}

		setShowInstructions(!profile.feature_editing_dismissed);
	}, [open, profile]);

	useEffect(() => {
		if (!modalData?.metadata?.initialFeature) {
			return;
		}

		setCurrentFeature(modalData.metadata.initialFeature);
	}, [modalData]);

	useEffect(() => {
		if (!features || !currentFeature) {
			return;
		}

		const updatedCurrentFeature = features.find(
			(f) => f.id === currentFeature.id
		);

		if (!updatedCurrentFeature) {
			return;
		}

		setCurrentFeature(updatedCurrentFeature);
	}, [features]);

	useEffect(() => {
		setToCurrentFeature();
	}, [currentFeature]);

	const handleClose = () => {
		setToCurrentFeature();
		setCurrentFeature(null);
		dispatch(ModalFlowActions.closeCurrentModal());
	};

	const handleSave = async () => {
		if (!currentFeature) {
			return;
		}

		const changeset = calculateFeatureChangeset(
			currentFeature,
			featurePayload,
			profile
		);

		const emptyResponse =
			changeset.questions.find((q) => q.updated.length === 0)?.updated === '' ||
			changeset.questions.find((q) => !q.updated.trim().length);

		if (emptyResponse) {
			return dispatch(
				SnackbarActions.showError(
					'Responses to text questions are required to edit this feature.'
				)
			);
		}

		if (
			!changeset.note &&
			!changeset.rating &&
			!changeset.images.addded.length &&
			!changeset.images.removed.length &&
			!changeset.questions.length
		) {
			return handleClose();
		}

		setSaveLoading(true);

		if (featurePayload.rating === undefined) {
			delete featurePayload.rating;
		}

		await dispatch(
			FeatureActions.updateFeature(currentFeature.id, featurePayload)
		)
			.then(() => InspectionsApi.logEdit(inspection.id, { changeset }))
			.then(handleClose)
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setSaveLoading(false));
	};

	if (!currentFeature) {
		return null;
	}

	if (!media) {
		return null;
	}

	if (showInstructions) {
		return (
			<InstructionsModal
				open={showInstructions}
				setOpen={setShowInstructions}
				userId={profile.id}
				handleClose={handleClose}
			/>
		);
	}

	const viewerProps: ViewerProps = {
		imageUri: media,
		feature: currentFeature,
		handleNext: () => {},
		handlePrevious: () => {},
		mode: 'edit',
		initialPosition: modalData?.metadata?.initialPosition,
		initialScale: modalData?.metadata?.initialScale,
	};

	const viewerMode = () => {
		if (currentFeature.videos.includes(media)) {
			return 'video';
		}

		if (imageIs360Photo(media)) {
			return 'image360';
		}

		return 'image';
	};

	return (
		<>
			<Dialog
				fullScreen
				open={open}
				onClose={handleClose}
				transitionDuration={0}>
				<DialogTitle sx={{ paddingLeft: 4, paddingRight: 4 }}>
					<Header feature={currentFeature} />
					<Spacer height={4} />
					<Divider />
				</DialogTitle>
				<DialogContent>
					<Container
						style={{
							width: '100%',
							height: '100%',
							alignItems: 'flex-start',
							justifyContent: 'flex-start',
						}}>
						<InfoSidebar
							feature={currentFeature}
							featurePayload={featurePayload}
							setFeaturePayload={setFeaturePayload}
						/>

						{viewerMode() === 'image360' && <Image360 {...viewerProps} />}
						{viewerMode() === 'video' && <Video {...viewerProps} />}
						{viewerMode() === 'image' && <Image {...viewerProps} />}
					</Container>
				</DialogContent>
			</Dialog>

			<Tools
				onClose={handleClose}
				onSave={handleSave}
				saveLoading={saveLoading}
			/>
		</>
	);
};

export default EditFeature;

const Container = styled(Row)`
	width: 100%;
	height: 100%;
	alignitems: flex-start;
	justifycontent: flex-start;
`;
