"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findConflictingAutomation = exports.canEditFeature = exports.inspectionIsInProgress = exports.canReOpen = exports.canShowViewModeToggle = exports.canMakeUnitEdits = exports.canPerformFeatureActions = exports.canShowFlaggedItems = exports.canShowSyncStatus = exports.canManualSync = exports.canCopyInspectionDeeplink = exports.canDownloadReport = exports.canShare = exports.canEditDueDate = exports.canShowHyperlinkToPropertyPage = exports.canShowReviewDueDate = exports.canShowRevisionDueDate = exports.canShowInviteDate = exports.canEditAssignee = exports.canShowAssignee = exports.canShowDueDate = exports.canShowCompletedBy = exports.canShowCompletedDate = exports.canCreateMaintenanceReport = exports.canRequestSignatures = exports.canDownloadMaintenanceFlags = exports.canDownloadImages = exports.canMarkAsComplete = exports.canSendReminder = exports.canDelete = exports.canUnarchive = exports.canArchive = exports.canCancelRevisionRequest = exports.canCancelReviewRequest = exports.canEditLabel = exports.canSetRevisionNotes = exports.canReject = exports.canUndoFirstStepReview = exports.canCompleteFirstStepReview = exports.canApprove = exports.canReview = exports.shouldShowVideoOption = exports.shouldShow360Option = exports.shouldShowLibraryOption = void 0;
const utils_1 = require("../../utils");
const inspections_1 = require("../../utils/inspections");
const shouldShowLibraryOption = (inspection, profile) => {
    if (!inspection)
        return false;
    return utils_1.Users.canPerformInspection(inspection) && !utils_1.Users.isRenter(profile);
};
exports.shouldShowLibraryOption = shouldShowLibraryOption;
const shouldShow360Option = (inspection) => {
    if (!inspection)
        return false;
    return inspection.settings.can_take_360_photos
        ? !!inspection.fast_track
        : false;
};
exports.shouldShow360Option = shouldShow360Option;
const shouldShowVideoOption = (inspection, profile, feature) => {
    if (feature === null || feature === void 0 ? void 0 : feature.is_video_enabled)
        return true;
    if (!inspection)
        return false;
    return inspection.settings.can_record_video && !utils_1.Users.isRenter(profile)
        ? !!inspection.fast_track
        : false;
};
exports.shouldShowVideoOption = shouldShowVideoOption;
const canReview = (inspection) => {
    const validStatuses = [
        'Awaiting Review',
        'Revision Review',
    ];
    if (!validStatuses.includes(inspection.inspection_status))
        return false;
    if (inspection.role !== 'creator')
        return false;
    return true;
};
exports.canReview = canReview;
const canApprove = (inspection) => {
    if (!(0, exports.canReview)(inspection))
        return false;
    /**
     * If the inspection requires 2 step approval and hasn't gone through
     * the first step then it can't be approved
     */
    if (inspection.settings.is_two_step_approval_required &&
        !inspection.is_initial_review_complete) {
        return false;
    }
    return true;
};
exports.canApprove = canApprove;
const canCompleteFirstStepReview = (inspection) => {
    if (!(0, exports.canReview)(inspection))
        return false;
    if (!inspection.settings.is_two_step_approval_required)
        return false;
    if (inspection.is_initial_review_complete)
        return false;
    return true;
};
exports.canCompleteFirstStepReview = canCompleteFirstStepReview;
const canUndoFirstStepReview = (inspection) => {
    if (!(0, exports.canReview)(inspection))
        return false;
    if (!inspection.settings.is_two_step_approval_required)
        return false;
    if (!inspection.is_initial_review_complete)
        return false;
    return true;
};
exports.canUndoFirstStepReview = canUndoFirstStepReview;
const canReject = (inspection) => {
    if (!(0, exports.canReview)(inspection))
        return false;
    if (inspection.review)
        return false;
    return true;
};
exports.canReject = canReject;
const canSetRevisionNotes = (inspection, feature) => {
    if (!(0, exports.canReview)(inspection))
        return false;
    if (!feature.revision_requested)
        return false;
    return true;
};
exports.canSetRevisionNotes = canSetRevisionNotes;
const canEditLabel = (inspection) => {
    if ((0, inspections_1.userIsInvitedTeammate)(inspection))
        return true;
    const validRoles = ['creator', 'self-perform'];
    if (!validRoles.includes(inspection.role))
        return false;
    return true;
};
exports.canEditLabel = canEditLabel;
const canCancelReviewRequest = (inspection) => {
    const validRoles = ['creator'];
    if (!validRoles.includes(inspection.role))
        return false;
    if (inspection.inspection_status !== 'In Review')
        return false;
    if (inspection.assigned_recipients.type !== 'emails')
        return false;
    return true;
};
exports.canCancelReviewRequest = canCancelReviewRequest;
const canCancelRevisionRequest = (inspection) => {
    const validRoles = ['creator'];
    if (!validRoles.includes(inspection.role))
        return false;
    if (inspection.inspection_status !== 'Revision Requested')
        return false;
    return true;
};
exports.canCancelRevisionRequest = canCancelRevisionRequest;
const canArchive = (inspection) => {
    if ((0, inspections_1.userIsInvitedTeammate)(inspection))
        return true;
    const validRoles = ['creator', 'self-perform'];
    if (!validRoles.includes(inspection.role))
        return false;
    if (inspection.archived)
        return false;
    return true;
};
exports.canArchive = canArchive;
const canUnarchive = (inspection) => {
    if ((0, inspections_1.userIsInvitedTeammate)(inspection))
        return true;
    const validRoles = ['creator', 'self-perform'];
    if (!validRoles.includes(inspection.role))
        return false;
    if (!inspection.archived)
        return false;
    return true;
};
exports.canUnarchive = canUnarchive;
const canDelete = (inspection) => {
    const validRoles = ['creator', 'self-perform'];
    const validStatuses = [
        'Scheduled',
        'Not Started',
        'Started',
    ];
    if (!validStatuses.includes(inspection.inspection_status))
        return false;
    if ((0, inspections_1.userIsInvitedTeammate)(inspection))
        return true;
    if (!validRoles.includes(inspection.role))
        return false;
    return true;
};
exports.canDelete = canDelete;
const canSendReminder = (inspection) => {
    const validRoles = ['creator'];
    const validStatuses = [
        'Not Started',
        'Started',
        'Revision Requested',
    ];
    if (!validRoles.includes(inspection.role))
        return false;
    if (!validStatuses.includes(inspection.inspection_status))
        return false;
    return true;
};
exports.canSendReminder = canSendReminder;
const canMarkAsComplete = (inspection) => {
    const validRoles = ['creator', 'self-perform'];
    const validStatuses = [
        'Started',
        'Revision Requested',
    ];
    if (!validRoles.includes(inspection.role))
        return false;
    if (!validStatuses.includes(inspection.inspection_status))
        return false;
    return true;
};
exports.canMarkAsComplete = canMarkAsComplete;
const canDownloadImages = (inspection, features) => {
    const validStatuses = [
        'Revision Requested',
        'In Review',
        'Completed',
        'Awaiting Review',
        'Revision Review',
        'Approved',
    ];
    if (!validStatuses.includes(inspection.inspection_status))
        return false;
    if (!features.some((f) => f.images.length > 0 || f.videos.length > 0))
        return false;
    return true;
};
exports.canDownloadImages = canDownloadImages;
const canDownloadMaintenanceFlags = (inspection, features) => {
    const validStatuses = [
        'In Review',
        'Completed',
        'Awaiting Review',
        'Revision Review',
        'Approved',
    ];
    if (!validStatuses.includes(inspection.inspection_status))
        return false;
    if (!features.some((f) => f.maintenance_flags.length > 0))
        return false;
    if ((0, inspections_1.userIsInvitedTeammate)(inspection))
        return true;
    const validRoles = ['creator', 'self-perform'];
    if (!validRoles.includes(inspection.role))
        return false;
    return true;
};
exports.canDownloadMaintenanceFlags = canDownloadMaintenanceFlags;
const canRequestSignatures = (inspection) => {
    const validRoles = ['creator', 'self-perform'];
    const validStatuses = [
        'In Review',
        'Completed',
        'Awaiting Review',
        'Revision Review',
        'Approved',
    ];
    if (!validRoles.includes(inspection.role))
        return false;
    if (!validStatuses.includes(inspection.inspection_status))
        return false;
    return true;
};
exports.canRequestSignatures = canRequestSignatures;
const canCreateMaintenanceReport = (inspection, features) => {
    const validRoles = ['creator', 'self-perform'];
    const validStatuses = [
        'In Review',
        'Completed',
        'Awaiting Review',
        'Revision Review',
        'Approved',
    ];
    if (!validRoles.includes(inspection.role))
        return false;
    if (!validStatuses.includes(inspection.inspection_status))
        return false;
    if (!features.some((f) => f.maintenance_flags.length > 0))
        return false;
    return true;
};
exports.canCreateMaintenanceReport = canCreateMaintenanceReport;
const canShowCompletedDate = (inspection) => {
    const validStatuses = [
        'Awaiting Review',
        'Completed',
        'Revision Requested',
        'Revision Review',
        'In Review',
        'Approved',
    ];
    if (!validStatuses.includes(inspection.inspection_status))
        return false;
    if (!inspection.completed_date)
        return false;
    return true;
};
exports.canShowCompletedDate = canShowCompletedDate;
const canShowCompletedBy = (inspection) => {
    const validStatuses = [
        'Awaiting Review',
        'Completed',
        'Revision Requested',
        'Revision Review',
        'In Review',
        'Approved',
    ];
    if (!validStatuses.includes(inspection.inspection_status))
        return false;
    if (!inspection.completed_by)
        return false;
    return true;
};
exports.canShowCompletedBy = canShowCompletedBy;
const canShowDueDate = (inspection) => {
    const validStatuses = [
        'Scheduled',
        'Not Started',
        'Started',
    ];
    if (!validStatuses.includes(inspection.inspection_status))
        return false;
    return true;
};
exports.canShowDueDate = canShowDueDate;
const canShowAssignee = (inspection) => {
    const validStatuses = [
        'Scheduled',
        'Not Started',
        'Started',
        'Revision Requested',
    ];
    if (!validStatuses.includes(inspection.inspection_status))
        return false;
    return true;
};
exports.canShowAssignee = canShowAssignee;
const canEditAssignee = (inspection) => {
    if (!(0, exports.canShowAssignee)(inspection))
        return false;
    if ((0, inspections_1.userIsInvitedTeammate)(inspection))
        return true;
    const validRoles = ['creator', 'self-perform'];
    if (!validRoles.includes(inspection.role))
        return false;
    return true;
};
exports.canEditAssignee = canEditAssignee;
const canShowInviteDate = (inspection) => {
    const validStatuses = ['Scheduled'];
    const validAssigneeTypes = [
        'emails',
        'residents',
        'teammates',
    ];
    if (!validStatuses.includes(inspection.inspection_status))
        return false;
    if (!validAssigneeTypes.includes(inspection.assigned_recipients.type))
        return false;
    if (!inspection.invite_date)
        return false;
    return true;
};
exports.canShowInviteDate = canShowInviteDate;
const canShowRevisionDueDate = (inspection) => {
    var _a;
    const validStatuses = ['Revision Requested'];
    if (!validStatuses.includes(inspection.inspection_status))
        return false;
    if (!((_a = inspection.rejection) === null || _a === void 0 ? void 0 : _a.due_date))
        return false;
    return true;
};
exports.canShowRevisionDueDate = canShowRevisionDueDate;
const canShowReviewDueDate = (inspection) => {
    var _a;
    const validStatuses = ['In Review'];
    if (!validStatuses.includes(inspection.inspection_status))
        return false;
    if (!((_a = inspection.review) === null || _a === void 0 ? void 0 : _a.due_date))
        return false;
    return true;
};
exports.canShowReviewDueDate = canShowReviewDueDate;
const canShowHyperlinkToPropertyPage = (inspection) => {
    if ((0, inspections_1.userIsInvitedTeammate)(inspection))
        return true;
    const validRoles = ['creator', 'self-perform'];
    if (!validRoles.includes(inspection.role))
        return false;
    return true;
};
exports.canShowHyperlinkToPropertyPage = canShowHyperlinkToPropertyPage;
const canEditDueDate = (inspection) => {
    if (!(0, exports.canShowDueDate)(inspection))
        return false;
    const validRoles = ['creator', 'self-perform'];
    if (!validRoles.includes(inspection.role))
        return false;
    return true;
};
exports.canEditDueDate = canEditDueDate;
const canShare = (inspection) => {
    const validStatuses = [
        'In Review',
        'Completed',
        'Awaiting Review',
        'Revision Review',
        'Approved',
    ];
    if (!validStatuses.includes(inspection.inspection_status))
        return false;
    return true;
};
exports.canShare = canShare;
const canDownloadReport = (inspection) => {
    const validStatuses = [
        'In Review',
        'Completed',
        'Awaiting Review',
        'Revision Review',
        'Approved',
    ];
    if (!validStatuses.includes(inspection.inspection_status))
        return false;
    return true;
};
exports.canDownloadReport = canDownloadReport;
const canCopyInspectionDeeplink = (inspection) => {
    const validStatuses = [
        'Scheduled',
        'Not Started',
        'Started',
        'Revision Requested',
    ];
    if (!validStatuses.includes(inspection.inspection_status))
        return false;
    return true;
};
exports.canCopyInspectionDeeplink = canCopyInspectionDeeplink;
const canManualSync = (inspection, property) => {
    var _a, _b;
    /**
     * If the property is not synced to a valid vendor we don't
     * have anywhere to sync the inspection to.
     */
    const validVendors = [
        'rentmanager',
        'appfolio',
        'rentvine',
    ];
    if (!validVendors.includes((_a = property.sync_data) === null || _a === void 0 ? void 0 : _a.vendor)) {
        return false;
    }
    /**
     * if the inspection has already been synced and we registered
     * no errors, we don't need to show the button. Manual sync is
     * only intended to be shown as an initial sync or a retry.
     */
    if (((_b = inspection.sync_data) === null || _b === void 0 ? void 0 : _b.status) === 'success')
        return false;
    /**
     * Only inspections that are Completed or In Review can be synced.
     */
    const validStatuses = [
        'In Review',
        'Completed',
        'Awaiting Review',
        'Revision Review',
        'Approved',
    ];
    if (!validStatuses.includes(inspection.inspection_status))
        return false;
    if ((0, inspections_1.userIsInvitedTeammate)(inspection))
        return true;
    const validRoles = ['creator', 'self-perform'];
    if (!validRoles.includes(inspection.role))
        return false;
    return true;
};
exports.canManualSync = canManualSync;
const canShowSyncStatus = (inspection) => {
    if (!inspection.sync_data)
        return false;
    const validStatuses = [
        'In Review',
        'Completed',
        'Awaiting Review',
        'Revision Review',
        'Approved',
    ];
    if (!validStatuses.includes(inspection.inspection_status))
        return false;
    if ((0, inspections_1.userIsInvitedTeammate)(inspection))
        return true;
    const validRoles = ['creator', 'self-perform'];
    if (!validRoles.includes(inspection.role))
        return false;
    return true;
};
exports.canShowSyncStatus = canShowSyncStatus;
const canShowFlaggedItems = (inspection, flags) => {
    if (flags.length === 0)
        return false;
    if ((0, inspections_1.userIsInvitedTeammate)(inspection))
        return true;
    const validRoles = ['creator', 'self-perform'];
    if (!validRoles.includes(inspection.role))
        return false;
    return true;
};
exports.canShowFlaggedItems = canShowFlaggedItems;
const canPerformFeatureActions = (inspection) => {
    if ((0, inspections_1.userIsInvitedTeammate)(inspection))
        return true;
    const validRoles = ['creator', 'self-perform'];
    if (!validRoles.includes(inspection.role))
        return false;
    const validStatuses = [
        'In Review',
        'Completed',
        'Awaiting Review',
        'Revision Review',
        'Approved',
    ];
    if (!validStatuses.includes(inspection.inspection_status))
        return false;
    return true;
};
exports.canPerformFeatureActions = canPerformFeatureActions;
const canMakeUnitEdits = (inspection) => {
    const validStatuses = [
        'In Review',
        'Completed',
        'Awaiting Review',
        'Revision Review',
        'Approved',
    ];
    if (!validStatuses.includes(inspection.inspection_status))
        return false;
    if ((0, inspections_1.userIsInvitedTeammate)(inspection))
        return true;
    const validRoles = ['creator', 'self-perform'];
    if (!validRoles.includes(inspection.role))
        return false;
    return true;
};
exports.canMakeUnitEdits = canMakeUnitEdits;
const canShowViewModeToggle = (inspection) => {
    const validStatuses = [
        'Revision Requested',
        'In Review',
        'Completed',
        'Awaiting Review',
        'Revision Review',
        'Approved',
    ];
    if (!validStatuses.includes(inspection.inspection_status))
        return false;
    return true;
};
exports.canShowViewModeToggle = canShowViewModeToggle;
const canReOpen = (inspection) => {
    const selfPerformValidRoles = ['self-perform'];
    const selfPerformValidStatuses = ['Completed'];
    if (selfPerformValidRoles.includes(inspection.role) &&
        selfPerformValidStatuses.includes(inspection.inspection_status)) {
        return true;
    }
    const inviteeValidRoles = ['invitee-write'];
    const inviteeValidStatuses = [
        'Awaiting Review',
        'Revision Review',
    ];
    /**
     * Any invitee can edit the inspection if it's in the correct status
     * and the assignee type is teammates
     */
    if ((0, inspections_1.userIsInvitedTeammate)(inspection) &&
        inviteeValidStatuses.includes(inspection.inspection_status)) {
        return true;
    }
    /**
     * Only invitees with write access can edit the inspection if it's in
     * the correct status and the assignee type is not teammates (emails/residents)
     */
    if (inviteeValidRoles.includes(inspection.role) &&
        inviteeValidStatuses.includes(inspection.inspection_status)) {
        return true;
    }
    return false;
};
exports.canReOpen = canReOpen;
const inspectionIsInProgress = (inspection) => {
    return ![
        'Approved',
        'Awaiting Review',
        'Revision Review',
        'Completed',
        'Scheduled',
    ].includes(inspection.inspection_status);
};
exports.inspectionIsInProgress = inspectionIsInProgress;
const canEditFeature = (inspection, feature) => {
    if ((0, exports.inspectionIsInProgress)(inspection))
        return false;
    if (feature.not_applicable)
        return false;
    /**
     * If the feature has an asset capture question and the inspection
     * is in review, we want to allow editing for the inspection creator
     */
    if (feature.questions.some((q) => q.type === 'asset capture')) {
        const validStatuses = [
            'Awaiting Review',
            'Revision Review',
        ];
        const validRoles = ['creator'];
        if (validStatuses.includes(inspection.inspection_status) &&
            validRoles.includes(inspection.role))
            return true;
    }
    /**
     * If we didn't match the previous condition we want to allow editing
     * only if the inspection is internal
     */
    const validAssigneeTypes = [
        'self-perform',
        'myself',
        'teammates',
    ];
    if (!validAssigneeTypes.includes(inspection.assigned_recipients.type))
        return false;
    return true;
};
exports.canEditFeature = canEditFeature;
const findConflictingAutomation = (template, automations, units) => {
    return automations.find((a) => {
        if (!a.active)
            return undefined;
        if (a.inspection_template.id !== template.id)
            return undefined;
        if (a.selection_type.type === 'all_units')
            return true;
        if (a.selection_type.type === 'specific_teams') {
            return units.some((u) => { var _a; return a.selection_type.ids.includes((_a = u.organization_id) !== null && _a !== void 0 ? _a : ''); });
        }
        if (a.selection_type.type === 'specific_units') {
            return units.some((u) => a.selection_type.ids.includes(u.id));
        }
        return undefined;
    });
};
exports.findConflictingAutomation = findConflictingAutomation;
