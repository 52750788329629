import { FilterMenuItem } from 'components/table/filter-button-v2';
import { useAccountSettings } from 'hooks/account-settings';
import { useDispatch } from 'react-redux';
import { actionType, icon, useFilter } from './common';

export default () => {
	const dispatch = useDispatch();
	const filter = useFilter();

	const { accountSettings } = useAccountSettings();

	const handleClicked = () => {
		dispatch({
			type: actionType,
			value: [],
		});
	};

	/**
	 * This filter is shown based on `two_step_inspection_approval` in
	 * the user's account settings. We hide the filter from the menu
	 * and not from the chips list because we want to allow users that had
	 * this filter on before the setting was turned off to be able to remove
	 * it.
	 */
	if (!accountSettings?.two_step_inspection_approval.enabled) {
		return null;
	}

	const disabled = !!filter;
	const tooltip = disabled ? 'This filter is already in use' : undefined;

	return (
		<FilterMenuItem
			title="Review Step"
			icon={icon}
			onClick={handleClicked}
			{...{ disabled, tooltip }}
		/>
	);
};
