import { Row } from 'components';

import { Checkbox, Typography } from '@mui/material';
import { ApiBaseIntegration } from '@rentcheck/types';
import { colors } from 'theme';
import { MappingSection as MappingSectionType } from '../../types';

interface Props {
	mappingSection: MappingSectionType;
	variantFields: ApiBaseIntegration['variant_fields'];
	setVariantFields: (fields: ApiBaseIntegration['variant_fields']) => void;
}

const Variants = ({
	mappingSection,
	variantFields,
	setVariantFields,
}: Props) => {
	const handleVariantFieldChanged = (key: string) => {
		setVariantFields({
			...variantFields,
			[key]: !variantFields[key],
		});
	};

	const allVariants = [
		...(mappingSection.variants ?? []),
		...mappingSection.mappings.map((m) => m.variant),
	];

	return (
		<>
			{allVariants.map((variant, idx) => {
				if (!variant) {
					return null;
				}

				const value = variantFields[variant.key];

				return (
					<Row key={idx}>
						<Checkbox
							name={variant.key}
							checked={value}
							onChange={() => handleVariantFieldChanged(variant.key)}
						/>
						<Typography variant="body2" color={colors.secondary}>
							{variant.title}
						</Typography>
					</Row>
				);
			})}
		</>
	);
};

export default Variants;
