import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Tooltip,
} from '@mui/material';
import { useDispatch } from 'react-redux';

import { DialogTitle, SwitchRow } from 'components';
import {
	ModalFlowActions,
	SnackbarActions,
	TeammatesActions,
} from 'store/actions';
import { Dispatch } from 'types';

import { LoadingButton } from '@mui/lab';
import {
	ApiPermissionGroup,
	ApiPermissionGroupDigest,
	CreatePermissionGroupV2Params,
	UpdatePermissionGroupV2Params,
	UserInfo,
} from '@rentcheck/types';
import { useCreatePermissionGroupModalData } from 'hooks/modals';
import usePermissionGroups from 'hooks/permission-groups';
import _ from 'lodash';
import { useEffect, useState } from 'react';

import { PermissionGroupsApi } from '@rentcheck/api-frontend';
import InspectionTemplates from './inspection-templates';
import Name from './name';
import Skeleton from './skeleton';
import Users from './users';

interface Props {
	open: boolean;
}

const getButtonProps = (
	name: string,
	permissionGroups: ApiPermissionGroupDigest[],
	permissionGroup?: ApiPermissionGroup
) => {
	const nameIsDuplicate = permissionGroups
		.filter((pg) => pg.id !== permissionGroup?.id)
		.some((pg) => pg.name.trim().toLowerCase() === name.trim().toLowerCase());

	const buttonDisabled = name.length === 0 || nameIsDuplicate;
	const buttonTooltip =
		name.length === 0
			? 'Add a name'
			: nameIsDuplicate
				? 'Name is already taken'
				: '';

	return { buttonDisabled, buttonTooltip, nameIsDuplicate };
};

export default ({ open }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const modalData = useCreatePermissionGroupModalData();
	const permissionGroupDigest = modalData?.metadata.permissionGroup;

	const { permissionGroups } = usePermissionGroups(100);

	const [permissionGroup, setPermissionGroup] = useState<ApiPermissionGroup>();

	const [name, setName] = useState('');
	const [users, setUsers] = useState<UserInfo[]>([]);
	const [allowBillingPlanEditing, setAllowBillingPlanEditing] =
		useState<boolean>(false);
	const [allowAutomationEditing, setAllowAutomationEditing] =
		useState<boolean>(false);
	const [allowNotificationsEditing, setAllowNotificationsEditing] =
		useState<boolean>(false);
	const [inspectionTemplateIds, setInspectionTemplateIds] = useState<string[]>(
		[]
	);

	const [loading, setLoading] = useState(false);
	const [loadingPermissionGroupInfo, setLoadingPermissionGroupInfo] =
		useState(false);

	useEffect(() => {
		if (open) {
			return;
		}

		setName('');
		setUsers([]);
		setInspectionTemplateIds([]);
		setLoading(false);
		setAllowBillingPlanEditing(false);
		setAllowAutomationEditing(false);
		setAllowNotificationsEditing(false);
		setPermissionGroup(undefined);
	}, [open]);

	useEffect(() => {
		if (!permissionGroupDigest) {
			setLoadingPermissionGroupInfo(false);
			return;
		}

		setLoadingPermissionGroupInfo(true);
		PermissionGroupsApi.get(permissionGroupDigest.id)
			.then((res) => setPermissionGroup(res))
			.catch((e) => dispatch(SnackbarActions.showError(e)))
			.finally(() => setLoadingPermissionGroupInfo(false));
	}, [permissionGroupDigest]);

	useEffect(() => {
		if (!permissionGroup) {
			return;
		}

		setName(permissionGroup.name);
		setUsers(permissionGroup.users);
		setAllowBillingPlanEditing(!!permissionGroup.allow_billing_plan_editing);
		setAllowAutomationEditing(!!permissionGroup.allow_automation_editing);
		setAllowNotificationsEditing(
			!!permissionGroup.allow_notifications_preferences_editing
		);
		setInspectionTemplateIds(_.map(permissionGroup.inspection_templates, 'id'));
	}, [permissionGroup]);

	const handleClose = () => {
		dispatch(ModalFlowActions.closeCurrentModal());
	};

	const handleSave = () => {
		setLoading(true);

		const data:
			| UpdatePermissionGroupV2Params['data']
			| CreatePermissionGroupV2Params['data'] = {
			name,
			user_ids: _.map(users, 'id'),
			inspection_template_ids: inspectionTemplateIds,
			allow_billing_plan_editing: allowBillingPlanEditing,
			allow_automation_editing: allowAutomationEditing,
			allow_notifications_preferences_editing: allowNotificationsEditing,
		};

		dispatch(
			permissionGroup
				? TeammatesActions.PermissionGroups.update(permissionGroup.id, data)
				: TeammatesActions.PermissionGroups.create(data)
		)
			.then(() => {
				handleClose();
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const { buttonDisabled, buttonTooltip, nameIsDuplicate } = getButtonProps(
		name,
		permissionGroups,
		permissionGroup
	);

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle
				title={
					permissionGroupDigest
						? 'Edit Permission Group'
						: 'Create Permission Group'
				}
				icon={solid('users')}
				onClose={handleClose}
			/>
			{loadingPermissionGroupInfo && <Skeleton />}
			{!loadingPermissionGroupInfo && (
				<>
					<DialogContent>
						<Name
							value={name}
							setValue={setName}
							error={nameIsDuplicate ? 'Name is already taken' : undefined}
						/>
						<Users value={users} setValue={setUsers} />
						{open && (
							<InspectionTemplates
								value={inspectionTemplateIds}
								setValue={setInspectionTemplateIds}
								mode={permissionGroup ? 'edit' : 'create'}
							/>
						)}

						<SwitchRow
							title="Edit Subscription Plan"
							caption="Toggle ON to allow this group to make billing plan adjustments."
							value={allowBillingPlanEditing}
							setValue={setAllowBillingPlanEditing}
						/>

						<SwitchRow
							title="Create and edit inspection automations"
							caption="Toggle ON to allow this group of users to edit automation settings."
							value={allowAutomationEditing}
							setValue={setAllowAutomationEditing}
						/>

						<SwitchRow
							title="Edit Notification Preferences"
							caption="Toggle ON to allow this group to adjust subscription level notification settings."
							value={allowNotificationsEditing}
							setValue={setAllowNotificationsEditing}
						/>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} variant="text" color="secondary">
							Cancel
						</Button>
						<Tooltip title={buttonTooltip}>
							<span>
								<LoadingButton
									loading={loading}
									disabled={buttonDisabled}
									onClick={handleSave}>
									{permissionGroupDigest ? 'Save' : 'Create'}
								</LoadingButton>
							</span>
						</Tooltip>
					</DialogActions>
				</>
			)}
		</Dialog>
	);
};
