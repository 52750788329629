import { InspectionTemplatesApi } from '@rentcheck/api-frontend';
import {
	ApiInspectionTemplateDigest,
	EditInspectionTemplateAutomationsParams,
	EditInspectionTemplatePermissionGroupsParams,
	FirebaseUpdatePayload,
	InspectionTemplate,
	UpdateInspectionTemplateResult,
} from '@rentcheck/types';
import { InspectionTemplatesApi as InspectionTemplatesApiFB } from 'api';
import { Dispatch, GetFirebase, GetState } from 'types';

type ReduxFn = (a: Dispatch, b: GetState, c: GetFirebase) => any;

export const create = (
	payload: Pick<
		InspectionTemplate,
		| 'name'
		| 'internal_label'
		| 'description'
		| 'team_ids'
		| 'property_type'
		| 'is_fast_track_enabled'
		| 'template_type'
		| 'sections'
		| 'contact_info'
	> & { permission_group_ids: string[] }
) => {
	const fn: ReduxFn = async (dispatch, getState) => {
		const { subscription } = getState();

		if (!subscription) {
			throw new Error('Subscription not found');
		}

		const fbData = await InspectionTemplatesApiFB.create({
			...payload,
			is_rc_template: false,
			subscription_id: subscription.id,
			is_published: false,
			unpublished_sub_ids: [],
		});

		if (!fbData) {
			throw new Error('Failed to create template');
		}

		await InspectionTemplatesApi.editPermissionGroups(fbData.id, {
			permission_group_ids: payload.permission_group_ids,
		});

		const data = await InspectionTemplatesApi.get(fbData.id);

		dispatch({ type: 'ADD_INSPECTION_TEMPLATE', data });

		return data;
	};

	return fn;
};

export const getAll = (
	includeUnavailable?: boolean,
	includeDeleted?: boolean,
	activeTemplatesOnly?: boolean
) => {
	const fn: ReduxFn = async (dispatch) => {
		await InspectionTemplatesApi.getAll(
			0,
			100,
			{
				include_unavailable: includeUnavailable,
				include_deleted: includeDeleted,
				active_only: activeTemplatesOnly,
			},
			undefined
		).then((data) =>
			dispatch({ type: 'SET_INSPECTION_TEMPLATES', data: data.data })
		);
	};

	return fn;
};

export const updateSections = (
	id: string,
	payload: FirebaseUpdatePayload<Pick<InspectionTemplate, 'sections'>>
) => {
	const fn: ReduxFn = async (dispatch) => {
		await InspectionTemplatesApiFB.update(id, payload);
		const data = await InspectionTemplatesApi.get(id);

		dispatch({ type: 'EDITED_INSPECTION_TEMPLATE_SECTIONS', data });
	};

	return fn;
};

export const setTemplatePublished = (
	template: ApiInspectionTemplateDigest,
	published: boolean
) => {
	const fn: ReduxFn = async (
		dispatch
	): Promise<UpdateInspectionTemplateResult['data']> => {
		const result = await InspectionTemplatesApi.update(template.id, {
			is_published: published,
		});

		dispatch({
			type: 'EDITED_INSPECTION_TEMPLATE_DETAILS',
			data: result.template,
		});

		return result;
	};

	return fn;
};

export const updateDetails = (
	id: string,
	payload: FirebaseUpdatePayload<
		Omit<InspectionTemplate, 'sections' | 'is_published'>
	>
) => {
	const fn: ReduxFn = async (dispatch) => {
		/**
		 * We update the template in firebase first, then once that succeeds
		 * we fetch the template from the API and update the state
		 */
		await InspectionTemplatesApiFB.update(id, payload);
		const data = await InspectionTemplatesApi.get(id);

		dispatch({ type: 'EDITED_INSPECTION_TEMPLATE_DETAILS', data });
	};

	return fn;
};

export const get = (id: string) => {
	const fn: ReduxFn = async (dispatch) => {
		await InspectionTemplatesApi.get(id).then((data) =>
			dispatch({ type: 'SET_INSPECTION_TEMPLATE', data })
		);
	};

	return fn;
};

export const deleteTemplate = (template: ApiInspectionTemplateDigest) => {
	const fn: ReduxFn = async (dispatch) => {
		const result = await InspectionTemplatesApi.softDeleteTemplate(template.id);

		if (result.status === 'success') {
			dispatch({ type: 'REMOVE_INSPECTION_TEMPLATE', data: template });
		}

		return result;
	};

	return fn;
};

export const unsetTemplate = () => {
	const fn: ReduxFn = (dispatch) => {
		dispatch({ type: 'UNSET_INSPECTION_TEMPLATE' });
	};

	return fn;
};

export const editPermissionGroups = (
	templateId: string,
	payload: EditInspectionTemplatePermissionGroupsParams['data']
) => {
	const fn: ReduxFn = async (dispatch) => {
		const data = await InspectionTemplatesApi.editPermissionGroups(
			templateId,
			payload
		);

		dispatch({ type: 'SET_INSPECTION_TEMPLATE', data });
	};

	return fn;
};

export const editAutomations = (
	templateId: string,
	payload: EditInspectionTemplateAutomationsParams['data']
) => {
	const fn: ReduxFn = async (dispatch) => {
		const data = await InspectionTemplatesApi.editAutomations(
			templateId,
			payload
		);

		dispatch({ type: 'SET_INSPECTION_TEMPLATE', data });
	};

	return fn;
};
