import { LoadingButton } from '@mui/lab';
import { Card, CardContent, Typography } from '@mui/material';
import { AdminToolsApi } from '@rentcheck/api-frontend';
import { ApiInspection } from '@rentcheck/types';
import { Column, Row, Spacer } from 'components';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarActions } from 'store/actions';
import { Dispatch } from 'types';

interface Props {
	inspection: ApiInspection;
	refreshInspection: () => void;
}

const RestoreInspection = ({ inspection, refreshInspection }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const [loading, setLoading] = useState(false);

	const canRestore = inspection?.team && inspection.created_by;

	const handleRestore = () => {
		if (!inspection) {
			return;
		}

		if (!canRestore) {
			return;
		}

		setLoading(true);

		AdminToolsApi.restoreInspection(inspection.id)
			.then(() => {
				refreshInspection();
				dispatch(SnackbarActions.showSuccess('Inspection Restored'));
			})
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	};

	const cantRestoreReason = () => {
		if (!inspection.team) {
			return "Inspection doesn't have a team";
		}

		if (!inspection.created_by) {
			return "Inspection doesn't have a created_by";
		}

		return '';
	};

	return (
		<Card>
			<CardContent>
				<Typography variant="h6">Restore Inspection</Typography>
				<Spacer height={2} />
				<Row>
					<Column>
						<Typography>
							In order to be restored the target inspection needs to have a
							valid <Typography variant="code">organization_id</Typography> and{' '}
							<Typography variant="code">landlordID</Typography>, additionally
							in order to be considered a valid restorable inspection the{' '}
							<Typography variant="code">authorized_user_ids</Typography> array
							can't include the{' '}
							<Typography variant="code">organization_id</Typography>:
						</Typography>

						{!canRestore && (
							<>
								<Spacer height={2} />
								<Typography variant="error">{cantRestoreReason()}</Typography>
							</>
						)}
					</Column>

					<Spacer width={4} />

					<LoadingButton
						loading={loading}
						onClick={handleRestore}
						disabled={!canRestore}
						sx={{ minWidth: 90, maxWidth: 90 }}>
						Restore Inspection
					</LoadingButton>
				</Row>
			</CardContent>
		</Card>
	);
};

export default RestoreInspection;
