import { InspectionsApi } from '@rentcheck/api-frontend';
import * as Sentry from '@sentry/react';
import { ReduxFn } from 'types';

import { ApiInspectionWithTemplate, ListOptions } from '@rentcheck/types';
import { State as FilterState } from '../../reducers/inspections/filters';
import * as ModalFlowActions from '../modal-flows';
import * as SnackbarActions from '../snackbar-actions';
import { buildInspectionFilters } from './utils';

export const pageSize = 20;

export const getAll = (
	offset: number = 0,
	filterState: FilterState
): ReduxFn => {
	const fn: ReduxFn = async (dispatch, getState) => {
		const activeProfile = getState().activeProfile;

		if (!activeProfile) return;

		const pageNumber = Math.floor(offset / pageSize);

		const filters = buildInspectionFilters(filterState);

		const pagination: ListOptions['pagination'] = {
			page_number: pageNumber,
			page_size: pageSize,
		};

		const { data, totalResults } = await InspectionsApi.getAll(
			false,
			pagination,
			filters,
			filterState.sort
		);

		const dispatchType =
			offset === 0 ? 'FETCHED_INITIAL_INSPECTIONS' : 'FETCHED_INSPECTIONS_PAGE';

		dispatch({ type: dispatchType, inspections: data });
		dispatch({ type: 'SET_TOTAL_INSPECTIONS', value: totalResults });

		return data;
	};

	return fn;
};

export const getById = (id: string): ReduxFn<ApiInspectionWithTemplate> => {
	const fn: ReduxFn = async (dispatch) => {
		const inspection = await InspectionsApi.getById(id);
		dispatch({ type: 'FETCHED_INSPECTION_WITH_TEMPLATE', inspection });

		return inspection;
	};

	return fn;
};

export const refreshInspection = (): ReduxFn => {
	const fn: ReduxFn = async (dispatch, getState) => {
		const currentInspection = getState().inspections.inspection;

		if (!currentInspection) return;

		const inspection = await InspectionsApi.getById(currentInspection.id);

		dispatch({ type: 'FETCHED_INSPECTION_WITH_TEMPLATE', inspection });

		return inspection;
	};

	return fn;
};

export const archive = (id: string): ReduxFn => {
	const fn: ReduxFn = async (dispatch) => {
		await InspectionsApi.update(id, { archived: true });
		dispatch({ type: 'DELETE_INSPECTION', inspection: { id } as any });
	};

	return fn;
};

export const deleteInspections = (ids: string[]): ReduxFn => {
	const fn: ReduxFn = async (dispatch) => {
		try {
			dispatch(ModalFlowActions.closeConfirmationModal());

			const progressSnackbar = await dispatch(
				SnackbarActions.showProgress(
					`Deleting ${ids.length} ${
						ids.length === 1 ? 'inspection' : 'inspections'
					}`
				)
			);

			await InspectionsApi.deleteInspections(ids);
			dispatch({ type: 'DELETE_INSPECTIONS', inspections: ids as any });

			dispatch(SnackbarActions.hideSnackbar(progressSnackbar.id));

			dispatch(
				SnackbarActions.showSuccess(
					`Deleted ${ids.length} ${
						ids.length === 1 ? 'inspection' : 'inspections'
					} successfully!`
				)
			);
		} catch (e) {
			Sentry.captureException(e);
			dispatch(SnackbarActions.showError((e as Error).message));

			throw e;
		}
	};

	return fn;
};
