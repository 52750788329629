import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Typography } from '@mui/material';

import { Dispatch } from 'types';
import { Analytics } from 'utils';
import { Screens, SpacedRow } from 'components';
import { colors } from 'theme';
import { AccountSettingsActions, SubscriptionActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';

import Skeleton from './skeleton';
import PaperRow from '../common/paper-row';

export default () => {
	const dispatch = useDispatch<Dispatch>();

	const profile = useTypedSelector((state) => state.activeProfile);
	const subscription = useTypedSelector((state) => state.subscription);
	const accountSettings = useTypedSelector((state) => state.accountSettings);

	const [loading, setLoading] = useState(!subscription);

	useEffect(() => {
		if (!profile) {
			return;
		}

		if (subscription) {
			setLoading(false);
			return;
		}

		setLoading(true);
		dispatch(SubscriptionActions.getSubscription()).finally(() =>
			setLoading(false)
		);
	}, [profile]);

	if (loading) {
		return <Skeleton />;
	}

	const handleInspectionReviewAutoapprovalToggle = () => {
		if (!accountSettings) return;

		dispatch(
			AccountSettingsActions.setInspectionReviewAutoapprovalEnabled(
				!accountSettings.is_inspection_review_autoapproval_enabled
			)
		);

		if (!accountSettings.is_inspection_review_autoapproval_enabled) {
			Analytics.trackEvent('auto_approve_inspections_enabled', {
				subscription_id: subscription?.id ?? '',
				chargebee_subscription_id: subscription?.plan?.sub_id ?? '',
			});
		}
	};

	return (
		<Screens.Container>
			<Screens.Header
				title="Inspection Workflow Settings"
				showBreadCrumbs
				subtitle="Tailor how inspections are reviewed and approved in your organization."
			/>

			<PaperRow
				title="Auto-approve inspections shared for review and signature"
				selected={
					accountSettings?.is_inspection_review_autoapproval_enabled ?? false
				}
				handleToggleSection={handleInspectionReviewAutoapprovalToggle}>
				<SpacedRow>
					<Typography color={colors.secondary}>
						When enabled, inspections shared for review and signature
						('In-Review' status) will be automatically approved once the
						recipient signs off, provided no edits, notes, or changes were made
						to the original inspection.
					</Typography>
					<a
						href="https://help.getrentcheck.com/en/articles/10768977-inspection-workflow-settings"
						style={{ marginLeft: 8, alignSelf: 'flex-end' }}>
						<Typography noWrap sx={{ textDecoration: 'underline' }}>
							Learn More
						</Typography>
					</a>
				</SpacedRow>
			</PaperRow>
		</Screens.Container>
	);
};
