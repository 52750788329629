"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InspectionTemplateHasNoFeatures = exports.InspectionTemplateNotAllowed = exports.InspectionTemplateNotPublished = void 0;
const http_1 = require("./http");
class InspectionTemplateNotPublished extends http_1.Forbidden {
    constructor() {
        super('Inspection template is not published');
    }
}
exports.InspectionTemplateNotPublished = InspectionTemplateNotPublished;
class InspectionTemplateNotAllowed extends http_1.Forbidden {
    constructor() {
        super(`Unit does not have access to selected inspection template`);
    }
}
exports.InspectionTemplateNotAllowed = InspectionTemplateNotAllowed;
class InspectionTemplateHasNoFeatures extends http_1.BadRequest {
    constructor() {
        super('inspection template has no features');
    }
}
exports.InspectionTemplateHasNoFeatures = InspectionTemplateHasNoFeatures;
