"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvalidLeaseDates = exports.InvalidMoveDates = exports.OperationNotSupportedForModel = exports.InvalidImportModel = void 0;
const http_1 = require("./http");
class InvalidImportModel extends http_1.BadRequest {
    constructor(model) {
        super(`invalid import model ${model}`);
    }
}
exports.InvalidImportModel = InvalidImportModel;
class OperationNotSupportedForModel extends http_1.BadRequest {
    constructor(operation, model) {
        super(`operation ${operation} is not supported for model ${model}`);
    }
}
exports.OperationNotSupportedForModel = OperationNotSupportedForModel;
class InvalidMoveDates extends http_1.BadRequest {
    constructor(startDate, endDate, fullName) {
        super(`Invalid move dates for ${fullName}: move-in (${startDate}) after move-out (${endDate})`);
    }
}
exports.InvalidMoveDates = InvalidMoveDates;
class InvalidLeaseDates extends http_1.BadRequest {
    constructor(startDate, endDate, fullName) {
        super(`Invalid lease dates for ${fullName}: lease start (${startDate}) after lease end (${endDate})`);
    }
}
exports.InvalidLeaseDates = InvalidLeaseDates;
