"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InspectionTemplateNoFastTrack = exports.InspectionTemplateDeleted = exports.InspectionTemplateNotPublished = exports.NoTeammateForTeam = void 0;
const http_1 = require("./http");
class NoTeammateForTeam extends http_1.BadRequest {
    constructor() {
        super('a teammate must be provided for all teams');
    }
}
exports.NoTeammateForTeam = NoTeammateForTeam;
class InspectionTemplateNotPublished extends http_1.BadRequest {
    constructor() {
        super('inspection template not published');
    }
}
exports.InspectionTemplateNotPublished = InspectionTemplateNotPublished;
class InspectionTemplateDeleted extends http_1.NotFound {
    constructor() {
        super('Selected inspection template has been deleted');
    }
}
exports.InspectionTemplateDeleted = InspectionTemplateDeleted;
class InspectionTemplateNoFastTrack extends http_1.BadRequest {
    constructor() {
        super('fast track not enabled for this inspection template');
    }
}
exports.InspectionTemplateNoFastTrack = InspectionTemplateNoFastTrack;
