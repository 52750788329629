import { InspectionFiltersConfig } from '@rentcheck/types';

import { buildDRFilter, buildMVFilter, buildSMVFilter } from 'utils/filters';
import { State as FilterState } from '../../reducers/inspections/filters';

/**
 * Builds an InspectionFiltersConfig object from a FilterState object
 */
export const buildInspectionFilters = (
	filterState: FilterState
): InspectionFiltersConfig => {
	const filters: InspectionFiltersConfig = {
		search: filterState.search,
		archived: filterState.archived,
		inspection_status: filterState.inspectionStatus,
		assigned_to: buildSMVFilter(filterState.assignedTo),
		rating: buildSMVFilter(filterState.ratings),
		review_step: buildSMVFilter(filterState.reviewStep),
		inspection_template: buildMVFilter(filterState.inspectionTemplate),
		team: buildMVFilter(filterState.team),
		due_date: buildDRFilter(filterState.dueDateRange),
		completed_date: buildDRFilter(filterState.completedDateRange),
	};

	return filters;
};
