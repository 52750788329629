"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InspectionRestore = exports.InspectionWithoutRenter = exports.DocumentWithNoTeam = void 0;
const http_1 = require("./http");
class DocumentWithNoTeam extends http_1.BadRequest {
    constructor(collection) {
        super(`The ${collection} is not part of any teams`);
    }
}
exports.DocumentWithNoTeam = DocumentWithNoTeam;
class InspectionWithoutRenter extends http_1.BadRequest {
    constructor() {
        super('Inspection does not have a renter');
    }
}
exports.InspectionWithoutRenter = InspectionWithoutRenter;
class InspectionRestore extends http_1.BadRequest {
    constructor(reason) {
        super(`Inspection cannot be restored - ${reason}`);
    }
}
exports.InspectionRestore = InspectionRestore;
