import { Accordion } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { integrations } from 'assets';
import { useTypedSelector } from 'store/reducers/rootReducer';

import Content from '../common/content';
import Header from '../common/header';

import { Permissions } from '@rentcheck/biz';
import { useRentManagerIntegrations } from 'hooks/rentmanager-integration';
import ActiveIntegration from './active';
import InactiveIntegration from './inactive';

const headerProps = {
	icon: integrations.rentManager,
	title: 'Rent Manager',
	body: 'Import and sync property and resident data from Rent Manager to RentCheck. Send completed inspection reports back to Rent Manager.',
	body2: 'Available as an add on to a RentCheck subscription.',
	route: '/account/integrations/rentmanager',
};

const RentManager = () => {
	const location = useLocation();

	const subscription = useTypedSelector((state) => state.subscription);
	const { rentManagerIntegrations } = useRentManagerIntegrations();

	const expanded = location.pathname.startsWith(headerProps.route);

	const canInstall =
		Permissions.Integrations.RentManager.canInstall(subscription);

	return (
		<>
			<Accordion expanded={expanded}>
				<Header {...headerProps} active={rentManagerIntegrations.length > 0} />
				<Content>
					{canInstall && <ActiveIntegration />}
					{!canInstall && <InactiveIntegration />}
				</Content>
			</Accordion>
		</>
	);
};

export default RentManager;
