import { Redirect, useLocation } from 'react-router-dom';
import { useTypedSelector } from 'store/reducers/rootReducer';

import { Utils } from '@rentcheck/biz';

import { Screens } from 'components';
import AppFolio from './appfolio';
import Latchel from './latchel';
import RentManager from './rent-manager';
import RentCheckAPI from './rentcheck-api';
import Rentvine from './rentvine';
import Survey from './survey';
import Zapier from './zapier';

const Integrations = () => {
	const location = useLocation();

	const profile = useTypedSelector((state) => state.activeProfile);

	if (!location.pathname.startsWith('/account/integrations')) {
		return null;
	}

	if (!profile) {
		return null;
	}

	if (Utils.Users.isRenter(profile)) {
		return <Redirect to="/account/profile" />;
	}

	return (
		<Screens.Container>
			<Screens.Header title="Integrations" />

			<RentManager />
			<Latchel />
			<Zapier />
			<AppFolio />
			<Rentvine />
			<RentCheckAPI />
			<Survey />
		</Screens.Container>
	);
};

export default Integrations;
