"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServiceUnavailable = exports.BadGateway = exports.Conflict = exports.MethodNotAllowed = exports.NotFound = exports.Forbidden = exports.Unauthorized = exports.BadRequest = void 0;
const _1 = require(".");
class BadRequest extends _1.RentCheckException {
    constructor(message) {
        super(message, 400);
    }
}
exports.BadRequest = BadRequest;
class Unauthorized extends _1.RentCheckException {
    constructor(message) {
        super(message !== null && message !== void 0 ? message : 'unauthorized', 401);
    }
}
exports.Unauthorized = Unauthorized;
class Forbidden extends _1.RentCheckException {
    constructor(message) {
        super(message !== null && message !== void 0 ? message : 'forbidden', 403);
    }
}
exports.Forbidden = Forbidden;
class NotFound extends _1.RentCheckException {
    constructor(documentName, id) {
        super(`${documentName} not found${id ? ` (${id})` : ''}`, 404);
    }
}
exports.NotFound = NotFound;
class MethodNotAllowed extends _1.RentCheckException {
    constructor() {
        super('method not allowed', 405);
    }
}
exports.MethodNotAllowed = MethodNotAllowed;
class Conflict extends _1.RentCheckException {
    constructor(message, resource) {
        super(message !== null && message !== void 0 ? message : `conflict${resource ? ` on ${resource}` : ''}`, 409);
    }
}
exports.Conflict = Conflict;
class BadGateway extends _1.RentCheckException {
    constructor(message) {
        super(message !== null && message !== void 0 ? message : 'bad gateway', 502);
    }
}
exports.BadGateway = BadGateway;
class ServiceUnavailable extends _1.RentCheckException {
    constructor(message) {
        super(message !== null && message !== void 0 ? message : 'service unavailable', 503);
    }
}
exports.ServiceUnavailable = ServiceUnavailable;
