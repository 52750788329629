var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import _ from 'lodash';
import { request } from '../request';
export const sendReminder = (recipients, message, channels) => __awaiter(void 0, void 0, void 0, function* () {
    return request({
        method: 'post',
        url: '/inspections/reminders',
        data: {
            recipients,
            message,
            channels,
        },
    }).then((value) => value.data.data);
});
const createErrorsArray = (e, payload) => {
    return payload.data.property_ids.map((id) => {
        const error = {
            property: {
                id,
                address: '',
                city: '',
                zipcode: '',
            },
            inspection: {
                type: payload.data.inspection_template_id,
            },
            reason: e.message,
        };
        return error;
    });
};
export const create = (payload) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const chunkSize = 50;
    if (['self-perform', 'myself'].includes(payload.data.recipients.type)) {
        delete payload.data.invite_date;
    }
    if (payload.data.recipients.type !== 'teammates') {
        delete payload.data.notify_residents;
    }
    if (payload.data.recurrence && payload.data.recurrence.type !== 'None') {
        delete payload.data.invite_date;
    }
    const propertyIds = payload.data.property_ids;
    const propertyIdChunks = _.chunk(propertyIds, chunkSize);
    const payloads = propertyIdChunks.map((ids) => {
        const thisPayload = _.cloneDeep(payload);
        thisPayload.data.property_ids = ids;
        return thisPayload;
    });
    const result = yield Promise.allSettled(payloads.map((p) => request({
        method: 'post',
        url: '/inspections',
        params: {
            user_id: p.userId,
        },
        data: p.data,
        version: 'v2',
    })
        .then((value) => value.data.data)
        .catch((e) => {
        const result = {
            success: [],
            errors: createErrorsArray(e, p),
            warnings: [],
        };
        return result;
    })));
    /**
     * We catch all failures so we can assume every result will be a success
     */
    const successResult = result.map((r) => r.value);
    const response = {
        success: _.flatten(successResult.map((r) => r.success)),
        errors: _.flatten(successResult.map((r) => r.errors)),
        warnings: _.flatten(successResult.map((r) => r.warnings)),
        conflict: (_a = successResult.find((sr) => sr.conflict)) === null || _a === void 0 ? void 0 : _a.conflict,
    };
    return response;
});
export const requestReview = (inspectionId, dueDate, recipients, message) => __awaiter(void 0, void 0, void 0, function* () {
    return request({
        method: 'post',
        url: `/inspections/${inspectionId}/review`,
        data: {
            due_date: dueDate,
            recipients,
            message,
        },
    }).then((value) => value.data.data);
});
export const manualSync = (inspectionId) => __awaiter(void 0, void 0, void 0, function* () {
    return request({
        method: 'post',
        url: `/inspections/manual-sync/${inspectionId}`,
    }).then((value) => value.data.data);
});
export const submit = (id, payload) => __awaiter(void 0, void 0, void 0, function* () {
    return request({
        method: 'post',
        url: `/inspections/${id}/submit`,
        data: payload,
    }).then((value) => value.data.data);
});
export const logEdit = (id, data) => __awaiter(void 0, void 0, void 0, function* () {
    return request({
        method: 'post',
        url: `/inspections/${id}/edit`,
        data,
    }).then((value) => value.data.data);
});
export const start = (inspectionId) => __awaiter(void 0, void 0, void 0, function* () {
    return request({
        method: 'post',
        url: `/inspections/${inspectionId}/start`,
        data: {},
    }).then((value) => value.data.data);
});
export const sign = (inspectionId, data) => __awaiter(void 0, void 0, void 0, function* () {
    return request({
        method: 'post',
        url: `/inspections/${inspectionId}/sign`,
        data,
    }).then((value) => value.data.data);
});
export const rate = (inspectionId, data) => __awaiter(void 0, void 0, void 0, function* () {
    return request({
        method: 'post',
        url: `/inspections/${inspectionId}/rate`,
        data,
    }).then((value) => value.data.data);
});
