import { Typography } from '@mui/material';
import { Spacer } from 'components';
import { useTypedSelector } from 'store/reducers/rootReducer';

import DataSyncModal, {
	IngestionFilterOptionType,
	MappingSectionType,
} from '../../common/data-sync-modal';
import InstallButton from '../../common/install-button';
import InstalledContent from '../../common/installed-content';

import { IntegrationsApi } from '@rentcheck/api-frontend';
import {
	ApiBaseIntegration,
	BaseIntegration,
	BaseSyncDetails,
	RentvinePropertyTypes,
	UpdateRentvineIntegrationsParams,
} from '@rentcheck/types';
import { useRentvineIntegration } from 'hooks/rentvine-integration';
import { Analytics } from 'utils';
import InstallModal from './install-modal';

const commonProps = {
	vendor: 'Rentvine',
	parentUrl: '/account/integrations/rentvine',
	learnMoreLink:
		'https://help.getrentcheck.com/en/articles/10295125-rentvine-integration',
};

const mappingSections: MappingSectionType[] = [
	{
		type: 'Units',
		title: 'Unit Sync',
		body: 'Unit Sync keeps RentCheck data current by importing and updating units from Rentvine daily. The system matches existing units for updates and creates new units when no match is found.',
		mode: 'read',
		mappings: [
			{
				columns: ['Address, Address 2', 'Address'],
				variant: {
					title: 'Append Rentvine unit name to the RentCheck address field.',
					columns: ['Address, Address 2, Name', 'Address'],
					key: 'address_includes_unit_name',
				},
			},
			{ columns: ['City', 'City'] },
			{ columns: ['Postal Code', 'Zip Code'] },
			{ columns: ['Beds', 'Bedrooms'] },
			{ columns: ['Full Baths', 'Full Bathrooms'] },
			{ columns: ['Half Baths', 'Half Bathrooms'] },
			{ columns: ['Property Type', 'Property Type'] },
		],
	},
	{
		type: 'Buildings',
		title: 'Building Sync',
		body: 'The Building Sync adds new multi-unit properties from Rentvine and keeps them up to date in RentCheck. On every sync, when a matching building is found the building will be updated with new information. Otherwise a new building will be created in RentCheck.',
		mode: 'read',
		mappings: [
			{ columns: ['Name', 'Building Name'] },
			{ columns: ['Address, Address 2', 'Address'] },
			{ columns: ['City', 'City'] },
			{ columns: ['Postal Code', 'Zip Code'] },
		],
	},
	{
		type: 'Residents',
		title: 'Resident Sync',
		body: 'The Resident Sync imports your tenant from Rentvine attached to a lease. When the Resident Sync is enabled contact details including email and phone are kept in sync along with resident status and key lease dates such as lease start, least end, move-in, renewal, and move-out dates.',
		mode: 'read',
		mappings: [
			{ columns: ['Name', 'First Name'] },
			{ columns: ['Name', 'Last Name'] },
			{ columns: ['Email', 'Email'] },
			{ columns: ['Phone', 'Phone'] },
			{ columns: ['Start Date', 'Lease Start Date'] },
			{ columns: ['End Date', 'Lease End Date'] },
			{ columns: ['Move Out Date', 'Move Out Date'] },
		],
	},
	{
		type: 'Inspection Reports',
		title: 'Inspection Sync',
		body: 'Send completed unit inspection reports back to Rentvine upon completion and approval. Inspection PDF reports will be uploaded and attached to the unit and lease.',
		mode: 'write',
		frequency: 12,
		mappings: [
			{
				columns: [
					'Inspection PDF report',
					'Attached to unit and current lease',
				],
			},
		],
	},
];

const Active = () => {
	const { rentvineIntegration } = useRentvineIntegration();

	const subscription = useTypedSelector((state) => state.subscription);

	const getIntegration = () => {
		return IntegrationsApi.Rentvine.get().then((data) => data);
	};

	const getExternalTeams = () => {
		return IntegrationsApi.Rentvine.getPortfolios();
	};

	const getIngestionFilterOptions = () => {
		return IntegrationsApi.Rentvine.getPortfolios();
	};

	const getPropertyTypeFilterOptions = () => {
		return Array.from(RentvinePropertyTypes);
	};

	const handleSaveIntegration = (
		integration: ApiBaseIntegration,
		basePayload: UpdateRentvineIntegrationsParams['data'],
		firstTimeSetup: boolean,
		propertyTypes?: string[],
		ingestionFilters?: IngestionFilterOptionType[]
	) => {
		const payload: UpdateRentvineIntegrationsParams['data'] = {
			...basePayload,
			property_groups: ingestionFilters?.map((ing) => ing.id),
			property_types:
				propertyTypes as UpdateRentvineIntegrationsParams['data']['property_types'],
		};

		return IntegrationsApi.Rentvine.update(payload).then(async () => {
			await IntegrationsApi.Rentvine.manualSync();

			if (firstTimeSetup && subscription) {
				const yesNoFromSyncDetails = (syncDetails?: BaseSyncDetails) => {
					return syncDetails?.active ? 'yes' : 'no';
				};

				const syncDetails = basePayload.sync_details as
					| BaseIntegration['sync_details']
					| undefined;

				Analytics.trackEvent('enabled_rentvine_sync', {
					subscription_id: subscription.id,
					chargebee_sub_id: subscription.plan.sub_id,
					building_sync_enabled: yesNoFromSyncDetails(syncDetails?.buildings),
					unit_sync_enabled: yesNoFromSyncDetails(syncDetails?.units),
					resident_sync_enabled: yesNoFromSyncDetails(syncDetails?.residents),
					inspection_sync_enabled: yesNoFromSyncDetails(
						syncDetails?.inspections
					),
					work_order_sync_enabled: yesNoFromSyncDetails(
						syncDetails?.work_orders
					),
				});
			}
		});
	};

	const isInstalled = !!rentvineIntegration;

	return (
		<>
			{!isInstalled && (
				<>
					<Typography sx={{ marginTop: -5 }}>
						Available as an add on to a RentCheck subscription.
					</Typography>
					<Spacer height={6} />
				</>
			)}

			<InstallButton
				isInstalled={isInstalled}
				InstallModal={InstallModal}
				{...commonProps}
			/>

			{isInstalled && (
				<>
					<InstalledContent {...commonProps} title={undefined} />

					<DataSyncModal
						{...commonProps}
						mappingSections={mappingSections}
						getIntegration={getIntegration}
						handleSaveIntegration={(
							integration,
							basePayload,
							firstTimeSetup,
							propertyTypes,
							ingestionFilters
						) =>
							handleSaveIntegration(
								integration,
								basePayload as UpdateRentvineIntegrationsParams['data'],
								firstTimeSetup,
								propertyTypes,
								ingestionFilters
							)
						}
						teamAssignment={{ extTeamName: 'Portfolio', getExternalTeams }}
						ingestionFilter={{
							name: 'Portfolio',
							selector: 'property_groups',
							getFilterOptions: getIngestionFilterOptions,
						}}
						propertyTypeFilter={{
							name: 'Property Type',
							selector: 'property_types',
							getFilterOptions: getPropertyTypeFilterOptions,
						}}
					/>
				</>
			)}
		</>
	);
};

export default Active;
