import { Paper, Switch, Typography } from '@mui/material';
import { Permissions } from '@rentcheck/biz';

import { colors } from 'theme';
import { Row, SpacedRow, PlanGate } from 'components';
import { useTypedSelector } from 'store/reducers/rootReducer';

interface Props {
	title: string;
	selected: boolean;
	handleToggleSection: () => void;
	children: React.ReactNode;
}

export default ({ title, selected, handleToggleSection, children }: Props) => {
	const subscription = useTypedSelector((state) => state.subscription);

	return (
		<Paper
			elevation={0}
			sx={{ border: '1px solid #0000001F', padding: '8px 16px 16px', mt: 3 }}>
			<SpacedRow style={{ marginBottom: 4 }}>
				<Typography variant="subtitle1">{title}</Typography>

				<Row>
					<Typography variant="overline" color={colors.secondary}>
						{selected ? 'on' : 'off'}
					</Typography>
					<PlanGate variant="professional">
						<Switch
							checked={selected}
							onChange={handleToggleSection}
							disabled={
								!Permissions.AccountSettings.MaintenanceSettings.canEdit(
									subscription
								)
							}
						/>
					</PlanGate>
				</Row>
			</SpacedRow>

			{children}
		</Paper>
	);
};
