import { AccountSettingsApi } from '@rentcheck/api-frontend';
import { AccountSettings, DeleteRoomsParams } from '@rentcheck/types';
import { Dispatch, GetState } from 'types';
import { Analytics } from 'utils';

export const get = () => async (dispatch: Dispatch) => {
	const accountSettings = await AccountSettingsApi.get();

	dispatch({ type: 'SET_ACCOUNT_SETTINGS', value: accountSettings });
};

export const deleteRooms =
	(data: DeleteRoomsParams['data']) => async (dispatch: Dispatch) => {
		const updatedSettings = await AccountSettingsApi.deleteRooms(data);

		dispatch({ type: 'SET_ACCOUNT_SETTINGS', value: updatedSettings });
	};

export const updateInspectionReportTemplates =
	(payload: AccountSettings['inspection_report_templates']) =>
	async (dispatch: Dispatch, getState: GetState) => {
		const accountSettings = getState().accountSettings;
		const profile = getState().activeProfile;

		if (!accountSettings) {
			throw Error('No account settings in store');
		}

		if (!profile) {
			throw Error('No active profile in store');
		}

		const updatedSettings = await AccountSettingsApi.update({
			inspection_report_templates: payload,
		});

		dispatch({ type: 'SET_ACCOUNT_SETTINGS', value: updatedSettings });

		Analytics.trackEvent('edited_inspection_report_settings', {
			user_id: profile.id,
			user_name: profile.name,
			subscription_id: accountSettings.subscription_id,
		});

		return updatedSettings;
	};

export const updateSkills =
	(payload: AccountSettings['skills']) => async (dispatch: Dispatch) => {
		const updatedSettings = await AccountSettingsApi.update({
			skills: payload,
		});

		dispatch({ type: 'SET_ACCOUNT_SETTINGS', value: updatedSettings });

		return updatedSettings;
	};

export const updateRooms =
	(payload: AccountSettings['rooms']) => async (dispatch: Dispatch) => {
		const updatedSettings = await AccountSettingsApi.update({ rooms: payload });

		dispatch({ type: 'SET_ACCOUNT_SETTINGS', value: updatedSettings });

		return updatedSettings;
	};

export const updateInspectionContactInfo =
	(payload: AccountSettings['inspection_contact_info']) =>
	async (dispatch: Dispatch) => {
		const updatedSettings = await AccountSettingsApi.update({
			inspection_contact_info: payload,
		});

		dispatch({ type: 'SET_ACCOUNT_SETTINGS', value: updatedSettings });

		return updatedSettings;
	};

export const setResidentReportedMaintenanceEnabled =
	(enable: boolean) => async (dispatch: Dispatch) => {
		const updatedSettings = await AccountSettingsApi.update({
			is_resident_reported_maintenance_enabled: enable,
		});

		dispatch({ type: 'SET_ACCOUNT_SETTINGS', value: updatedSettings });

		return updatedSettings;
	};

export const setMaintenanceFlagDefaultPhotosEnabled =
	(enable: boolean) => async (dispatch: Dispatch) => {
		const updatedSettings = await AccountSettingsApi.update({
			is_maintenance_flag_default_photos_enabled: enable,
		});

		dispatch({ type: 'SET_ACCOUNT_SETTINGS', value: updatedSettings });

		return updatedSettings;
	};

export const setInspectionReviewAutoapprovalEnabled =
	(enable: boolean) => async (dispatch: Dispatch) => {
		const updatedSettings = await AccountSettingsApi.update({
			is_inspection_review_autoapproval_enabled: enable,
		});

		dispatch({ type: 'SET_ACCOUNT_SETTINGS', value: updatedSettings });

		return updatedSettings;
	};
