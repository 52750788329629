"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvalidSubscriptionEvent = exports.InvalidOperation = void 0;
const http_1 = require("./http");
class InvalidOperation extends http_1.BadRequest {
    constructor(badOperation) {
        super(`invalid "${badOperation}" operation`);
    }
}
exports.InvalidOperation = InvalidOperation;
class InvalidSubscriptionEvent extends http_1.BadRequest {
    constructor(event) {
        super(`invalid subscription event "${event}"`);
    }
}
exports.InvalidSubscriptionEvent = InvalidSubscriptionEvent;
