import { Accordion } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { integrations } from 'assets';
import { useTypedSelector } from 'store/reducers/rootReducer';

import Content from '../common/content';
import Header from '../common/header';

import { Permissions } from '@rentcheck/biz';
import ActiveIntegration from './active';
import InactiveIntegration from './inactive';
import { useRentvineIntegration } from 'hooks/rentvine-integration';

const headerProps = {
	icon: integrations.rentvine,
	title: 'Rentvine',
	body: 'Import and sync property and resident data from Rentvine to RentCheck. Send completed inspection reports back to Rentvine.',
	body2: 'Available as an add on to a RentCheck subscription.',
	route: '/account/integrations/rentvine',
};

const Rentvine = () => {
	const location = useLocation();

	const subscription = useTypedSelector((state) => state.subscription);
	const { rentvineIntegration } = useRentvineIntegration();

	const expanded = location.pathname.startsWith(headerProps.route);

	const canInstall = Permissions.Integrations.Rentvine.canInstall(subscription);

	return (
		<>
			<Accordion expanded={expanded}>
				<Header {...headerProps} active={!!rentvineIntegration} />
				<Content>
					{canInstall && <ActiveIntegration />}
					{!canInstall && <InactiveIntegration />}
				</Content>
			</Accordion>
		</>
	);
};

export default Rentvine;
