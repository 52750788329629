var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { APIConfig } from '../..';
import { request } from '../../request';
export const getPortfolios = () => __awaiter(void 0, void 0, void 0, function* () {
    return request({
        method: 'get',
        url: `/rentvine/portfolios`,
        params: {
            user_id: APIConfig.getUserId(),
        },
    })
        .then((value) => value.data.data
        .filter((portfolio) => portfolio.isActive !== '0')
        .map((portfolio) => {
        return Object.assign({ id: portfolio.portfolioID }, portfolio);
    }))
        .catch(() => []);
});
export const get = () => __awaiter(void 0, void 0, void 0, function* () {
    return request({
        method: 'get',
        url: '/integrations/rentvine',
    }).then((value) => {
        return value.data.data;
    });
});
