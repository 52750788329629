import {
	FormControlLabel,
	Paper,
	Radio,
	RadioGroup,
	Typography,
} from '@mui/material';
import { useState } from 'react';

import { NotificationsPreferences } from '@rentcheck/types';

import { NotificationsPreferencesAPI } from '@rentcheck/api-frontend';
import { Spacer } from 'components';
import { colors } from 'theme';

interface PreferencesSnippetProps {
	title: string;
	description: string;
	preferenceKey: keyof Pick<
		NotificationsPreferences,
		| 'inspections_awaiting_review'
		| 'inspections_in_revision_review'
		| 'automation_failures'
	>;
	preferenceType: 'personal' | 'subscription' | 'encrypted';
	emailOptionObjectName?: 'inspections';
	token?: string;
	notificationPreferences: NotificationsPreferences;
}

export default ({
	title,
	description,
	preferenceKey,
	preferenceType,
	emailOptionObjectName,
	token,
	notificationPreferences,
}: PreferencesSnippetProps) => {
	const preference = notificationPreferences[preferenceKey];

	const [enabled, setEnabled] = useState(preference.enabled);

	const [emailPreference, setEmailPreference] = useState(preference.email);

	const updateNotificationPreference = async () => {
		switch (preferenceType) {
			case 'personal':
				NotificationsPreferencesAPI.updatePersonal(notificationPreferences);
				break;
			case 'subscription':
				NotificationsPreferencesAPI.updateSubscription(notificationPreferences);
				break;
			case 'encrypted':
				if (token) {
					NotificationsPreferencesAPI.updateByEncryptedToken(
						token,
						notificationPreferences
					);
				}
				break;
		}
	};

	const handleEnabledChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEnabled(event.target.value === 'true');

		notificationPreferences[preferenceKey] = {
			...preference,
			enabled: event.target.value === 'true',
		};

		updateNotificationPreference();
	};

	const handleEmailPreferenceChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setEmailPreference(event.target.value as 'all' | 'created_by_me');

		notificationPreferences[preferenceKey] = {
			...preference,
			email: event.target.value as 'all' | 'created_by_me',
		};

		updateNotificationPreference();
	};

	return (
		<div style={{ padding: '24px' }}>
			<Typography variant="subtitle1">{title}</Typography>
			<Spacer height={2} />
			<Typography variant="body1" color={colors.secondary}>
				{description}
			</Typography>
			<Spacer height={4} />
			<RadioGroup value={enabled} onChange={handleEnabledChange}>
				<FormControlLabel
					value={false}
					control={<Radio />}
					label={<Typography variant="body1">Don't notify me</Typography>}
				/>
				<FormControlLabel
					value={true}
					control={<Radio />}
					label={
						<Typography variant="body1">
							{emailOptionObjectName
								? 'Send me notifications'
								: 'Send me notifications (Email)'}
						</Typography>
					}
				/>
			</RadioGroup>
			{emailOptionObjectName && enabled && (
				<Paper
					elevation={0}
					sx={{
						border: '1px solid #0000001F',
						borderRadius: '8px',
						padding: '16px 16px 16px',
						ml: 4,
						mt: 1,
					}}>
					<Typography>Email</Typography>

					<RadioGroup
						value={emailPreference}
						onChange={handleEmailPreferenceChange}>
						<FormControlLabel
							value="all"
							control={<Radio />}
							label={
								<Typography variant="body1">
									All {emailOptionObjectName}
								</Typography>
							}
						/>
						<FormControlLabel
							value="created_by_me"
							control={<Radio />}
							label={
								<Typography variant="body1">
									Only {emailOptionObjectName} I've created
								</Typography>
							}
						/>
					</RadioGroup>
				</Paper>
			)}
		</div>
	);
};
