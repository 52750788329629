import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Autocomplete,
	Card,
	CardContent,
	Link,
	Paper,
	Skeleton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';

import { ApiBaseIntegration, ApiBaseSyncDetails } from '@rentcheck/types';

import { Column, SpacedRow, Spacer } from 'components';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { spacingPx } from 'theme';

import FormTextField from 'components/form-text-field';
import { DefaultSkillType, DefaultWorkOrderStatusType } from '../..';
import { MappingSection as MappingSectionType } from '../../types';
import Switch from '../switch';
import SyncDetails from '../sync-details';
import Variants from './variants';

interface Props {
	provider: string;
	mappingSection: MappingSectionType;
	syncToggle: boolean;
	setSyncToggle: (toggle: boolean) => void;
	syncDetailsExtractor: (
		integration?: ApiBaseIntegration
	) => ApiBaseSyncDetails | undefined;
	setVariantFields: (value: ApiBaseIntegration['variant_fields']) => void;
	integration?: ApiBaseIntegration;
	defaultSkill?: DefaultSkillType;
	defaultWorkOrderStatus?: DefaultWorkOrderStatusType;
}

const AutocompleteSkeleton = () => {
	return (
		<>
			<Skeleton
				sx={{ mt: 1, borderRadius: 1 }}
				height={50}
				variant="rectangular"
			/>
			<Skeleton sx={{ mt: 0.1, ml: 2 }} width={60} height={20} />
		</>
	);
};

const MappingSection = ({
	provider,
	mappingSection,
	syncToggle,
	integration,
	setSyncToggle,
	syncDetailsExtractor,
	setVariantFields,
	defaultSkill,
	defaultWorkOrderStatus,
}: Props) => {
	const {
		title,
		body,
		mode,
		frequency,
		mappings,
		type: objectType,
		requiredAddons,
	} = mappingSection;

	const subscription = useTypedSelector((state) => state.subscription);

	const hasRequiredAddons = requiredAddons?.check(subscription) ?? true;

	const isWorkOrderSection =
		objectType === 'Work Orders' || objectType === 'Service Issues';

	/**
	 * Dont't render these sections until we get the skill list back, there's
	 * a bug in the autocomplete component where if you use a controlled component
	 * and the value is not in the options it will not select it once the options
	 * become available
	 */
	const showDefaultSkillSelection = isWorkOrderSection && defaultSkill;
	const defaultSkillLoading = !(defaultSkill?.getOptions() ?? []).length;

	const showDefaultWorkOrderStatusSelection =
		isWorkOrderSection && defaultWorkOrderStatus;
	const defaultWorkOrderStatusLoading = !(
		defaultWorkOrderStatus?.getOptions() ?? []
	).length;

	return (
		<Card sx={{ marginBottom: 4 }}>
			<CardContent>
				<Column>
					<SpacedRow>
						<Typography variant="subtitle1">{title}</Typography>
						<Switch
							color="success"
							checked={syncToggle}
							onChange={() => setSyncToggle(!syncToggle)}
						/>
					</SpacedRow>
					<Spacer height={2} />
					<Typography variant="body2" color="#00000099">
						{body}
					</Typography>
					<Spacer height={6} />
				</Column>

				<Variants
					mappingSection={mappingSection}
					variantFields={integration?.variant_fields ?? {}}
					setVariantFields={setVariantFields}
				/>

				<SpacedRow style={{ alignItems: 'flex-start' }}>
					<Column style={{ flex: 7 }}>
						<Typography variant="subtitle2">
							{mode === 'read' ? provider : 'RentCheck'} to{' '}
							{mode === 'write' ? provider : 'RentCheck'} field mappings:
						</Typography>
						<Spacer height={3} />

						<TableContainer
							component={Paper}
							elevation={0}
							sx={{ border: '1px solid #0000001F', borderBottom: 'none' }}>
							<Table>
								<TableHead
									sx={{
										th: {
											backgroundColor: mode === 'read' ? undefined : '#F2F2FD',
										},
									}}>
									<TableCell>
										{mode === 'read' ? provider : 'RentCheck'}
									</TableCell>

									<TableCell>
										<FontAwesomeIcon icon={solid('long-arrow-right')} />
									</TableCell>

									<TableCell>
										{mode === 'write' ? provider : 'RentCheck'}
									</TableCell>
								</TableHead>
								<TableBody>
									{mappings.map((m, index) => {
										const isVariantActive =
											(m.variant &&
												integration?.variant_fields[m.variant.key]) ??
											false;

										if (m.variant && isVariantActive) {
											return (
												<TableRow key={index}>
													<TableCell>{m.variant.columns[0]}</TableCell>
													<TableCell />
													<TableCell>{m.variant.columns[1]}</TableCell>
												</TableRow>
											);
										}

										return (
											<TableRow key={index}>
												<TableCell>{m.columns[0]}</TableCell>
												<TableCell />
												<TableCell>{m.columns[1]}</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</TableContainer>
					</Column>
					<Spacer width={5} />

					<Column style={{ flex: 3 }}>
						<Typography variant="subtitle2">Sync Details:</Typography>

						<Spacer height={3} />

						<SyncDetails
							vendor={provider}
							objectType={objectType}
							count={syncDetailsExtractor(integration)?.count ?? 0}
							date={syncDetailsExtractor(integration)?.last_sync}
							frequency={frequency}
						/>
					</Column>
				</SpacedRow>

				{showDefaultSkillSelection && (
					<Column>
						<Typography variant="subtitle2" mt={2}>
							Set the default {defaultSkill?.name}
						</Typography>
						<Typography variant="body2" mt={1} color="#4d4d4d">
							This {defaultSkill?.shortName} will be set by default when
							flagging an item for maintenance but can be updated as needed
						</Typography>
						{defaultSkillLoading && <AutocompleteSkeleton />}
						{!defaultSkillLoading && (
							<Autocomplete
								options={defaultSkill?.getOptions() || []}
								getOptionLabel={(o) => o.name}
								sx={{ mt: 1 }}
								value={defaultSkill?.value}
								onChange={(e, v) => defaultSkill?.setValue(v ?? undefined)}
								isOptionEqualToValue={(o, v) => o.name === v?.name}
								renderInput={(params) => (
									<FormTextField
										{...params}
										placeholder={`Select a default ${defaultSkill?.name}`}
										error={syncToggle && !defaultSkill?.value}
										helperText={'Required'}
									/>
								)}
							/>
						)}
					</Column>
				)}

				{showDefaultWorkOrderStatusSelection && (
					<Column>
						<Typography variant="subtitle2" mt={2}>
							Set the {defaultWorkOrderStatus?.name} for new{' '}
							{objectType.toLowerCase()} created in {provider}
						</Typography>
						<Typography variant="body2" mt={1} color="#4d4d4d">
							Select the {defaultWorkOrderStatus?.name} that should be set when
							new {objectType.toLowerCase()} are created in {provider}
						</Typography>
						{defaultWorkOrderStatusLoading && <AutocompleteSkeleton />}
						{!defaultWorkOrderStatusLoading && (
							<Autocomplete
								options={defaultWorkOrderStatus?.getOptions() || []}
								getOptionLabel={(o) => o.name}
								sx={{ mt: 1 }}
								value={defaultWorkOrderStatus?.value}
								onChange={(e, v) =>
									defaultWorkOrderStatus?.setValue(v ?? undefined)
								}
								isOptionEqualToValue={(o, v) => o.name === v?.name}
								renderInput={(params) => (
									<FormTextField
										{...params}
										placeholder={`Select a default ${defaultWorkOrderStatus?.name}`}
										error={syncToggle && !defaultWorkOrderStatus?.value}
										helperText={'Required'}
									/>
								)}
							/>
						)}
					</Column>
				)}
			</CardContent>

			{!!requiredAddons && !hasRequiredAddons && (
				<Typography
					variant="subtitle2"
					style={{
						backgroundColor: '#E9F7EC',
						width: '100%',
						padding: spacingPx(4),
					}}>
					Available on {requiredAddons?.name}.{' '}
					<Link
						color="#232E3A"
						href="https://help.getrentcheck.com/en"
						target="_blank">
						Upgrade now
					</Link>
				</Typography>
			)}
		</Card>
	);
};

export default MappingSection;
