import { UserRequestsApi } from '@rentcheck/api-frontend';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { SnackbarActions } from 'store/actions';
import Skeleton from '../details/skeleton';

const InspectionRequestLoader = () => {
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();

	useEffect(() => {
		const pathComponents = location.pathname.split('/');
		const requestId = pathComponents[2];

		if (!requestId) history.replace('/404');

		UserRequestsApi.getSignatureRequest(requestId)
			.then(async (data) => {
				if (data.status === 'REQUEST_SENT') {
					await UserRequestsApi.acceptPendingSignatureRequests(
						data.inspection.id
					);
				}

				history.replace(`/inspections/${data.inspection.id}`);
			})
			.catch((e) => {
				history.replace('/404');
				dispatch(SnackbarActions.showError(e));
			});
	}, [location.search]);

	return <Skeleton />;
};

export default InspectionRequestLoader;
