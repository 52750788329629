"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RentvineVendorAccessTypes = exports.RentvinePriorityLevels = void 0;
exports.RentvinePriorityLevels = {
    low: 1,
    medium: 2,
    high: 3,
};
exports.RentvineVendorAccessTypes = {
    vendor_may_access: 1,
    resident_presence_required: 2,
    not_applicable: 3,
};
