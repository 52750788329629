var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { request } from '../request';
export const completeSignatureRequest = (inspectionId, data) => __awaiter(void 0, void 0, void 0, function* () {
    return request({
        method: 'post',
        version: 'v1',
        url: `/inspections/${inspectionId}/signature-requests/sign`,
        data,
    }).then((value) => value.data.data);
});
export const createSignatureRequest = (inspectionId, payload) => __awaiter(void 0, void 0, void 0, function* () {
    return request({
        method: 'post',
        version: 'v1',
        url: `/inspections/${inspectionId}/signature-requests`,
        data: payload,
    }).then((value) => value.data.data);
});
export const createTakeOverRequest = (inspectionId) => __awaiter(void 0, void 0, void 0, function* () {
    return request({
        method: 'post',
        version: 'v1',
        url: `/inspections/${inspectionId}/take-over-requests`,
    }).then((value) => value.data.data);
});
