import { Divider, Paper, Typography } from '@mui/material';
import { Column } from 'components';
import { useSubscriptionNotificationPreferences } from 'hooks/notification-preferences';
import { borders, colors, radius } from 'theme';

import { useTypedSelector } from 'store/reducers/common';
import PreferencesSnippet from '../common/preferences-snippet';
import Skeleton from '../common/skeleton';

export default () => {
	const profile = useTypedSelector((state) => state.activeProfile);

	const { loading, notificationPreferences } =
		useSubscriptionNotificationPreferences();

	if (loading || !notificationPreferences || !profile) {
		return <Skeleton />;
	}

	return (
		<>
			<Typography variant="body2" color={colors.secondary}>
				Settings that apply to everyone on your account unless they set personal
				preferences
			</Typography>
			<Paper
				elevation={0}
				sx={{
					border: borders.cardBorder,
					borderRadius: radius.large,
					padding: '8px 16px 16px',
					mt: 3,
				}}>
				<Column>
					<PreferencesSnippet
						title="Inspections Awaiting Review"
						description="Get notified when requested inspections are submitted for review."
						preferenceKey="inspections_awaiting_review"
						preferenceType="subscription"
						emailOptionObjectName="inspections"
						notificationPreferences={notificationPreferences}
					/>

					<Divider />

					<PreferencesSnippet
						title="Inspections in Revision Review"
						description="Get notified when inspection revisions are submitted for review."
						preferenceKey="inspections_in_revision_review"
						preferenceType="subscription"
						emailOptionObjectName="inspections"
						notificationPreferences={notificationPreferences}
					/>

					{profile.permissions.allow_automation_editing && (
						<>
							<Divider />

							<PreferencesSnippet
								title="Automation Failures"
								description="Get notified when an active automation fails to create an inspection."
								preferenceKey="automation_failures"
								preferenceType="subscription"
								notificationPreferences={notificationPreferences}
							/>
						</>
					)}
				</Column>
			</Paper>
		</>
	);
};
