import _ from 'lodash';
import queryString from 'query-string';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import * as AuthActions from 'store/actions/auth-actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';

const Navbar = () => {
	const history = useHistory();
	const location = useLocation();
	const URLParameters = queryString.parse(location.search);

	const dispatch: Dispatch = useDispatch();

	const auth = useTypedSelector((state) => state.firebase.auth);

	useEffect(() => {
		// Unlike what the function name suggests, this action gets the profile from firebase
		// when the app is first launched if there was a user logged in the last time they
		// used the app. Don't delete this call, and if you do replace it with a suitable
		// method for fetching the data/updating the user.
		dispatch(AuthActions.checkIfUserExists());
	}, []);

	const isLocationAuth = () => {
		// fix to not been redirected to the sign in page
		// when the user is not logged in and tries to signup

		return [
			'/signin',
			'/signup',
			'/image-viewer',
			'/essential',
			'/apmhelp',
			'/appfolio',
			'/account/notification-preferences',
		].some((signInLocation) => location.pathname.startsWith(signInLocation));
	};

	const redirect = (
		deeplink: string,
		URLParameters?: queryString.ParsedQuery<string>
	) => {
		if (deeplink === '/') {
			history.replace('/signin');

			return null;
		}

		let redirectTo = '/signin?redirect=' + deeplink;

		if (URLParameters) {
			_.mapKeys(URLParameters, (value, key) => {
				redirectTo = redirectTo.concat(`&${key}=${value}`);
			});
		}

		history.replace(redirectTo);

		return null;
	};

	if (!auth.uid && !isLocationAuth()) {
		const deeplink = URLParameters.$deeplink_path as string;

		if (deeplink?.startsWith('/login')) {
			const components = deeplink.split('/');
			const email = components[2];
			const password = components[3];

			return redirect('/signin', { email, password });
		}

		if (deeplink) {
			return redirect(deeplink as string);
		}

		if (location.pathname) {
			return redirect(location.pathname, URLParameters);
		}
	}

	return null;
};

export default Navbar;
