import { Divider, Paper, Typography } from '@mui/material';

import { Column } from 'components';
import { useEncryptedNotificationPreferences } from 'hooks/notification-preferences';
import { borders, radius } from 'theme';

import PreferencesSnippet from '../common/preferences-snippet';
import Skeleton from '../common/skeleton';
import InvalidToken from './invalid-token';

interface EncryptedPreferencesProps {
	token: string;
}

export default ({ token }: EncryptedPreferencesProps) => {
	const { loading, notificationPreferences } =
		useEncryptedNotificationPreferences(token);

	if (loading) {
		return <Skeleton />;
	}

	if (!notificationPreferences || !notificationPreferences.user_email) {
		return <InvalidToken />;
	}

	return (
		<>
			<Typography color="#4d4d4d">
				Settings for <strong>{notificationPreferences.user_email}</strong>
			</Typography>

			<Paper
				elevation={0}
				sx={{
					border: borders.cardBorder,
					borderRadius: radius.large,
					padding: '8px 16px 16px',
					mt: 3,
				}}>
				<Column>
					<PreferencesSnippet
						title="Inspections Awaiting Review"
						description="Get notified when requested inspections are submitted for review."
						preferenceKey="inspections_awaiting_review"
						preferenceType="encrypted"
						emailOptionObjectName="inspections"
						token={token}
						notificationPreferences={notificationPreferences}
					/>

					<Divider />

					<PreferencesSnippet
						title="Inspections in Revision Review"
						description="Get notified when inspection revisions are submitted for review."
						preferenceKey="inspections_in_revision_review"
						preferenceType="encrypted"
						emailOptionObjectName="inspections"
						token={token}
						notificationPreferences={notificationPreferences}
					/>
				</Column>
			</Paper>
		</>
	);
};
