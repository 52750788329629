import { useDispatch } from 'react-redux';

import { ApiInspection } from '@rentcheck/types';

import { LoadingButton } from '@mui/lab';
import { Rules } from '@rentcheck/biz';
import { useState } from 'react';
import { InspectionActions, SnackbarActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';
import { Analytics } from 'utils';

interface Props {
	inspection: ApiInspection;
}

export default ({ inspection }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const profile = useTypedSelector((state) => state.activeProfile);

	const [loading, setLoading] = useState(false);

	const handleCancelRevision = () => {
		setLoading(true);

		dispatch(
			InspectionActions.updateInspection(inspection.id, {
				status: 'Review Inspection',
			})
		)
			.then(() => {
				dispatch(
					SnackbarActions.showSuccess('Revision request canceled successfully!')
				);

				Analytics.trackEvent('revision request canceled', {
					inspection_ids: inspection.id,
					userID: profile.id,
				});
			})
			.catch((e) => dispatch(SnackbarActions.showError(e)))
			.finally(() => setLoading(false));
	};

	if (!Rules.Inspections.canCancelRevisionRequest(inspection)) {
		return null;
	}

	return (
		<LoadingButton
			variant={'outlined'}
			color={'secondary'}
			loading={loading}
			sx={{ whiteSpace: 'nowrap' }}
			onClick={handleCancelRevision}>
			Cancel Revision Request
		</LoadingButton>
	);
};
