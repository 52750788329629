import { ListOptions, MultiValueFilter } from '@rentcheck/types';
import {
	DateRange,
	State as FiltersState,
} from 'store/reducers/inspections/filters';

export const getMultiValueFilterDependency = (
	filter?: MultiValueFilter<{ id: string } | string>
): (string | undefined)[] => {
	if (!filter) {
		return [undefined, undefined];
	}

	if (!filter.value) {
		return [undefined, undefined];
	}

	if (filter.value.length === 0) {
		return [undefined, undefined];
	}

	if (typeof filter.value[0] === 'string') {
		return [filter.value.join(''), filter.condition];
	}

	const filterAsIdObject = filter as MultiValueFilter<{ id: string }>;

	return [
		filterAsIdObject.value.map(({ id }) => id).join(''),
		filter.condition,
	];
};

export const getDateRangeFilterDependency = (filter?: DateRange) => {
	return [filter?.start, filter?.end];
};

export const getSortDependencies = (sort: ListOptions['sort']) => {
	return [sort.sort_by, sort.sort_type];
};

export const getFilterDependencies = (filters: FiltersState) => {
	return [
		filters.archived,
		filters.search,
		filters.assignedTo.join(''),
		(filters.ratings ?? []).join(''),
		(filters.reviewStep ?? []).join(''),
		...getDateRangeFilterDependency(filters.dueDateRange),
		...getDateRangeFilterDependency(filters.completedDateRange),
		...getMultiValueFilterDependency(filters.team),
		...getMultiValueFilterDependency(filters.inspectionStatus),
		...getMultiValueFilterDependency(filters.inspectionTemplate),
		...getSortDependencies(filters.sort),
	];
};
