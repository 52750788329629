import { useTypedSelector } from 'store/reducers/common';
import AuthenticatedLayout from './authenticated';
import Container from './common/container';
import Skeleton from './common/skeleton';
import UnauthenticatedLayout from './unauthenticated';

export default () => {
	const auth = useTypedSelector((state) => state.firebase.auth);

	if (!auth.isLoaded) {
		return (
			<Container>
				<Skeleton />
			</Container>
		);
	}

	if (auth.isEmpty) {
		return <UnauthenticatedLayout />;
	}

	return <AuthenticatedLayout />;
};
