"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.canEdit = exports.canSign = exports.canSubmit = exports.canAccess = exports.areFlagDefaultPhotosEnabled = exports.canRenterReportMaintenance = exports.canTake360Photos = exports.canRecordVideo = void 0;
const Utils = __importStar(require("../utils"));
const canRecordVideo = (subscription) => {
    if (!subscription) {
        return false;
    }
    if (Utils.Subscriptions.isOnEssentialsPlan(subscription)) {
        return false;
    }
    return true;
};
exports.canRecordVideo = canRecordVideo;
const canTake360Photos = (subscription) => {
    if (!subscription) {
        return false;
    }
    if (Utils.Subscriptions.isOnEssentialsPlan(subscription)) {
        return false;
    }
    return true;
};
exports.canTake360Photos = canTake360Photos;
const canRenterReportMaintenance = (accountSettings, subscription) => {
    if (!subscription) {
        return false;
    }
    if (Utils.Subscriptions.isOnEssentialsPlan(subscription)) {
        return false;
    }
    return accountSettings === null || accountSettings === void 0 ? void 0 : accountSettings.is_resident_reported_maintenance_enabled;
};
exports.canRenterReportMaintenance = canRenterReportMaintenance;
const areFlagDefaultPhotosEnabled = (accountSettings) => {
    if (!accountSettings)
        return false;
    return accountSettings.is_maintenance_flag_default_photos_enabled;
};
exports.areFlagDefaultPhotosEnabled = areFlagDefaultPhotosEnabled;
const canAccess = ({ user, inspection, permissionGroups, }) => {
    var _a, _b;
    const userIdIsInAuthorizedUserIds = inspection.authorized_user_ids.includes(user.id);
    if (userIdIsInAuthorizedUserIds)
        return true;
    const userOrgIds = user.organizations.map((org) => org.id);
    const userOrOrgsHaveAccessToInspection = userOrgIds.some((orgId) => inspection.authorized_user_ids.includes(orgId));
    const validInspectionTemplatesForUser = (_b = (_a = permissionGroups === null || permissionGroups === void 0 ? void 0 : permissionGroups.map((permissionGroup) => permissionGroup.inspection_templates)) === null || _a === void 0 ? void 0 : _a.flat()) !== null && _b !== void 0 ? _b : [];
    const userPermissionGroupsHaveAccessToInspectionTemplate = validInspectionTemplatesForUser === null || validInspectionTemplatesForUser === void 0 ? void 0 : validInspectionTemplatesForUser.includes(inspection.inspection_template.id);
    if (userOrOrgsHaveAccessToInspection &&
        userPermissionGroupsHaveAccessToInspectionTemplate) {
        return true;
    }
    if (user.internal_admin)
        return true;
    return false;
};
exports.canAccess = canAccess;
const canSubmit = ({ user, inspection, }) => {
    const validInspectionStatuses = [
        'Inspection Request Sent',
        'Inspection Request Accepted',
        'Start Inspection',
        'Send Request or Start Inspection',
        'Continue Inspection',
    ];
    if (!validInspectionStatuses.includes(inspection.inspection_status)) {
        const error = `Invalid inspection status: ${inspection.inspection_status}`;
        return { value: false, error };
    }
    if (inspection.renterID && inspection.renterID !== user.id) {
        const error = `User does not have permission to submit inspection`;
        return { value: false, error };
    }
    return { value: true };
};
exports.canSubmit = canSubmit;
const canSign = ({ user, inspection }) => {
    const validInspectionStatuses = ['Continue Inspection'];
    if (!validInspectionStatuses.includes(inspection.inspection_status)) {
        const error = `Invalid inspection status: ${inspection.inspection_status}`;
        return { value: false, error };
    }
    if (inspection.renterID && inspection.renterID !== user.id) {
        const error = `User does not have permission to submit inspection`;
        return { value: false, error };
    }
    return { value: true };
};
exports.canSign = canSign;
const canEdit = ({ changeset }) => {
    var _a, _b, _c, _d, _e, _f;
    if ('questions' in changeset) {
        const emptyResponse = ((_a = changeset.questions.find((q) => q.updated.length === 0)) === null || _a === void 0 ? void 0 : _a.updated) === '' ||
            changeset.questions.find((q) => !q.updated.trim().length);
        if (emptyResponse) {
            return {
                value: false,
                error: 'Responses to text questions are required to edit this feature.',
            };
        }
    }
    const changesetAsInspectionEdited = changeset;
    if (!changesetAsInspectionEdited.note &&
        !changesetAsInspectionEdited.images &&
        !((_c = (_b = changesetAsInspectionEdited.images) === null || _b === void 0 ? void 0 : _b.addded) === null || _c === void 0 ? void 0 : _c.length) &&
        !((_e = (_d = changesetAsInspectionEdited.images) === null || _d === void 0 ? void 0 : _d.removed) === null || _e === void 0 ? void 0 : _e.length) &&
        !((_f = changesetAsInspectionEdited.questions) === null || _f === void 0 ? void 0 : _f.length)) {
        return { value: false, error: 'No changes to save' };
    }
    return { value: true };
};
exports.canEdit = canEdit;
