import { LoadingButton } from '@mui/lab';
import {
	Autocomplete,
	Card,
	CardContent,
	TextField,
	Typography,
} from '@mui/material';
import { AdminToolsApi } from '@rentcheck/api-frontend';
import {
	AdminUpdateInspectionStatusParams,
	ApiInspection,
	ValidInspectionStatusesOnAdminUpdate,
} from '@rentcheck/types';
import { SpacedRow, Spacer } from 'components';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarActions } from 'store/actions';
import { Dispatch } from 'types';

interface Props {
	inspection: ApiInspection;
	refreshInspection: () => void;
}

const ChangeStatus = ({ inspection, refreshInspection }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const [loading, setLoading] = useState(false);
	const [newValue, setNewValue] = useState<
		AdminUpdateInspectionStatusParams['data']['inspection_status'] | null
	>(null);

	const handleConfirm = async () => {
		if (!inspection) {
			return;
		}

		if (!newValue) {
			return;
		}

		setLoading(true);

		AdminToolsApi.updateInspectionStatus(inspection.id, {
			inspection_status: newValue,
		})
			.then(() => {
				dispatch(SnackbarActions.showSuccess('Inspection Updated'));
				refreshInspection();
			})
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	};

	return (
		<Card sx={{ width: '100%' }}>
			<CardContent>
				<Typography variant="h6">Change Status</Typography>
				<Spacer height={2} />
				<SpacedRow>
					<Autocomplete
						fullWidth
						id="autocomplete-inspection-type"
						options={ValidInspectionStatusesOnAdminUpdate}
						value={newValue}
						onChange={(_, newNewValue) => setNewValue(newNewValue)}
						renderInput={(params) => (
							<TextField
								variant="filled"
								placeholder="Inspection Status"
								{...params}
							/>
						)}
					/>

					<Spacer width={4} />

					<LoadingButton loading={loading} onClick={handleConfirm}>
						Confirm
					</LoadingButton>
				</SpacedRow>
			</CardContent>
		</Card>
	);
};

export default ChangeStatus;
