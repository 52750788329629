import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip, Typography } from '@mui/material';
import { Screens, SpacedRow, Spacer } from 'components';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'store/reducers/rootReducer';

import { AccountSettingsActions, SubscriptionActions } from 'store/actions';
import { Dispatch } from 'types';

import { colors } from 'theme';
import Skeleton from './skeleton';
import PaperRow from '../common/paper-row';

export default () => {
	const dispatch = useDispatch<Dispatch>();

	const profile = useTypedSelector((state) => state.activeProfile);
	const subscription = useTypedSelector((state) => state.subscription);
	const accountSettings = useTypedSelector((state) => state.accountSettings);

	const [loading, setLoading] = useState(!subscription);

	useEffect(() => {
		if (!profile) {
			return;
		}

		if (subscription) {
			setLoading(false);
			return;
		}

		setLoading(true);
		dispatch(SubscriptionActions.getSubscription()).finally(() =>
			setLoading(false)
		);
	}, [profile]);

	if (loading) {
		return <Skeleton />;
	}

	const handleResidentReportedMaintenanceToggle = () => {
		if (!accountSettings) {
			return;
		}

		dispatch(
			AccountSettingsActions.setResidentReportedMaintenanceEnabled(
				!accountSettings.is_resident_reported_maintenance_enabled
			)
		);
	};
	const handleMaintenanceFlagDefaultPhotosToggle = () => {
		if (!accountSettings) {
			return;
		}

		dispatch(
			AccountSettingsActions.setMaintenanceFlagDefaultPhotosEnabled(
				!accountSettings.is_maintenance_flag_default_photos_enabled
			)
		);
	};

	return (
		<Screens.Container>
			<Screens.Header
				title="Maintenance Settings"
				showBreadCrumbs
				subtitle="Manage maintenance account settings including resident-reported maintenance."
				actions={[
					<Chip
						label="Beta"
						sx={{
							backgroundColor: '#2D3CE614',
							'.MuiChip-icon': {
								color: colors.primary,
							},
						}}
						icon={<FontAwesomeIcon icon={solid('message-smile')} />}
					/>,
				]}
			/>

			<PaperRow
				title="Resident-reported maintenance"
				selected={
					accountSettings?.is_resident_reported_maintenance_enabled ?? false
				}
				handleToggleSection={handleResidentReportedMaintenanceToggle}>
				<SpacedRow>
					<Typography color={colors.secondary}>
						Toggle this option ON to allow residents to report items needing
						maintenance while performing inspections. Items flagged by residents
						will be highlighted for your review and will not automatically
						generate work orders.
					</Typography>
					<a
						href="https://help.getrentcheck.com/en/articles/8545113-resident-reported-maintenance"
						style={{ marginLeft: 8, alignSelf: 'flex-end' }}>
						<Typography noWrap sx={{ textDecoration: 'underline' }}>
							Learn More
						</Typography>
					</a>
				</SpacedRow>
			</PaperRow>

			<PaperRow
				title="Maintenance flag media attachment"
				selected={
					accountSettings?.is_maintenance_flag_default_photos_enabled ?? false
				}
				handleToggleSection={handleMaintenanceFlagDefaultPhotosToggle}>
				<Typography color={colors.secondary}>
					Toggle ON to automatically attach all feature images and videos to a
					maintenance flag while performing an inspection in the RentCheck
					mobile app. You can still remove media if needed.
				</Typography>

				<Spacer height={2} />

				<Typography color={colors.secondary}>
					Toggle OFF to manually attach feature images and videos to a
					maintenance flag while performing an inspection in the RentCheck
					mobile app.
				</Typography>
			</PaperRow>
		</Screens.Container>
	);
};
