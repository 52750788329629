import { Rules } from '@rentcheck/biz';
import { ApiInspection } from '@rentcheck/types';
import MenuItem from 'components/menu-item';
import { useDispatch } from 'react-redux';
import { InspectionActions, SnackbarActions } from 'store/actions';
import { Snackbar } from 'store/actions/snackbar-actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';
import { Analytics } from 'utils';

interface Props {
	inspection: ApiInspection;
	onSelected: () => void;
}

export default ({ inspection, onSelected }: Props) => {
	const dispatch = useDispatch<Dispatch>();

	const profile = useTypedSelector((state) => state.activeProfile);

	const handleClick = async () => {
		onSelected();

		const progressSnackbar: Snackbar = await dispatch(
			SnackbarActions.showProgress('Canceling Revision Request')
		);

		dispatch(
			InspectionActions.updateInspection(inspection.id, {
				status: 'Review Inspection',
			})
		)
			.then(() => {
				Analytics.trackEvent('revision request canceled', {
					inspection_ids: inspection.id,
					userID: profile.id,
				});
			})
			.finally(() =>
				dispatch(SnackbarActions.hideSnackbar(progressSnackbar.id))
			);
	};

	if (!Rules.Inspections.canCancelRevisionRequest(inspection)) {
		return null;
	}

	return <MenuItem onClick={handleClick}>Cancel Revision Request</MenuItem>;
};
