import { LoadingButton } from '@mui/lab';
import { Card, CardContent, TextField, Typography } from '@mui/material';
import { AdminToolsApi } from '@rentcheck/api-frontend';
import { ApiInspection } from '@rentcheck/types';
import { SpacedRow, Spacer } from 'components';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarActions } from 'store/actions';
import { Dispatch } from 'types';

interface Props {
	inspection: ApiInspection;
	refreshInspection: () => void;
}

export default ({ inspection, refreshInspection }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const [newValue, setNewValue] = useState('');
	const [loading, setLoading] = useState(false);

	const handleConfirm = async () => {
		if (!inspection) {
			return;
		}

		if (!newValue) {
			return;
		}

		if (!inspection.team) {
			dispatch(
				SnackbarActions.showError('The inspection is not part of any teams')
			);
			return;
		}

		setLoading(true);

		AdminToolsApi.transferInspectionToProperty(inspection.id, newValue)
			.then(refreshInspection)
			.then(() => dispatch(SnackbarActions.showSuccess('Inspection Updated')))
			.catch((e) => dispatch(SnackbarActions.showError(e)))
			.finally(() => setLoading(false));
	};

	return (
		<Card sx={{ width: '100%' }}>
			<CardContent>
				<Typography variant="h6">Transfer to Property</Typography>
				<Spacer height={2} />
				<SpacedRow>
					<TextField
						fullWidth
						variant="filled"
						type="string"
						placeholder="New Property ID"
						value={newValue}
						onChange={(e) => setNewValue(e.target.value)}
					/>

					<Spacer width={4} />

					<LoadingButton loading={loading} onClick={handleConfirm}>
						Confirm
					</LoadingButton>
				</SpacedRow>
			</CardContent>
		</Card>
	);
};
