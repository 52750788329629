"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnableToUndoInitialReview = exports.UnableToCompleteInitialReview = exports.InvalidAreaNameNoOrdinal = exports.InvalidFastTrackInspectionType = exports.InvalidInspectionStatusForInvite = exports.InviteDateWithSelfPerform = exports.InvalidInviteDateAfterDueDate = exports.InvalidSelectedFeaturesOnInspectionTemplate = exports.NoRecipients = exports.InvalidInspectionTypeFromPropertyType = exports.InvalidAreaForProperty = exports.InvalidInspectionStatusForCancelReview = exports.InvalidAssignmentTypeForReviewRequest = exports.InvalidAssignmentTypeForCancelReview = exports.InvalidInspectionStatusForReviewRequest = exports.InvalidCompletedInspectionField = exports.InvalidTemplateForSubscription = exports.MissingRooms = exports.MissingFeatures = exports.NoneInProgressInspections = exports.XIdAlreadyExists = exports.NoResidents = void 0;
const http_1 = require("./http");
class NoResidents extends http_1.BadRequest {
    constructor(type) {
        super(`No ${type} residents`);
    }
}
exports.NoResidents = NoResidents;
class XIdAlreadyExists extends http_1.Conflict {
    constructor(value) {
        super(`the provided x_id (${value}) must be unique for your account`);
    }
}
exports.XIdAlreadyExists = XIdAlreadyExists;
class NoneInProgressInspections extends http_1.BadRequest {
    constructor() {
        super('none inspection ids are in progress');
    }
}
exports.NoneInProgressInspections = NoneInProgressInspections;
class MissingFeatures extends http_1.BadRequest {
    constructor(type) {
        super(`${type} requires at least one feature`);
    }
}
exports.MissingFeatures = MissingFeatures;
class MissingRooms extends http_1.BadRequest {
    constructor(inspectionTemplateName) {
        super(`${inspectionTemplateName} requires the property to have at least one room`);
    }
}
exports.MissingRooms = MissingRooms;
class InvalidTemplateForSubscription extends http_1.BadRequest {
    constructor(field) {
        super(`this template id is not published for subscription ${field}`);
    }
}
exports.InvalidTemplateForSubscription = InvalidTemplateForSubscription;
class InvalidCompletedInspectionField extends http_1.BadRequest {
    constructor(field) {
        super(`can not update "${field}" on completed inspection`);
    }
}
exports.InvalidCompletedInspectionField = InvalidCompletedInspectionField;
class InvalidInspectionStatusForReviewRequest extends http_1.BadRequest {
    constructor() {
        super('inspection status does not support review request');
    }
}
exports.InvalidInspectionStatusForReviewRequest = InvalidInspectionStatusForReviewRequest;
class InvalidAssignmentTypeForCancelReview extends http_1.BadRequest {
    constructor() {
        super('inspection assignment type does not support cancelling review request');
    }
}
exports.InvalidAssignmentTypeForCancelReview = InvalidAssignmentTypeForCancelReview;
class InvalidAssignmentTypeForReviewRequest extends http_1.BadRequest {
    constructor() {
        super('inspection assignment type does not support review request');
    }
}
exports.InvalidAssignmentTypeForReviewRequest = InvalidAssignmentTypeForReviewRequest;
class InvalidInspectionStatusForCancelReview extends http_1.BadRequest {
    constructor() {
        super('inspection status does not support cancelling review request');
    }
}
exports.InvalidInspectionStatusForCancelReview = InvalidInspectionStatusForCancelReview;
class InvalidAreaForProperty extends http_1.BadRequest {
    constructor(area) {
        super(`invalid area "${area}" for property`);
    }
}
exports.InvalidAreaForProperty = InvalidAreaForProperty;
class InvalidInspectionTypeFromPropertyType extends http_1.BadRequest {
    constructor(propertyType, inspectionType) {
        super(`${propertyType} does not support ${inspectionType} inspections`);
    }
}
exports.InvalidInspectionTypeFromPropertyType = InvalidInspectionTypeFromPropertyType;
class NoRecipients extends http_1.BadRequest {
    constructor(recipientsType) {
        super(`No ${recipientsType} assigned to perform this inspection`);
    }
}
exports.NoRecipients = NoRecipients;
class InvalidSelectedFeaturesOnInspectionTemplate extends http_1.BadRequest {
    constructor() {
        super('inspection template does not accept selected features');
    }
}
exports.InvalidSelectedFeaturesOnInspectionTemplate = InvalidSelectedFeaturesOnInspectionTemplate;
class InvalidInviteDateAfterDueDate extends http_1.BadRequest {
    constructor() {
        super('invitation date should not be after due date');
    }
}
exports.InvalidInviteDateAfterDueDate = InvalidInviteDateAfterDueDate;
class InviteDateWithSelfPerform extends http_1.BadRequest {
    constructor() {
        super('self-perform inspection does not allow invite_date');
    }
}
exports.InviteDateWithSelfPerform = InviteDateWithSelfPerform;
class InvalidInspectionStatusForInvite extends http_1.BadRequest {
    constructor() {
        super('inspection status does not support invite date update');
    }
}
exports.InvalidInspectionStatusForInvite = InvalidInspectionStatusForInvite;
class InvalidFastTrackInspectionType extends http_1.BadRequest {
    constructor() {
        super(`inspection template does not support fast track`);
    }
}
exports.InvalidFastTrackInspectionType = InvalidFastTrackInspectionType;
class InvalidAreaNameNoOrdinal extends http_1.BadRequest {
    constructor(simplifiedAreaName) {
        const message = `invalid area name, ${simplifiedAreaName} requires an ordinal to be valid. ` +
            `e.g. First ${simplifiedAreaName}, Second ${simplifiedAreaName}, etc.`;
        super(message);
    }
}
exports.InvalidAreaNameNoOrdinal = InvalidAreaNameNoOrdinal;
class UnableToCompleteInitialReview extends http_1.BadRequest {
    constructor() {
        super('unable to complete initial review');
    }
}
exports.UnableToCompleteInitialReview = UnableToCompleteInitialReview;
class UnableToUndoInitialReview extends http_1.BadRequest {
    constructor() {
        super('unable to undo initial review');
    }
}
exports.UnableToUndoInitialReview = UnableToUndoInitialReview;
