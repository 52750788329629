import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Menu, MenuItem, Button, Tooltip } from '@mui/material';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ApiInspection, APIProperty, MaintenanceFlag } from '@rentcheck/types';
import { Rules } from '@rentcheck/biz';

import { useLatchelIntegrations } from 'hooks/latchel-integrations';
import { useAppFolioIntegration } from 'hooks/appfolio-integration';
import { useRentManagerIntegrations } from 'hooks/rentmanager-integration';
import { ModalFlowActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { simplePropertyType } from 'utils/helpers';

interface Props {
	inspection: ApiInspection;
	property: APIProperty;
	selectedFlags: MaintenanceFlag[];
	allFlags: MaintenanceFlag[];
}

const CreateButton = ({
	inspection,
	property,
	selectedFlags,
	allFlags,
}: Props) => {
	const dispatch = useDispatch();

	const subscription = useTypedSelector((state) => state.subscription);
	const { appFolioIntegration } = useAppFolioIntegration();
	const { rentManagerIntegrations } = useRentManagerIntegrations();
	const { latchelIntegrations } = useLatchelIntegrations();
	const latchelIntegrationForTeam = latchelIntegrations.find(
		(li) => li.team.id === inspection.team?.id
	);

	const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);

	const createWorkOrdersResultForAppFolio =
		Rules.WorkOrders.canCreateWorkOrders(
			'appfolio',
			appFolioIntegration,
			subscription,
			property
		);
	const createWorkOrdersResultForRentManager =
		Rules.WorkOrders.canCreateWorkOrders(
			'rentmanager',
			rentManagerIntegrations,
			subscription,
			property
		);

	const flags = selectedFlags.length ? selectedFlags : allFlags;

	const handleOpenMenu = (e: React.MouseEvent<HTMLElement>) => {
		setAnchorElement(e.currentTarget);
	};

	const handleCloseMenu = () => {
		setAnchorElement(null);
	};

	const handleMenuClick = () => {
		/**
		 * Had to add this here to prevent a user from dismissing this
		 * menu and quickly tapping the revision button, which causes
		 * overlapping options to fire instead of the actual revision modal
		 */
		if (!anchorElement) return;
		handleCloseMenu();
	};

	const addWorkOrderItem = (
		provider: 'AppFolio' | 'Rent Manager' | 'Latchel' | 'Yardi' | 'Jenark',
		result: string
	) => {
		const tooltipTitle = `This ${simplePropertyType(property)} is not synced with ${provider}.`;
		const text = `Create ${provider} ${provider === 'Rent Manager' ? 'Service Issue' : 'Work Order'}`;

		if (result === 'not synced') {
			menu.push(
				<Tooltip title={tooltipTitle}>
					<span>
						<MenuItem disabled>{text}</MenuItem>
					</span>
				</Tooltip>
			);
		}

		if (result === 'allowed') {
			menu.push(
				<MenuItem
					onClick={() => {
						handleMenuClick();
						dispatch(
							ModalFlowActions.showCreateWorkOrderModal({
								provider,
								inspection,
								flags,
							})
						);
					}}>
					{text}
				</MenuItem>
			);
		}
	};

	const menu = [
		<MenuItem
			onClick={() => {
				handleMenuClick();
				dispatch(
					ModalFlowActions.showCreateMaintenanceReportModal({
						inspection,
						flags,
					})
				);
			}}>
			Create Maintenance Report
		</MenuItem>,
	];

	if (latchelIntegrationForTeam) {
		menu.push(
			<MenuItem
				onClick={() => {
					handleMenuClick();
					dispatch(
						ModalFlowActions.showCreateWorkOrderModal({
							provider: 'Latchel',
							inspection,
							flags,
						})
					);
				}}>
				Create Latchel Work Order
			</MenuItem>
		);
	}

	addWorkOrderItem('AppFolio', createWorkOrdersResultForAppFolio);
	addWorkOrderItem('Rent Manager', createWorkOrdersResultForRentManager);

	return (
		<>
			<Button
				variant="outlined"
				onClick={handleOpenMenu}
				endIcon={<FontAwesomeIcon icon={solid('caret-down')} />}>
				Create
			</Button>

			<Menu
				open={!!anchorElement}
				anchorEl={anchorElement}
				onClose={handleCloseMenu}
				transformOrigin={{ vertical: 'top', horizontal: 'left' }}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
				{menu.map((m) => m)}
			</Menu>
		</>
	);
};

export default CreateButton;
