import {
	ApiInspectionEvent,
	InspectionEventStatusChangedMetadata,
} from '@rentcheck/types';

import BaseTimelineEvent from '../base-event';
import RejectedFeaturesList from './rejected-features-list';

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Typography } from '@mui/material';
import _ from 'lodash';
import { ReactNode } from 'react';

interface Props {
	event: ApiInspectionEvent;
}

const StatusChangedTimelineEvent = ({ event }: Props) => {
	const metadata = event.metadata as InspectionEventStatusChangedMetadata;

	const title = () => {
		switch (metadata.inspection_status) {
			case 'inspection_rejected':
				return 'Revision requested';
			case 'inspection_revision_canceled':
				return 'Revision request canceled';
			case 'inspection_submitted':
				return 'Inspection completed';
			case 'inspection_partially_completed':
				return 'Inspection marked as completed';
			case 'inspection_auto_approved':
				return 'Inspection auto-approved';
			default:
				return _.capitalize(metadata.inspection_status.replaceAll('_', ' '));
		}
	};

	const subtitle = () => {
		const metadata = event.metadata as InspectionEventStatusChangedMetadata;

		switch (metadata.inspection_status) {
			case 'inspection_created':
				return `Created by ${metadata.changed_by_name}`;
			case 'inspection_started':
				return `Started by ${metadata.changed_by_name}`;
			case 'inspection_rejected':
				return `Requested by ${metadata.changed_by_name}`;
			case 'inspection_revision_canceled':
				return `Canceled by ${metadata.changed_by_name}`;
			case 'inspection_approved':
				return `Approved by ${metadata.changed_by_name}`;
			case 'inspection_completed':
				return `Completed by ${metadata.changed_by_name}`;
			case 'inspection_submitted':
				return `Completed by ${metadata.changed_by_name}`;
			case 'inspection_resubmitted':
				return `Resubmitted by ${metadata.changed_by_name}`;
			case 'inspection_partially_completed':
				return `Marked as completed by ${metadata.changed_by_name}`;
			case 'inspection_scheduled':
				return `Scheduled by ${metadata.changed_by_name}`;
			case 'inspection_auto_approved':
				return 'Inspection was resubmitted with no changes and automatically approved';
			default:
				return `Changed by ${metadata.changed_by_name}`;
		}
	};

	const icon = () => {
		const metadata = event.metadata as InspectionEventStatusChangedMetadata;

		switch (metadata.inspection_status) {
			case 'inspection_created':
				return solid('star');
			case 'inspection_started':
				return solid('file-invoice');
			case 'inspection_rejected':
				return solid('pen-swirl');
			case 'inspection_revision_canceled':
				return solid('pen-swirl');
			case 'inspection_approved':
				return solid('thumbs-up');
			case 'inspection_completed':
				return solid('check');
			case 'inspection_partially_completed':
				return solid('check');
			case 'inspection_submitted':
				return solid('share');
			case 'inspection_resubmitted':
				return solid('share');
			case 'inspection_auto_approved':
				return solid('thumbs-up');
			default:
				return solid('star');
		}
	};

	const additionalSections: ReactNode[] = [];

	if (metadata?.features?.length) {
		additionalSections.push(
			<>
				<Typography variant="body2" color="#4D4D4D">
					Features:
				</Typography>
				<RejectedFeaturesList featuresList={metadata.features} />
			</>
		);
	}

	return (
		<BaseTimelineEvent
			icon={icon()}
			title={title()}
			subtitle={subtitle()}
			timestamp={event.timestamp}
			sentTo={metadata.emails_sent}
			note={metadata.note}
			additionalContent={additionalSections}
		/>
	);
};

export default StatusChangedTimelineEvent;
