"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Zapier = exports.WorkOrders = exports.Vitally = exports.Validations = exports.TeamInvites = exports.TakeOverRequests = exports.Syncs = exports.Subscriptions = exports.Propexo = exports.Properties = exports.PermissionGroups = exports.Integrations = exports.Inspections = exports.InspectionTemplates = exports.InspectionInvites = exports.Imports = exports.Automations = exports.AppFolio = exports.Admin = exports.RentCheckException = void 0;
class RentCheckException extends Error {
    constructor(message, statusCode) {
        super(message);
        this.statusCode = statusCode;
    }
}
exports.RentCheckException = RentCheckException;
__exportStar(require("./http"), exports);
exports.Admin = __importStar(require("./admin"));
exports.AppFolio = __importStar(require("./appfolio"));
exports.Automations = __importStar(require("./automations"));
exports.Imports = __importStar(require("./imports"));
exports.InspectionInvites = __importStar(require("./inspection-invites"));
exports.InspectionTemplates = __importStar(require("./inspection-templates"));
exports.Inspections = __importStar(require("./inspections"));
exports.Integrations = __importStar(require("./integrations"));
exports.PermissionGroups = __importStar(require("./permission-groups"));
exports.Properties = __importStar(require("./properties"));
exports.Propexo = __importStar(require("./propexo"));
exports.Subscriptions = __importStar(require("./subscriptions"));
exports.Syncs = __importStar(require("./syncs"));
exports.TakeOverRequests = __importStar(require("./take-over-requests"));
exports.TeamInvites = __importStar(require("./team-invites"));
exports.Validations = __importStar(require("./validations"));
exports.Vitally = __importStar(require("./vitally"));
exports.WorkOrders = __importStar(require("./work-orders"));
exports.Zapier = __importStar(require("./zapier"));
