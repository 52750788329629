"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSyncData = void 0;
const dates_1 = require("../dates");
const getSyncData = (tenant) => {
    if (tenant.appfolio_sync) {
        return Object.assign(Object.assign({ vendor: 'appfolio' }, tenant.appfolio_sync), { last_sync: (0, dates_1.parse)('firestore', tenant.appfolio_sync.last_sync, null) });
    }
    if (tenant.rentmanager_sync) {
        return Object.assign(Object.assign({ vendor: 'rentmanager' }, tenant.rentmanager_sync), { last_sync: (0, dates_1.parse)('firestore', tenant.rentmanager_sync.last_sync, null) });
    }
    if (tenant.yardi_sync) {
        return Object.assign(Object.assign({ vendor: 'yardi' }, tenant.yardi_sync), { last_sync: (0, dates_1.parse)('firestore', tenant.yardi_sync, null) });
    }
    if (tenant.rentvine_sync) {
        return Object.assign(Object.assign({ vendor: 'rentvine' }, tenant.rentvine_sync), { last_sync: (0, dates_1.parse)('firestore', tenant.rentvine_sync.last_sync, null) });
    }
    return undefined;
};
exports.getSyncData = getSyncData;
