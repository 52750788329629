import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { LoadingButton } from '@mui/lab';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Link,
	TextField,
	Typography,
} from '@mui/material';
import { IntegrationsApi } from '@rentcheck/api-frontend';
import { Column, DialogTitle, Spacer } from 'components';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarActions, SubscriptionActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';

import Success from '../../../common/install-success-modal';

import HeaderIcons from './header-icons';

interface Props {
	open: boolean;
	handleClose: () => void;
}

const InstallRentvineModal = ({ open, handleClose }: Props) => {
	const dispatch = useDispatch();

	const profile = useTypedSelector((state) => state.activeProfile);
	const subscription = useTypedSelector((state) => state.subscription);

	const [corpId, setCorpId] = useState('');
	const [corpIdError, setCorpIdError] = useState('');

	const [apiKey, setApiKey] = useState('');
	const [apiKeyError, setApiKeyError] = useState('');

	const [apiSecret, setApiSecret] = useState('');
	const [apiSecretError, setApiSecretError] = useState('');

	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);

	useEffect(() => {
		setCorpId('');
		setCorpIdError('');

		setApiKey('');
		setApiKeyError('');

		setApiSecret('');
		setApiSecretError('');

		setLoading(false);
	}, [open]);

	useEffect(() => {
		setCorpIdError('');
	}, [corpId]);

	useEffect(() => {
		setApiKeyError('');
	}, [apiKey]);

	useEffect(() => {
		setApiSecretError('');
	}, [apiSecret]);

	const validateForm = () => {
		let formIsValid = true;

		if (!corpId) {
			setCorpIdError('Corporation ID is required.');
			formIsValid = false;
		}

		if (!apiKey) {
			setApiKeyError('API Key is required.');
			formIsValid = false;
		}

		if (!apiSecret) {
			setApiSecretError('API Secret is required.');
			formIsValid = false;
		}

		return formIsValid;
	};

	const handleConnect = () => {
		if (!profile || !subscription) {
			return;
		}

		if (!validateForm()) {
			return;
		}

		setLoading(true);

		IntegrationsApi.Rentvine.create({
			client_id: apiKey,
			client_secret: apiSecret,
			client_subdomain: corpId,
		})
			.then(() => {
				setSuccess(true);
				dispatch(SubscriptionActions.getSubscription());
			})
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	};

	const handleCloseSuccess = () => {
		handleClose();
		setSuccess(false);
	};

	if (success) {
		return (
			<Success
				vendor="Rentvine"
				parentUrl="/account/integrations/rentvine"
				handleClose={handleCloseSuccess}
				waitForConnection={true}
			/>
		);
	}

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle
				title="Connect Rentvine to RentCheck"
				icon={solid('plug')}
				onClose={handleClose}
			/>

			<DialogContent>
				<Column>
					<HeaderIcons />
					<Spacer height={16} />
					<Typography variant="subtitle2">
						Enter the Rentvine Corporation ID, API key, and secret to connect
						Rentvine to RentCheck. Learn More.{' '}
						<Link
							target="_blank"
							href="https://help.getrentcheck.com/en/articles/10295125-rentvine-integration">
							Learn More
						</Link>
					</Typography>
					<Spacer height={4} />
					<TextField
						variant="filled"
						label="Corporation ID"
						placeholder="Enter your Corporation ID here"
						value={corpId}
						onChange={(e) => setCorpId(e.target.value)}
						error={!!corpIdError}
						helperText={corpIdError || 'Required'}
					/>
					<Spacer height={4} />
					<TextField
						variant="filled"
						label="API Key"
						placeholder="Enter your API Key here"
						value={apiKey}
						onChange={(e) => setApiKey(e.target.value)}
						error={!!apiKeyError}
						helperText={apiKeyError || 'Required'}
					/>
					<Spacer height={4} />
					<TextField
						variant="filled"
						label="API Secret"
						placeholder="Enter your API Secret here"
						value={apiSecret}
						onChange={(e) => setApiSecret(e.target.value)}
						error={!!apiSecretError}
						helperText={apiSecretError || 'Required'}
					/>
				</Column>
			</DialogContent>

			<DialogActions>
				<Button variant="text" color="secondary" onClick={handleClose}>
					Cancel
				</Button>

				<LoadingButton
					variant="contained"
					onClick={handleConnect}
					loading={loading}>
					Install
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};

export default InstallRentvineModal;
