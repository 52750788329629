import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { ApiInspection } from '@rentcheck/types';

import { InspectionActions } from 'store/actions';
import { Dispatch } from 'types';

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DialogTitle } from 'components';
import FormTextField from 'components/form-text-field';
import { formattedAddress } from 'utils/helpers';

interface Props {
	inspection: ApiInspection;
	open: boolean;
	onClose: () => void;
}

export default ({ inspection, open, onClose }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const [label, setLabel] = useState(inspection.inspection_label ?? '');
	const [loading, setLoading] = useState(false);

	const handleUpdate = async () => {
		setLoading(true);
		dispatch(
			InspectionActions.updateInspection(inspection.id, {
				inspection_label: label,
			})
		).then(() => {
			setLoading(false);
			onClose();
		});
	};

	const subtitle = `${inspection.inspection_template.name} Inspection • ${formattedAddress(
		inspection.property
	)}`;

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle
				icon={solid('file-pen')}
				title="Edit Inspection Label"
				bottomSubtitle={subtitle}
				onClose={onClose}
			/>

			<DialogContent>
				<FormTextField
					placeholder="Enter label here"
					value={label}
					onChange={(e) => setLabel(e.target.value)}
				/>
			</DialogContent>

			<DialogActions>
				<Button onClick={onClose} variant="text" color="secondary">
					Cancel
				</Button>
				<LoadingButton
					variant="contained"
					color="primary"
					loading={loading}
					onClick={handleUpdate}>
					Update Label
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};
