import {
	MouseEvent,
	ReactNode,
	ReactNodeArray,
	forwardRef,
	useImperativeHandle,
	useState,
} from 'react';
import { v4 as uuid } from 'uuid';
import styled from 'styled-components';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Badge,
	CircularProgress,
	ToggleButton as MuiToggleButton,
	Popover,
	Tooltip,
} from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';

import { colors } from '@rentcheck/theme';

import * as theme from 'theme';

interface Props {
	icon: IconProp;
	loading?: boolean;
	tooltip?: string;
	disabled?: boolean;
	badgeCount?: number;
	width?: number;
	children?: ReactNode | ReactNodeArray;
	onClick?: () => void;
	buttonSx?: React.CSSProperties;
	selected?: boolean;
	selectedWhenMenuOpen?: boolean;
	closeOnClickInside?: boolean;
}

export interface ToggleButtonRef {
	close: () => void;
	click: () => void;
}

export default forwardRef<ToggleButtonRef, Props>((props, ref) => {
	const {
		disabled,
		tooltip = '',
		selectedWhenMenuOpen = false,
		closeOnClickInside = true,
		selected = false,
		badgeCount,
		icon,
		children,
		onClick,
		width,
		loading,
		buttonSx,
	} = props;

	const [uniqueId] = useState(uuid());
	const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);

	const buttonId = `toggle-button-${uniqueId}`;

	const handleClick = (e?: MouseEvent<HTMLButtonElement>) => {
		e?.preventDefault();
		e?.stopPropagation();

		onClick?.();

		if (!children) {
			return;
		}

		if (e?.currentTarget) {
			setMenuAnchor(e.currentTarget);
			return;
		}

		const anchorElement = document.getElementById(
			buttonId
		) as HTMLButtonElement;

		if (anchorElement) {
			setMenuAnchor(anchorElement);
		}
	};

	const handleClose = () => {
		if (!closeOnClickInside) {
			return;
		}

		setMenuAnchor(null);
	};

	const handleImperativeClose = () => {
		setMenuAnchor(null);
	};

	useImperativeHandle(
		ref,
		() => ({
			click: handleClick,
			close: handleImperativeClose,
		}),
		[menuAnchor]
	);

	return (
		<>
			<Tooltip title={tooltip}>
				<Badge
					color="error"
					overlap="circular"
					badgeContent={badgeCount}
					sx={{
						'.MuiBadge-badge': {
							height: 16,
							minWidth: 16,
							padding: '0 1px',
							fontSize: 10,
						},
					}}>
					<ToggleButton
						menuOpen={!!menuAnchor}
						disabled={disabled}
						selected={selectedWhenMenuOpen ? !!menuAnchor : selected}
						value={selected}
						id={buttonId}
						onClick={handleClick}
						style={buttonSx}>
						{!loading && <FontAwesomeIcon icon={icon} fontSize={16} />}
						{loading && (
							<CircularProgress
								size={10}
								color={selected ? 'info' : 'primary'}
							/>
						)}
					</ToggleButton>
				</Badge>
			</Tooltip>
			{!!children && (
				<Popover
					sx={{ marginTop: 1 }}
					open={!!menuAnchor}
					anchorEl={menuAnchor}
					onClick={handleClose}
					onClose={() => setMenuAnchor(null)}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}>
					<MenuWrapper width={width}>{children}</MenuWrapper>
				</Popover>
			)}
		</>
	);
});

const ToggleButton = muiStyled(MuiToggleButton)<{
	menuOpen: boolean;
	style?: React.CSSProperties;
}>(({ theme, style }) => ({
	height: 32,
	width: 32,
	marginLeft: 8,
	borderRadius: '50%',
	border: 'none',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',

	'&.hover': {
		backgroundColor: '#0000000A',
	},

	'&.Mui-selected': {
		color: '#fff',
		backgroundColor: theme.palette.primary.main,

		'&:hover': {
			backgroundColor: theme.palette.primary.dark,
		},
	},

	'&.Mui-disabled': {
		backgroundColor: colors.gray[500],
		color: colors.white,
	},

	...(style ?? {}),
}));

const MenuWrapper = styled.div<{ width?: number }>`
	background-color: ${theme.colors.white};
	border-radius: ${theme.radius.small};
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
	${({ width }) => (width ? `${width}px` : '')};
`;
