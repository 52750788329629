import { IntegrationsApi } from '@rentcheck/api-frontend';
import { ApiRentvineIntegration } from '@rentcheck/types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { SnackbarActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';

export const useRentvineIntegration = () => {
	const dispatch: Dispatch = useDispatch();

	const subscription = useTypedSelector((state) => state.subscription);

	const [rentvineIntegration, setRentvineIntegration] =
		useState<ApiRentvineIntegration>();
	const [loadingRentvineIntegration, setLoadingRentvineIntegration] =
		useState(true);

	useEffect(() => {
		if (!subscription) {
			setLoadingRentvineIntegration(false);
			return;
		}

		setLoadingRentvineIntegration(true);

		IntegrationsApi.Rentvine.get()
			.then((data) => setRentvineIntegration(data))
			.catch((e) => {
				if (e.message === 'integration not found') return;

				dispatch(SnackbarActions.showError());
			})
			.finally(() => setLoadingRentvineIntegration(false));
	}, [subscription, dispatch]);

	return {
		loading: loadingRentvineIntegration,
		rentvineIntegration: rentvineIntegration || null,
	};
};
