import { TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { ApiInspection, APIProperty } from '@rentcheck/types';

import { Column } from 'components';
import { shortDisplayDate } from 'utils/helpers';

import { InspectionsApi } from '@rentcheck/api-frontend';
import EmptyState from './EmptyState';
import DetailsTable from './table';
import PropertyDetailsSkeleton from './table/skeleton';

interface Props {
	property: APIProperty;
}

interface RowProps {
	inspections: ApiInspection[];
}

const InspectionRows = ({ inspections }: RowProps) => {
	const history = useHistory();

	const [startIndex, setStartIndex] = useState(0);

	const pageSize = 5;
	const headers = ['Template', 'Due Date', 'Status'];

	return (
		<DetailsTable
			headers={headers}
			length={inspections.length}
			pageSize={pageSize}
			startIndex={startIndex}
			setStartIndex={setStartIndex}>
			<TableBody>
				{inspections
					.slice(startIndex * pageSize, startIndex * pageSize + pageSize)
					.map((i) => {
						return (
							<TableRow
								key={i.id}
								sx={{ cursor: 'pointer' }}
								onClick={() => history.push(`/inspections/${i.id}`)}>
								<TableCell>
									<Column>
										<Typography>{i.inspection_template.name}</Typography>
										{i.inspection_label && (
											<Typography fontSize={12}>
												{i.inspection_label}
											</Typography>
										)}
									</Column>
								</TableCell>

								<TableCell>
									<Typography>{shortDisplayDate(i.due_date)}</Typography>
								</TableCell>

								<TableCell>
									<Typography>{i.inspection_status}</Typography>
								</TableCell>
							</TableRow>
						);
					})}
			</TableBody>
		</DetailsTable>
	);
};

const InspectionsList = ({ property }: Props) => {
	const [inspections, setInspections] = useState<ApiInspection[]>([]);

	const [fetchedInspections, setFetchedInspections] = useState(false);

	useEffect(() => {
		InspectionsApi.getAll(
			false,
			{ page_size: 100, page_number: 0 },
			{ property: { value: [property.id], condition: 'any_of' } },
			{ sort_by: 'inspection_date', sort_type: 'DESC' }
		).then((i) => {
			setInspections(i.data);
			setFetchedInspections(true);
		});
	}, [property]);

	if (!fetchedInspections) {
		return <PropertyDetailsSkeleton />;
	}

	if (inspections.length === 0) {
		return <EmptyState property={property} />;
	}

	return (
		<Column>
			<InspectionRows inspections={inspections} />
		</Column>
	);
};

export default InspectionsList;
